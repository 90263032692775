import CircleIndicator from '@/components/widgets/mnemonic/ui/circle-indicator/circle-indicator'
import ValueIndicator from '@/components/widgets/mnemonic/ui/value-indicator/value-indicator'
import { useGetCurrentMnemoScheme } from '@/hooks/api/use-get-current-mnemo-scheme'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useAddMnemonicItemMutation } from '@/store/api/mnemo.api'
import type { TMnemoSchemeItem } from '@/types/mnemonic.type'
import { EToolType } from '@/types/mnemonic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { MouseEvent } from 'react'

import styles from './mnemonicTools.module.css'

import IndicatorSettings from '../indicator-settings/indicator-settings'
import StatisticalIndicatorTool from '../ui/statistical-indicator-tool/statistical-indicator-tool'
import TextIndicator from '../ui/text-indicator/text-indicator'
import UploadFile from '../ui/upload-file/upload-file'
import VerticalIndicator from '../ui/vertical-indicator/vertical-indicator'

const MnemonicTools = () => {
  const { selectedMachineId, selectedPointId, selectedMeasurementId } = useTypedSelector((state) => state.globalReducer)
  const { data: currentMnemonicSchemeData, mnemonicSchemeData } = useGetCurrentMnemoScheme()

  const [addMnemonicItem] = useAddMnemonicItemMutation()

  const handleDoubleClickIndicator = async (e: MouseEvent<HTMLElement>, indicator: TMnemoSchemeItem) => {
    try {
      const createdMnemonicItem = await addMnemonicItem(indicator).unwrap()

      if (!createdMnemonicItem.id) throw new Error('Ошибка при добавлении в базу элемента')

      successNotificationCreate('Индикатор добавлен')
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  return mnemonicSchemeData?.content.length ? (
    <div className={styles.tools}>
      <IndicatorSettings />

      <CircleIndicator
        handleDoubleClick={(evt) => {
          if (currentMnemonicSchemeData?.mnemoSchemeId) {
            let currentId = {}

            if (selectedMeasurementId) {
              currentId = {
                measurementId: selectedMeasurementId
              }
            } else if (selectedPointId) {
              currentId = { measuringPointId: selectedPointId }
            } else {
              currentId = { machineId: selectedMachineId }
            }

            handleDoubleClickIndicator(evt, {
              mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
              name: 'dangerStateIndicatorElement',
              x: 10,
              y: 10,
              dangerStateIndicatorElement: {
                ...currentId,
                width: 30,
                height: 30
              }
            })
          }
        }}
      />

      <ValueIndicator
        handleDoubleClick={(evt) => {
          if (currentMnemonicSchemeData?.mnemoSchemeId) {
            let currentId = {}

            if (selectedMeasurementId) {
              currentId = {
                measurementId: selectedMeasurementId
              }
            } else if (selectedPointId) {
              currentId = { measuringPointId: selectedPointId }
            } else {
              currentId = { machineId: selectedMachineId }
            }

            handleDoubleClickIndicator(evt, {
              mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
              name: 'measurementLastValueElement',
              x: 10,
              y: 10,
              measurementLastValueElement: {
                ...currentId
              }
            })
          }
        }}
      />

      {selectedMeasurementId ? (
        <VerticalIndicator
          handleDoubleClick={(evt) => {
            if (currentMnemonicSchemeData?.mnemoSchemeId) {
              handleDoubleClickIndicator(evt, {
                mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
                name: 'measurementWithThresholdIndicatorElement',
                x: 10,
                y: 10,
                measurementWithThresholdIndicatorElement: {
                  measurementId: selectedMeasurementId,
                  height: 80,
                  width: 15,
                  horizontal: false
                }
              })
            }
          }}
          toolType={EToolType.MEASUREMENT_WITH_THRESHOLD_INDICATOR_ELEMENT}
        />
      ) : null}

      {!selectedMeasurementId ? (
        <StatisticalIndicatorTool
          handleDoubleClick={(evt) => {
            if (currentMnemonicSchemeData?.mnemoSchemeId) {
              let currentId = {}

              if (selectedPointId) {
                currentId = { measuringPointId: selectedPointId }
              } else {
                currentId = { machineId: selectedMachineId }
              }

              handleDoubleClickIndicator(evt, {
                mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
                name: 'statisticalIndicatorElement',
                x: 10,
                y: 10,
                statisticalIndicatorElement: {
                  ...currentId,
                  height: 30,
                  width: 30
                }
              })
            }
          }}
        />
      ) : null}

      {
        /* selectedMeasurement.measurementId ? (
        <PieIndicator
          toolType={
            EToolType.MEASUREMENT_WITH_THRESHOLD_SPEEDOMETER_INDICATOR_ELEMENT
          }
          handleDoubleClick={(evt) => {
            if (currentMnemonicSchemeData?.mnemoSchemeId) {
              handleDoubleClickIndicator(evt, {
                mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
                name: 'measurementWithThresholdSpeedometerIndicatorElement',
                x: 10,
                y: 10,
                measurementWithThresholdSpeedometerIndicatorElement: {
                  measurementId: selectedMeasurement.measurementId,
                  height: 60,
                  width: 90,
                },
              });
            }
          }}
          zones={[
            { value: 10, color: 'rgb(184, 95, 95)' },
            { value: 10, color: 'rgb(199, 194, 73)' },
            { value: 30, color: 'rgb(38, 227, 94)' },
            { value: 10, color: 'rgb(199, 194, 73)' },
            { value: 10, color: 'rgb(184, 95, 95)' },
          ]}
        />
      ) :  */ null
      }

      <UploadFile />

      <TextIndicator
        handleDoubleClick={(evt) => {
          if (currentMnemonicSchemeData?.mnemoSchemeId) {
            handleDoubleClickIndicator(evt, {
              mnemoSchemeId: currentMnemonicSchemeData?.mnemoSchemeId,
              name: 'staticLabelElement',
              x: 10,
              y: 10,
              staticLabelElement: {
                text: 'Текст',
                fontStyle: { fontSize: 14, color: '#000' }
              }
            })
          }
        }}
      />
    </div>
  ) : null
}

export default MnemonicTools
