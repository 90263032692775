import type { EAverageType } from '@/enums/measurment/average-type.enum'
import type { EDetectorType } from '@/enums/measurment/common-level/detector-type.enum'

export const mapAverageType: { [key in EAverageType]: string } = {
  LINEAR: 'Линейное',
  EXPONENTIAL: 'Экспоненциальное'
}

export const averageTypeOptions = Object.entries(mapAverageType).map(([key, label]) => ({
  value: key as EDetectorType,
  label
}))
