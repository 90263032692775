import type {
  IOutDataBd,
  IOuts
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.type'
import { formatDataForTable } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bd-form/bd-form.util'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import cn from 'classnames'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TOutTable = {
  outs: IOuts | null
}
export const OutTable: FC<TOutTable> = ({ outs }) => {
  const [outData, setOutData] = useState<IOutDataBd[] | undefined>(undefined)

  useEffect(() => {
    if (!outs) {
      setOutData(undefined)
      return
    }

    const formattedData = formatDataForTable(outs)
    setOutData(formattedData)
  }, [outs])

  const columns: ColumnsType<IOutDataBd> = [
    {
      dataIndex: 'label'
    },
    {
      dataIndex: 'freqOutName',
      title: 'Наименование частот'
    },
    {
      dataIndex: 'freqOutCoefKb',
      title: 'Коэффициенты в рамках КБ'
    },
    {
      dataIndex: 'freqOutCoefMachine',
      title: 'Коэффициенты в рамках машины'
    },
    {
      dataIndex: 'freqOutValue',
      title: 'Значения частот'
    }
  ]

  return (
    <Table
      className={cn(styles['drawer-kinematic-table'], styles['drawer-kinematic-table-width-height'])}
      columns={columns}
      dataSource={outData}
      pagination={false}
      bordered={true}
    />
  )
}
