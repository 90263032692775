import type { EDetectorType } from '@/enums/measurment/common-level/detector-type.enum'
import type { ESpecterLineCount } from '@/enums/measurment/specter-line-count.enum'

export const mapSpecterLineCount: { [key in ESpecterLineCount]: string } = {
  COUNT_8_100_128: '100',
  COUNT_7_200_256: '200',
  COUNT_6_400_512: '400',
  COUNT_5_800_1024: '800',
  COUNT_4_1600_2048: '1 600',
  COUNT_3_3200_4096: '3 200',
  COUNT_2_6400_8192: '6 400',
  COUNT_1_12800_16384: '12 800',
  COUNT_0_25600_32768: '25 600'
} as const

export const specterLineCountOptions = Object.entries(mapSpecterLineCount).map(([key, label]) => ({
  value: key as EDetectorType,
  label
}))
