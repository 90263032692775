import ConnectionPanel from '@/app/configuration/components/connections/components/connection-panel/connection-panel'
import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import ToolBar from '@/components/controlers/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import Submenu from '@/components/controlers/submenu/submenu'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

const Connections: FC = () => {
  const { selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const { selectedDeviceId, deviceContent } = useTypedSelector((state) => state.devicesReducer)
  return (
    <Container>
      <ToolBar />
      <LeftBar>
        <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONNECTION} />
      </LeftBar>
      <WorkSpace>
        <Submenu links={configurationLinks} />
        <ConnectionPanel pointId={selectedPointId} deviceId={selectedDeviceId} deviceContent={deviceContent} />
      </WorkSpace>
    </Container>
  )
}

export default Connections
