export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1)

export const isDevelopmentMode = (): boolean => process.env.NODE_ENV === 'development'

export const addLeadingZeros = (number: number, length: number) => {
  let numString = String(number)
  while (numString.length < length) {
    numString = '0' + numString
  }
  return numString
}

/**
 * Determine whether the array is empty.
 * @param {unknown[]} array the array.
 * @returns {boolean} result of checking.
 * */
export const isEmpty = (array: unknown[]): boolean => !array.length

export const convertDegToRad = (deg: number): number => (Math.PI * deg) / 180

export const formatFloat = (value: number, digits: number): string => {
  const result = parseFloat(value.toFixed(digits))
  if (result < 0.0001) {
    return result.toExponential()
  }

  return result.toString()
}

export const isNumber = (value: unknown): value is number => typeof value === 'number' && !isNaN(value)
export const replaceDecimalPoint = (inputString?: string) => {
  if (inputString) {
    return inputString.replace(/\d+\.\d+/g, function (match) {
      return match.replace('.', ',')
    })
  }
}

/**
 * Форматирует число с указанной точностью,
 * автоматически выбирая между обычным представлением и экспоненциальной формой.
 *
 * @param {number} num - Число для форматирования.
 * @param {number} precision - Точность (количество значимых цифр).
 * @param {number} expThreshold - Порог экспоненциального представления (по умолчанию 3).
 * @returns {string} Отформатированное число.
 */
export const formatNumber = (num: number, precision = 3, expThreshold = 3): string => {
  if (num === 0) {
    return '0'
  }
  let formatted = num.toPrecision(precision)
  if (Math.abs(num) >= Math.pow(10, expThreshold) || Math.abs(num) <= Math.pow(10, -expThreshold)) {
    formatted = Number(formatted).toExponential()
  }
  return formatted
}
