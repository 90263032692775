import FieldWrapper from '@/components/wrappers/field-wrapper/field-wrapper'
import { validateMaxValue, validateMinValue } from '@/utils/validation-rules'
import type { FormItemProps, InputNumberProps, TooltipProps } from 'antd'
import { Form, InputNumber } from 'antd'
import type { Rule } from 'antd/es/form'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './form-input-number.module.css'

type FormInputProps = {
  labelContent: string
  widthLabel?: string
  widthInput?: string
  layout?: 'vertical' | 'horizontal'
  tooltipKey?: string
  maxValue?: number
  minValue?: number
  isValidateZero?: boolean
  customRules?: Rule[]
}

type TProps = FormInputProps & InputNumberProps & FormItemProps & TooltipProps

const validateZeroValue = (isValidateZero?: boolean) => (_: unknown, value: string) => {
  if (isValidateZero && Number(value) <= 0) {
    return Promise.reject(new Error('Значение должно быть больше 0'))
  }

  return Promise.resolve()
}

const FormInputNumber: FC<TProps> = ({
  labelContent,
  name,
  step,
  initialValue = 0,
  min,
  max,
  required = false,
  disabled,
  tooltipKey,
  onChange,
  layout = 'horizontal',
  placeholder,
  maxValue,
  minValue,
  isValidateZero,
  customRules = []
}) => {
  // Получение разделителя относительно локального локеля
  const decimalSeparatorInfo = new Intl.NumberFormat().formatToParts(0.1)
  const decimalSeparator = decimalSeparatorInfo.find((part) => part.type === 'decimal')

  const rules = [
    {
      required: required,
      message: `Поле «${labelContent}» обязательно`
    },
    {
      validator: validateMaxValue(maxValue)
    },
    {
      validator: validateMinValue(minValue)
    },
    {
      validator: validateZeroValue(isValidateZero)
    },
    ...customRules
  ]

  return (
    <FieldWrapper tooltipKey={tooltipKey} layout={layout}>
      <Form.Item
        className={cn(styles.item, {
          [styles['item-vertical']]: layout === 'vertical'
        })}
        label={<span style={{ fontWeight: 'bold' }}>{labelContent}</span>}
        rules={rules}
        name={name}
        initialValue={initialValue}
      >
        <InputNumber
          name={name}
          disabled={disabled}
          step={step}
          min={min}
          max={max}
          decimalSeparator={decimalSeparator?.value || ','}
          style={{ width: '100%' }}
          onChange={onChange}
          placeholder={placeholder}
        />
      </Form.Item>
    </FieldWrapper>
  )
}

export default FormInputNumber
