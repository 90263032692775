import type { IToolProps } from '@/components/widgets/mnemonic/ui/tool.interface'
import { mapThresholdColor } from '@/constants/threshold/threshold.constant'
import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'
import { useGetMnemoStats } from '@/hooks/api/use-get-mnemo-stats'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type Konva from 'konva'
import type { FC } from 'react'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { Circle, Line, Transformer } from 'react-konva'

const DangerStateIndicator: FC<IToolProps> = ({
  element,
  updateMnemonicElement,
  draggable,
  parentSize,
  setContextMenuOptions
}) => {
  const circleRef = useRef<Konva.Circle>(null)
  const pointRef = useRef<Konva.Circle>(null)
  const lineRef = useRef<Konva.Line>(null)
  const transformerRef = useRef<Konva.Transformer>(null)
  const { selectedMnemoItemId } = useTypedSelector((state) => state.mnemonicReducer)
  const { setSelectedMnemoItemId } = useActions()
  const isSelected = selectedMnemoItemId === element?.mnemoElementId

  const { setMnemoSchemeItem } = useActions()

  const { data: dataMnemoStats } = useGetMnemoStats()

  const dangerState = dataMnemoStats?.mnemoElementState?.find(
    (indicator) => indicator.mnemoElementId === element?.mnemoElementId
  )

  useEffect(() => {
    if (!transformerRef?.current || !circleRef?.current) return

    transformerRef.current.nodes([circleRef?.current])
    transformerRef.current.getLayer()?.batchDraw()
  }, [])

  //для того чтобы не прыгало при ресайзе
  useLayoutEffect(() => {
    if (!circleRef?.current) return

    circleRef.current.scaleX(1)
    circleRef.current.scaleY(1)
  }, [element])

  return (
    <>
      {element?.pointerPosition ? (
        <Line
          ref={lineRef}
          points={[
            pointRef?.current?.attrs.x || element?.pointerPosition?.x || 0,
            pointRef?.current?.attrs.y || element?.pointerPosition?.y || 0,
            circleRef?.current?.attrs.x || element?.x || 0,
            circleRef?.current?.attrs.y || element?.y || 0
          ]}
          stroke={'#000'}
          strokeWidth={1}
        />
      ) : null}

      <Circle
        ref={circleRef}
        onClick={
          draggable
            ? () => {
                if (element) {
                  setMnemoSchemeItem(element)
                  setSelectedMnemoItemId(element.mnemoElementId)
                }
              }
            : undefined
        }
        onContextMenu={(e) => {
          e.evt.preventDefault()

          const pointerPosition = e.target.getStage()?.getPointerPosition()

          if (!pointerPosition || !setContextMenuOptions) return

          setContextMenuOptions({
            isOpen: true,
            position: {
              x: pointerPosition.x,
              y: pointerPosition.y
            }
          })
        }}
        onMouseEnter={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grab'
        }}
        onMouseDown={(e) => {
          // style stage container:
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'grabbing'
        }}
        onMouseLeave={(e) => {
          const container = e.target.getStage()?.container()
          if (!container || !draggable) return
          container.style.cursor = 'default'
        }}
        onTransformEnd={() => {
          const node = circleRef.current

          if (!node || !updateMnemonicElement || !element?.dangerStateIndicatorElement) return

          const scaleX = node.scaleX()
          const scaleY = node.scaleY()

          updateMnemonicElement({
            ...element,
            x: node.x(),
            y: node.y(),
            dangerStateIndicatorElement: {
              ...element.dangerStateIndicatorElement,
              width: node.width() * scaleX,
              height: node.height() * scaleY
            }
          })
        }}
        draggable={draggable}
        onDragEnd={(e) => {
          if (!updateMnemonicElement || !element?.mnemoSchemeId || e.target.attrs.x < 0 || e.target.attrs.y < 0) return

          updateMnemonicElement({
            ...element,
            x: e.target.attrs.x,
            y: e.target.attrs.y
          })
        }}
        width={Math.abs(element?.dangerStateIndicatorElement?.width || 15)}
        height={Math.abs(element?.dangerStateIndicatorElement?.height || 15)}
        x={element?.x}
        y={element?.y}
        stroke={isSelected ? 'red' : undefined}
        fill={
          dangerState?.dangerLevelStateDto.dangerLevelType === EThresholdLevel.LEVEL_0
            ? mapThresholdColor[EThresholdLevel.LEVEL_0]
            : dangerState?.dangerLevelStateDto.dangerLevelType === EThresholdLevel.LEVEL_1
            ? mapThresholdColor[EThresholdLevel.LEVEL_1]
            : dangerState?.dangerLevelStateDto.dangerLevelType === EThresholdLevel.LEVEL_2
            ? mapThresholdColor[EThresholdLevel.LEVEL_2]
            : dangerState?.dangerLevelStateDto.dangerLevelType === EThresholdLevel.LEVEL_3
            ? mapThresholdColor[EThresholdLevel.LEVEL_3]
            : dangerState?.dangerLevelStateDto.dangerLevelType === EThresholdLevel.LEVEL_4
            ? mapThresholdColor[EThresholdLevel.LEVEL_4]
            : mapThresholdColor[EThresholdLevel.NONE]
        }
      />

      <Transformer
        flipEnabled={false}
        resizeEnabled={draggable}
        borderEnabled={draggable}
        ref={transformerRef}
        rotateEnabled={false}
        boundBoxFunc={(oldBox, newBox) => {
          const circle = circleRef.current
          const line = lineRef.current

          if (!circle) return oldBox

          /* const isOut =
            circle.x() < 0 ||
            circle.y() < 0 ||
            circle.x() + circle.width() * circle.getAbsoluteScale().x >=
              (parentSize?.width || 1) ||
            circle.y() + circle.height() * circle.getAbsoluteScale().y >=
              (parentSize?.height || 1);

          if (isOut)
            return {
              ...oldBox,
              height: oldBox.height - 1,
              width: oldBox.width - 1,
            }; */

          if (line) {
            line.attrs.points = [line.attrs.points[0], line.attrs.points[1], circle.x(), circle.y()]
          }

          return newBox
        }}
        onDragMove={() => {
          const circle = circleRef.current
          const newPosition = { x: circle?.attrs.x, y: circle?.attrs.y }

          if (!newPosition?.x || !newPosition?.y || !circle) return

          if (newPosition.x < circle.width() / 2) {
            newPosition.x = circle.width() / 2
          }

          if (newPosition.x > Number(parentSize?.width) - circle.width() / 2) {
            newPosition.x = Number(parentSize?.width) - circle.width() / 2
          }

          if (newPosition.y < circle.height() / 2) {
            newPosition.y = circle.height() / 2
          }

          if (newPosition.y > Number(parentSize?.height) - circle.height() / 2) {
            newPosition.y = Number(parentSize?.height) - circle.height() / 2
          }

          circle?.setPosition(newPosition)

          if (!lineRef.current) return

          lineRef.current.attrs.points = [
            lineRef.current.attrs.points[0],
            lineRef.current.attrs.points[1],
            newPosition.x,
            newPosition.y
          ]
        }}
        enabledAnchors={['top-left', 'top-right', 'bottom-left', 'bottom-right']}
      />

      {element?.pointerPosition ? (
        <Circle
          ref={pointRef}
          draggable={draggable}
          fill={'#000'}
          width={10}
          height={10}
          onDragEnd={(e) => {
            if (!updateMnemonicElement || !element?.mnemoSchemeId || e.target.attrs.x < 0 || e.target.attrs.y < 0)
              return

            updateMnemonicElement({
              ...element,
              pointerPosition: {
                x: e.target.attrs.x,
                y: e.target.attrs.y
              }
            })
          }}
          onDragMove={(e) => {
            if (!lineRef.current) return

            lineRef.current.attrs.points = [
              e.target.attrs.x,
              e.target.attrs.y,
              lineRef.current.attrs.points[2],
              lineRef.current.attrs.points[3]
            ]
          }}
          x={element?.pointerPosition?.x}
          y={element?.pointerPosition?.y}
        />
      ) : null}
    </>
  )
}

export default DangerStateIndicator
