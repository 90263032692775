import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import { TitleButton } from '@/constants/core/common.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import React from 'react'

type PropsType = {
  isGeneratorContent: boolean
  isGeneratorPanelContent: boolean
}

const DevicesTools: FC<PropsType> = ({ isGeneratorContent, isGeneratorPanelContent }) => {
  const { setIsEditGenerator } = useActions()
  const { isEditGenerator } = useTypedSelector((state) => state.devicesReducer)
  const { setIsOpenPopupGenerator } = useActions()

  const handleAddPopup = () => {
    setIsOpenPopupGenerator(true)
  }

  const handleEditGenerator = () => {
    setIsEditGenerator(!isEditGenerator)
  }

  return (
    <>
      <ButtonToolbar disabled={!isGeneratorContent} onClick={handleAddPopup} icon={'add'} title={TitleButton.Add} />
      <ButtonToolbar
        disabled={!isGeneratorPanelContent}
        onClick={handleEditGenerator}
        icon={'edit'}
        activated={isEditGenerator}
        title={TitleButton.Edit}
      />
      <ButtonToolbar disabled={true} onClick={() => console.log('remove')} icon={'delete'} title={TitleButton.Delete} />
    </>
  )
}

export default DevicesTools
