import { KEY_ROOT } from '@/components/widgets/equipment-tree/equipment-tree.constant'
import useTreeDraggableHook from '@/components/widgets/equipment-tree/use-tree-draggable.hook'
import { useTreeLoader } from '@/components/widgets/equipment-tree/use-tree-loader.hook'
import { useTreeSelectionHandler } from '@/components/widgets/equipment-tree/use-tree-select-handler.hook'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { findNode } from '@/utils/tree/find-node'
import { Tree } from 'antd'
import cn from 'classnames'
import type { FC, Key } from 'react'
import { useEffect, useState } from 'react'

import styles from './equipment-tree.module.css'

const initialTreeStructure = [
  {
    key: KEY_ROOT,
    title: 'Корень',
    group: EEquipmentTree.ROOT,
    icon: <span className={cn('material-symbols-outlined', styles['icon'])}>folder</span>
  }
]

interface IProps {
  equipmentTreeMode: EEquipmentTreeMode
}

const EquipmentTree: FC<IProps> = ({ equipmentTreeMode }) => {
  const isConfiguration = EEquipmentTreeMode.CONFIGURATION === equipmentTreeMode
  const isConnection = EEquipmentTreeMode.CONNECTION === equipmentTreeMode
  const { treeMenu, selectedTreeItems } = useTypedSelector((state) => state.equipmentsUiReducer)
  const { selectedGroupId, selectedMachineId, selectedMeasurementId, selectedPointId, expendedEquipments } =
    useTypedSelector((state) => state.globalReducer)
  const { setTreeMenu, setSelectedTreeItems } = useActions()
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([])
  const { handleDataLoad } = useTreeLoader(treeMenu, equipmentTreeMode)
  const { handleSelect } = useTreeSelectionHandler(treeMenu)
  const { allowDraggableNode, onDragStart, onDragEnd } = useTreeDraggableHook(equipmentTreeMode)

  useEffect(() => {
    setTreeMenu(initialTreeStructure)
  }, [setTreeMenu])

  useEffect(() => {
    setExpandedKeys((prevState) =>
      Array.from(
        new Set(
          [
            ...prevState,
            ...expendedEquipments,
            KEY_ROOT,
            selectedGroupId,
            selectedMachineId,
            selectedMeasurementId,
            selectedPointId
          ].filter((item): item is string => item !== null)
        )
      )
    )
  }, [expendedEquipments, selectedGroupId, selectedMachineId, selectedMeasurementId, selectedPointId])

  useEffect(() => {
    if (isConnection || isConfiguration) {
      const selectedEquipment: string | null =
        selectedMeasurementId ||
        selectedPointId ||
        selectedMachineId ||
        selectedGroupId?.[selectedGroupId?.length - 1] ||
        null
      if (selectedEquipment) {
        const foundNode = findNode(treeMenu, selectedEquipment)

        if (foundNode?.node) {
          handleSelect(null, { node: foundNode.node })
        }
      }
    }
  }, [
    equipmentTreeMode,
    handleSelect,
    selectedGroupId,
    selectedMachineId,
    selectedMeasurementId,
    selectedPointId,
    treeMenu
  ])

  useEffect(() => {
    if (equipmentTreeMode === EEquipmentTreeMode.CONDITION) {
      const selectedEquipment = selectedMachineId
      if (selectedEquipment) {
        setSelectedTreeItems([selectedEquipment])
      }
    }
  }, [equipmentTreeMode, selectedMachineId, setSelectedTreeItems])

  const handleExpand = (keys: Key[]) => {
    setExpandedKeys(keys)
  }

  return (
    <Tree
      showIcon={true}
      treeData={treeMenu}
      showLine={true}
      loadData={handleDataLoad}
      expandedKeys={expandedKeys}
      onExpand={handleExpand}
      onSelect={handleSelect}
      selectedKeys={selectedTreeItems}
      className={styles['tree']}
      blockNode={true}
      draggable={{ icon: false, nodeDraggable: allowDraggableNode }}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    />
  )
}

export default EquipmentTree
