import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import ContentWrapper from '@/components/controlers/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import SwitchIndicator from '@/components/controlers/switch-indicator/switch-indicator'
import HeaderConfiguration from '@/components/widgets/header-work-space/header-configuration'
import Spinner from '@/components/widgets/spinner/spinner'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/wrappers/controls-wrapper/controls-wrapper'
import { ButtonTitle, DATE_TEMPLATE } from '@/constants/core/common.constant'
import { Vp3711Label } from '@/constants/device/vp3711/vp3711-labels.constant'
import { Vp3711Param } from '@/constants/device/vp3711/vp3711-params.constant'
import { ELtrCrateStatus } from '@/enums/ltr/ltr-crate-status.enum'
import { useGetCrateQuery, useUpdateCrateMutation } from '@/store/api/ltr.api'
import type { TVp3711Form } from '@/types/device/vp3711/vp3711-form.type'
import type { TVp3711Static } from '@/types/device/vp3711/vp3711-static.type'
import type { ICrate } from '@/types/ltr/crate/crate.interface'
import { formatDate } from '@/utils/format-date'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Flex, Form, Input } from 'antd'
import { useForm } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'

import styles from './vp3711.module.css'

type TProps = {
  deviceId: string | undefined
}

const getValuesForForm = (crate: ICrate): TVp3711Form => ({
  name: crate.name,
  comment: crate.comment,
  location: crate.location
})

const Vp3711Panel: FC<TProps> = ({ deviceId }) => {
  const { data: ltrCrate, isLoading } = useGetCrateQuery({ ltrCrateId: deviceId }, { skip: !deviceId })
  const [triggerUpdateCrate, { isLoading: isUpdating }] = useUpdateCrateMutation()
  const [staticData, setStaticData] = useState<TVp3711Static>({})
  const [form] = useForm<TVp3711Form>()

  useEffect(() => {
    if (ltrCrate) {
      const data: TVp3711Static = {
        crateSerial: ltrCrate.crateSerial,
        status: ltrCrate.status,
        statusUpdatingTimestamp: ltrCrate.statusUpdatingTimestamp,
        crateInfo: ltrCrate.crateInfo
      }
      setStaticData(data)

      form.setFieldsValue(getValuesForForm(ltrCrate))
    }
  }, [form, ltrCrate])

  if (isLoading) {
    return <Spinner />
  }

  if (!ltrCrate) {
    return undefined
  }

  const handleUpdateVp3711Finish = async () => {
    const fields = form.getFieldsValue()
    try {
      if (fields.name && deviceId) {
        await triggerUpdateCrate({ ltrCrateId: deviceId, body: fields }).unwrap()
        successNotificationCreate('Параметры модуля обновлены')
      }
    } catch (e) {
      console.error(e)
      errorNotificationCreate(e)
    }
  }

  const handleCancelForm = async () => {
    form.setFieldsValue(getValuesForForm(ltrCrate))
  }

  const isOnlineDevice = staticData.status === ELtrCrateStatus.ONLINE

  const status = staticData.status ? (
    <SwitchIndicator sourceBoolean={isOnlineDevice} trueContent='Устройство онлайн' falseContent='Устройство оффлайн' />
  ) : undefined

  const statusUpdatingTimestamp = staticData.statusUpdatingTimestamp
    ? formatDate(staticData.statusUpdatingTimestamp, DATE_TEMPLATE)
    : undefined

  return (
    <>
      <HeaderConfiguration
        content={
          <Flex gap={5} align='center'>
            <span className='material-symbols-outlined'>dns</span>
            <span>Крейт {ltrCrate.name}</span>
          </Flex>
        }
      />
      <ContentWrapper>
        <Form className={styles.container} form={form} disabled={isUpdating} onFinish={handleUpdateVp3711Finish}>
          <ControlsWrapper>
            <PanelTitle>Параметры крейта</PanelTitle>
            <FormItemApp name={Vp3711Param.Name} label={Vp3711Label.Name}>
              <Input />
            </FormItemApp>
            <FormItemApp name={Vp3711Param.Location} label={Vp3711Label.Location}>
              <TextArea className={styles['text-area']} />
            </FormItemApp>
            <FormItemApp name={Vp3711Param.Comment} label={Vp3711Label.Comment}>
              <TextArea className={styles['text-area']} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <PanelTitle>Информация о крейте</PanelTitle>
            <TextStatic label={Vp3711Label.CrateSerial} value={staticData.crateSerial} />
            <TextStatic label={Vp3711Label.CrateTypeName} value={staticData.crateInfo?.crateTypeName} />
            <TextStatic label={Vp3711Label.BoardRevision} value={staticData.crateInfo?.boardRevision} />
            <TextStatic label={Vp3711Label.CpuType} value={staticData.crateInfo?.cpuType} />
            <TextStatic
              label={Vp3711Label.BootloaderVersion}
              value={staticData.crateInfo?.bootloaderVersion || 'Не определено'}
            />
            <TextStatic label={Vp3711Label.FpgaName} value={staticData.crateInfo?.fpgaName} />
            <TextStatic label={Vp3711Label.FpgaVersion} value={staticData.crateInfo?.fpgaVersion} />
            <TextStatic label={Vp3711Label.SoftwareVersion} value={staticData.crateInfo?.softwareVersion} />
            <PanelTitle withoutBorder={true} className={styles['title']}>
              Статус устройства
            </PanelTitle>
            <TextStatic label={Vp3711Label.Status} value={status} />
            <TextStatic label={Vp3711Label.StatusUpdatingTimestamp} value={statusUpdatingTimestamp} />
          </ControlsWrapper>
          <ControlsWrapper className={styles['buttons']}>
            <ButtonsWrapper>
              <ButtonPrimary
                htmlType='button'
                title={ButtonTitle.Reset}
                isLoadingSkeleton={false}
                isLoading={false}
                disabled={false}
                onClick={handleCancelForm}
              />
              <ButtonPrimary htmlType='submit' title={ButtonTitle.Save} loading={isLoading} />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
    </>
  )
}

export default Vp3711Panel
