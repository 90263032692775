import Charts from '@/app/machine-condition/components/results/components/charts/charts'
import ResultsSidebar from '@/app/machine-condition/components/results/components/results-sidebar/results-sidebar'
import ResultsTools from '@/app/machine-condition/components/results/components/results-tools/results-tools'
import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import Submenu from '@/components/controlers/submenu/submenu'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import { machineConditionLinks } from '@/constants/navigation/links.constant'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'

import styles from './results.module.css'

const Results: FC = () => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const isSelectedMachine = Boolean(selectedMachineId)

  return (
    <>
      <Container>
        <LeftBar>
          {/*<GroupList />*/}
          <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONDITION} />
        </LeftBar>
        <WorkSpace>
          <Submenu links={machineConditionLinks} />

          {isSelectedMachine && (
            <>
              <ResultsTools />

              <div className={styles.content}>
                <ResultsSidebar />
                <Charts />
              </div>
            </>
          )}
        </WorkSpace>
      </Container>
    </>
  )
}

export default Results
