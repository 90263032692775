import type { TMeasurementForm } from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'

export const measurementDefaultValues: { [key in EPhysicalQuantityType]?: TMeasurementForm } = {
  AMPERAGE: undefined,
  DRPM: undefined,
  PHASE: undefined,
  RPM: undefined,
  TEMPERATURE: { unitType: EUnitType.DEGREES_CELSIUS },
  VIBRO_ACCELERATION: { unitType: EUnitType.MS2 },
  VIBRO_DISPLACEMENT: { unitType: EUnitType.MKM },
  VIBRO_VELOCITY: {
    unitType: EUnitType.MMS
  },
  VOLTAGE: { unitType: EUnitType.M_VOLT }
}
