import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import type { IConfigCharts } from '@/types/chart/charts.type'
import type { KeyMap } from 'react-hotkeys'

export const enum ETypeChart {
  SCALAR = 'scalar',
  SPECTRUM = 'spectrum',
  TIMING = 'timing'
}

export const configCharts: IConfigCharts = {
  [ETypeMeasurement.TemperatureDescription]: {
    typeChart: ETypeChart.SCALAR,
    size: 50
  },
  [ETypeMeasurement.CommonLevelDescription]: {
    typeChart: ETypeChart.SCALAR,
    size: 50
  },
  [ETypeMeasurement.ProgramSpecterDescription]: {
    typeChart: ETypeChart.SPECTRUM,
    size: 5
  },
  [ETypeMeasurement.SignalDescription]: {
    typeChart: ETypeChart.TIMING,
    size: 5
  }
}

export const PADDING_CHART = 5

export const SpectrumKeyMap: KeyMap = {
  StepRightDown: { action: 'keydown', sequence: 'right' },
  StepRightUp: { action: 'keyup', sequence: 'right' },
  StepLeftDown: { action: 'keydown', sequence: 'left' },
  StepLeftUp: { action: 'keyup', sequence: 'left' },
  MiddleStepRightDown: { action: 'keydown', sequence: 'shift+right' },
  MiddleStepRightUp: { action: 'keyup', sequence: 'shift+right' },
  MiddleStepLeftDown: { action: 'keydown', sequence: 'shift+left' },
  MiddleStepLeftUp: { action: 'keyup', sequence: 'shift+left' },
  LongStepRightDown: { action: 'keydown', sequence: 'ctrl+shift+right' },
  LongStepRightUp: { action: 'keyup', sequence: 'ctrl+shift+right' },
  LongStepLeftDown: { action: 'keydown', sequence: 'ctrl+shift+left' },
  LongStepLeftUp: { action: 'keyup', sequence: 'ctrl+shift+left' },
  StepUpDown: { action: 'keydown', sequence: 'up' },
  StepUpUp: { action: 'keyup', sequence: 'up' }
}
