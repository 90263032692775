import { calculateRiftsValues } from '@/app/configuration/components/equipments/components/measurements/components/threshold/tolerance-control-settings/tolerance-control-settings.service'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import { ThresholdLabel, ThresholdName, riftCalcTypeValues } from '@/constants/threshold/threshold.constant'
import type { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IThresholdForm } from '@/types/threshold/threshold.type'
import type { FormInstance } from 'antd'
import { InputNumber, Select, Switch } from 'antd'
import type { SwitchChangeEventHandler } from 'antd/es/switch'
import { isNull } from 'lodash'
import type { FC } from 'react'
import React from 'react'

interface IProps {
  toleranceForm: FormInstance<IThresholdForm>
}

const ToleranceControlSettings: FC<IProps> = ({ toleranceForm }) => {
  const { setRifts, setRiftCalcEnabled, setRiftCalcType, setBaseRiftValue } = useActions()
  const { riftCalcEnabled, rifts, riftCalcType, baseRiftValue } = useTypedSelector((state) => state.thresholdReducer)
  const isBaseRiftValueAutoCorrectEnabled = toleranceForm.getFieldValue(ThresholdName.BaseRiftValueAutoCorrectEnabled)

  const handleControlEnabledChange: SwitchChangeEventHandler = (value) => {
    setRiftCalcEnabled(value)
    if (value) {
      const newRifts = calculateRiftsValues(rifts, riftCalcType, baseRiftValue)
      setRifts(newRifts)
    }
  }

  const handleRiftCalcTypeChange = (value: ERiftCalcType) => {
    setRiftCalcType(value)
    const newRifts = calculateRiftsValues(rifts, value, baseRiftValue)
    setRifts(newRifts)
  }

  const handleBaseRiftValueChange = (value: number | null) => {
    if (!isNull(value)) {
      const newRifts = calculateRiftsValues(rifts, riftCalcType, value)
      setRifts(newRifts)
      setBaseRiftValue(value)
    }
  }

  return (
    <>
      <PanelTitle>Параметры допускового контроля</PanelTitle>
      <FormItemApp name={ThresholdName.ControlEnabled} label={ThresholdLabel.ControlEnabled}>
        <Switch />
      </FormItemApp>
      <FormItemApp name={ThresholdName.RiftCalcEnabled} label={ThresholdLabel.RiftCalcEnabled}>
        <Switch onChange={handleControlEnabledChange} />
      </FormItemApp>
      <>
        <FormItemApp
          label={ThresholdLabel.RiftCalcType}
          name={ThresholdName.RiftCalcType}
          rules={[{ required: riftCalcEnabled, message: 'Поле обязательно' }]}
        >
          <Select
            options={riftCalcTypeValues}
            placeholder='Выберите вид расчёта'
            onChange={handleRiftCalcTypeChange}
            disabled={!riftCalcEnabled}
          />
        </FormItemApp>
        <FormItemApp
          label={ThresholdLabel.BaseRiftValue}
          name={ThresholdName.BaseRiftValue}
          rules={[{ required: riftCalcEnabled, message: 'Поле обязательно' }]}
        >
          <InputNumber
            onChange={handleBaseRiftValueChange}
            placeholder='Введите базовый уровень'
            disabled={isBaseRiftValueAutoCorrectEnabled || !riftCalcEnabled}
          />
        </FormItemApp>
        <FormItemApp
          label={ThresholdLabel.SkipLimit}
          name={ThresholdName.SkipLimit}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <InputNumber placeholder='Введите количество повторений' />
        </FormItemApp>
        <FormItemApp
          label={ThresholdLabel.BaseRiftValueAutoCorrectEnabled}
          name={ThresholdName.BaseRiftValueAutoCorrectEnabled}
        >
          <Switch disabled={true} />
        </FormItemApp>
      </>
    </>
  )
}

export default ToleranceControlSettings
