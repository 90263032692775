import type { IToolProps } from '@/components/widgets/mnemonic/ui/tool.interface'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type Konva from 'konva'
import type { FC } from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Circle, Group, Line, Rect, Text } from 'react-konva'
import { Html } from 'react-konva-utils'

const StaticLabelElement: FC<IToolProps> = ({
  element,
  updateMnemonicElement,
  draggable,
  parentSize,
  setContextMenuOptions
}) => {
  const groupRef = useRef<Konva.Group>(null)
  const rectRef = useRef<Konva.Rect>(null)
  const textRef = useRef<Konva.Text>(null)
  const pointRef = useRef<Konva.Circle>(null)
  const lineRef = useRef<Konva.Line>(null)
  const { selectedMnemoItemId } = useTypedSelector((state) => state.mnemonicReducer)
  const { setSelectedMnemoItemId } = useActions()
  const isSelected = selectedMnemoItemId === element?.mnemoElementId

  const [isEdit, setIsEdit] = useState(false)

  const { setMnemoSchemeItem } = useActions()

  const { mnemoSchemeItem } = useTypedSelector((state) => state.mnemonicReducer)

  useEffect(() => {
    setIsEdit(false)
  }, [mnemoSchemeItem])

  useLayoutEffect(() => {
    if (!rectRef.current) return

    rectRef.current.attrs.width = textRef.current?.width()
    rectRef.current.attrs.height = textRef.current?.height()
  }, [element])

  if (!element || !parentSize) return null

  return (
    <>
      {element?.pointerPosition ? (
        <Line
          ref={lineRef}
          points={[
            pointRef?.current?.attrs.x || element?.pointerPosition?.x || 0,
            pointRef?.current?.attrs.y || element?.pointerPosition?.y || 0,
            groupRef?.current?.attrs.x + 10 || element?.x + 10 || 0,
            groupRef?.current?.attrs.y + 10 || element?.y + 10 || 0
          ]}
          stroke={'#000'}
          strokeWidth={1}
        />
      ) : null}

      {isEdit ? (
        <Html
          divProps={{
            style: {
              position: 'absolute'
            }
          }}
        >
          <textarea
            defaultValue={element.staticLabelElement?.text}
            onBlur={(evt) => {
              setIsEdit(false)

              if (!updateMnemonicElement) return

              updateMnemonicElement({
                ...element,
                staticLabelElement: {
                  ...element.staticLabelElement,
                  text: evt.target.value.trim()
                }
              })
            }}
            style={{
              position: 'absolute',
              top: element?.y,
              left: element?.x,
              padding: 0,
              paddingTop: 5,
              paddingLeft: 5,
              margin: 0,
              overflow: 'hidden',
              outline: 'none',
              borderWidth: 1,
              borderColor: '#40a9ff',
              resize: 'none',
              fontSize: 20,
              height: 35,
              borderRadius: 5
            }}
          />
        </Html>
      ) : (
        <Group
          onClick={
            draggable
              ? () => {
                  if (element) {
                    setMnemoSchemeItem(element)
                    setSelectedMnemoItemId(element.mnemoElementId)
                  }
                }
              : undefined
          }
          onDblClick={
            draggable
              ? () => {
                  setIsEdit(true)
                }
              : undefined
          }
          onContextMenu={(e) => {
            e.evt.preventDefault()

            const pointerPosition = e.target.getStage()?.getPointerPosition()

            if (!pointerPosition || !setContextMenuOptions) return

            setContextMenuOptions({
              isOpen: true,
              position: {
                x: pointerPosition.x,
                y: pointerPosition.y
              }
            })
          }}
          onMouseEnter={(e) => {
            // style stage container:
            const container = e.target.getStage()?.container()
            if (!container || !draggable) return
            container.style.cursor = 'grab'
          }}
          onMouseDown={(e) => {
            // style stage container:
            const container = e.target.getStage()?.container()
            if (!container || !draggable) return
            container.style.cursor = 'grabbing'
          }}
          onMouseLeave={(e) => {
            const container = e.target.getStage()?.container()
            if (!container || !draggable) return
            container.style.cursor = 'default'
          }}
          ref={groupRef}
          x={element.x}
          y={element.y}
          draggable={draggable}
          onDragEnd={(e) => {
            if (!updateMnemonicElement || !element.mnemoSchemeId || e.target.attrs.x < 0 || e.target.attrs.y < 0) return

            updateMnemonicElement({
              ...element,
              x: e.target.attrs.x,
              y: e.target.attrs.y
            })
          }}
          onDragMove={(e) => {
            const node = e.target
            const textNode = textRef.current
            const newPosition = { x: node?.attrs.x, y: node?.attrs.y }

            if (!newPosition?.x || !newPosition?.y || !node || !textNode) return

            if (newPosition.x < 0) {
              newPosition.x = 0
            }

            if (newPosition.x > parentSize.width - textNode.width()) {
              newPosition.x = parentSize.width - textNode.width()
            }

            if (newPosition.y < 0) {
              newPosition.y = 0
            }

            if (newPosition.y > parentSize?.height - textNode.height()) {
              newPosition.y = parentSize?.height - textNode.height()
            }

            node?.setPosition(newPosition)

            if (!lineRef.current) return

            lineRef.current.attrs.points = [
              lineRef.current.attrs.points[0],
              lineRef.current.attrs.points[1],
              newPosition.x + 10,
              newPosition.y + 10
            ]
          }}
        >
          <Rect
            ref={rectRef}
            fill={element.staticLabelElement?.fontStyle?.backgroundColor || '#fff'}
            stroke={isSelected ? 'red' : element.staticLabelElement?.borderStyle?.color}
            strokeWidth={element.staticLabelElement?.borderStyle?.size}
            cornerRadius={8}
          />

          <Text
            ref={textRef}
            onContextMenu={(e) => {
              e.evt.preventDefault()
            }}
            padding={10}
            text={element?.staticLabelElement?.text}
            align={'center'}
            fontSize={element?.staticLabelElement?.fontStyle?.fontSize}
            fill={element?.staticLabelElement?.fontStyle?.color || '#000'}
            fontStyle={
              element?.staticLabelElement?.fontStyle?.bold || element?.staticLabelElement?.fontStyle?.italic
                ? `${element?.staticLabelElement?.fontStyle?.bold ? 'bold ' : ''}${
                    element?.staticLabelElement?.fontStyle?.italic ? 'italic' : ''
                  }`
                : 'normal'
            }
          />
        </Group>
      )}

      {element?.pointerPosition ? (
        <Circle
          ref={pointRef}
          draggable={draggable}
          fill={'#000'}
          width={10}
          height={10}
          onDragEnd={(e) => {
            if (!updateMnemonicElement || !element?.mnemoSchemeId || e.target.attrs.x < 0 || e.target.attrs.y < 0)
              return

            updateMnemonicElement({
              ...element,
              pointerPosition: {
                x: e.target.attrs.x,
                y: e.target.attrs.y
              }
            })
          }}
          onDragMove={(e) => {
            if (!lineRef.current) return

            lineRef.current.attrs.points = [
              e.target.attrs.x,
              e.target.attrs.y,
              lineRef.current.attrs.points[2],
              lineRef.current.attrs.points[3]
            ]
          }}
          x={element?.pointerPosition?.x}
          y={element?.pointerPosition?.y}
        />
      ) : null}
    </>
  )
}

export default StaticLabelElement
