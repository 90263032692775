import Spinner from '@/components/widgets/spinner/spinner'
import { configCharts } from '@/constants/chart/charts.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementsQuery } from '@/store/api/measurements.api'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'

import styles from './measurements-list.module.css'

import MeasurementItem from './components/measurement-item'

const MeasurementsList = () => {
  const { setSelectedMeasurementId, setConfigChart } = useActions()
  const { selectedMachineId, selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const { data: measurementsResponse, isFetching } = useGetMeasurementsQuery(selectedPointId, {
    skip: !selectedPointId
  })

  const measurements = measurementsResponse?.content

  if (isFetching) {
    return <Spinner />
  }

  const handleMeasurementSelect = (id: IMeasurement['measuringPointId']) => {
    // dispatch(baseApi.util.invalidateTags([ApiTag.MeasurementsResult]))
    if (id) {
      setSelectedMeasurementId(id)

      const foundMeasurement = measurements?.find((measurement) => measurement.measurementId === id)
      const typeMeasurement = getTypeMeasurement(foundMeasurement)
      if (typeMeasurement) {
        setConfigChart(configCharts[typeMeasurement])
      }
    }
  }

  return (
    selectedPointId &&
    selectedMachineId && (
      <div className={styles.container}>
        {measurements?.map((measurementItem, index) => (
          <MeasurementItem
            key={measurementItem.measurementId}
            measurement={measurementItem}
            onSelectMeasurementClick={handleMeasurementSelect}
          />
        ))}
        {measurements?.length === 0 && (
          <p style={{ textAlign: 'center', fontSize: '12px', margin: 0 }}>Виды измерения отсутствуют</p>
        )}
      </div>
    )
  )
}

export default MeasurementsList
