import DocTooltip from '@/components/widgets/doc-tooltip/doc-tooltip'
import cn from 'classnames'
import type { FC, PropsWithChildren } from 'react'

import styles from './field-wrapper.module.css'

type FieldWrapperProps = {
  tooltipKey?: string
  layout?: 'vertical' | 'horizontal'
  className?: string
  lastElement?: boolean
}

const FieldWrapper: FC<PropsWithChildren<FieldWrapperProps>> = ({
  children,
  tooltipKey,
  className,
  layout,
  lastElement = false
}) => (
  <div
    className={cn(styles['field-wrapper'], className, {
      [styles['vertical']]: layout === 'vertical',
      [styles['last-element']]: lastElement
    })}
  >
    {tooltipKey && (
      <DocTooltip formKey={tooltipKey} className={styles.tooltip} />
    )}
    {children}
  </div>
)

export default FieldWrapper
