import { getTreeCharFreqs } from '@/app/machine-condition/components/results/components/charts/components/express-sidebar/components/char-freq-tree-select/char-freq-tree-select.service'
import { useGetKinematicElements } from '@/hooks/api/use-get-kinematic-elements'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { ICharFreqTree } from '@/types/kinematic/char-freq.type'
import type { TreeSelectProps } from 'antd'
import { TreeSelect } from 'antd'
import type { FC } from 'react'

type TProps = TreeSelectProps<string | null | undefined, ICharFreqTree>

const CharFreqTreeSelect: FC<TProps> = ({ ...props }) => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { kinematicElementsQuery } = useGetKinematicElements(selectedMachineId)
  const { data: elements } = kinematicElementsQuery
  const charFreqsOptions = elements?.content ? getTreeCharFreqs(elements.content) : undefined

  return (
    <>
      <TreeSelect
        {...props}
        treeData={charFreqsOptions}
        placeholder={'Выберите характерную частоту'}
        treeDefaultExpandAll={true}
        showSearch={true}
        treeNodeLabelProp='labelTitle'
        popupMatchSelectWidth={false}
      />
    </>
  )
}

export default CharFreqTreeSelect
