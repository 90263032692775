import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import ToolBar from '@/components/controlers/layouts/tool-bar/tool-bar'
import { ReportKey } from '@/constants/reports.constant'
import { useAppDispatch } from '@/hooks/use-app-dispatch'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { generationPdfHarmonic, generationPdfSquare, generationPdfVp3701 } from '@/store/reducers/thunks/reports.thunk'
import { isEmpty } from '@/utils/common.util'
import type { FC } from 'react'

const ReportsToolbar: FC = () => {
  const dispatch = useAppDispatch()
  const { checkedData, selectedReport } = useTypedSelector((state) => state.reportsReducer)

  const handleClickGenerationPdf = (reportKey: ReportKey) => {
    switch (reportKey) {
      case ReportKey.Vp3701:
        dispatch(generationPdfVp3701(checkedData))
        return
      case ReportKey.Harmonic:
        dispatch(generationPdfHarmonic(checkedData))
        return
      case ReportKey.Square:
        dispatch(generationPdfSquare(checkedData))
        return
    }
  }

  return (
    <>
      <ToolBar>
        {selectedReport && (
          <>
            <ButtonToolbar
              icon={'description'}
              title={'Создать PDF'}
              disabled={isEmpty(checkedData)}
              onClick={() => handleClickGenerationPdf(selectedReport.key)}
            />
          </>
        )}
      </ToolBar>
    </>
  )
}

export default ReportsToolbar
