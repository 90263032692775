import FormItemsHarmonicSignal from '@/app/configuration/components/devices/popup-generator/form-items-harmonic-signal/form-items-harmonic-signal'
import FormItemsModuleOne from '@/app/configuration/components/devices/popup-generator/form-items-module-one/form-items-module-one'
import FormItemsModuleTwo from '@/app/configuration/components/devices/popup-generator/form-items-module-two/form-items-module-two'
import FormItemsParameters from '@/app/configuration/components/devices/popup-generator/form-items-parameters/form-items-parameters'
import FormItemsSquareSignal from '@/app/configuration/components/devices/popup-generator/form-items-square-signal/form-items-square-signal'
import FormItemsTypeChannel from '@/app/configuration/components/devices/popup-generator/form-items-type-channel/form-items-type-channel'
import FormItemsType from '@/app/configuration/components/devices/popup-generator/form-items-type/form-items-type'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import Popup from '@/components/widgets/popup/popup'
import { GeneratorKey, TypeGeneratorValue } from '@/constants/core/common.constant'
import { FormBranch, FormTitleGenerator } from '@/constants/devices.constant'
import { NotificationMessageConstant } from '@/constants/notification-message.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useCreateGeneratorMutation, useGetGeneratorsQuery } from '@/store/api/devices.api'
import type { FormDataType, IGenerator } from '@/types/devices.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import styles from './popup-generator.module.css'

import { ParameterDevices } from '../devices.constant'

const PopupGenerator: FC = () => {
  const [form] = useForm()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isLastStep, setIsLastStep] = useState(false)
  const [isErrorValidation] = useState(false)
  const [formData, setFormData] = useState<FormDataType>({})
  const { data, isLoading: isLoadingGetGenerators } = useGetGeneratorsQuery()

  const { setIsOpenPopupGenerator, setFormGeneratorStep, setFormGeneratorBranch } = useActions()
  const { isOpenPopupGenerator, formGeneratorBranch, formGeneratorStep, moduleOneActive } = useTypedSelector(
    (state) => state.devicesReducer
  )
  const [createGenerator, { isLoading: isLoadingCreateGenerator, isError: isErrorCreateGenerator }] =
    useCreateGeneratorMutation()

  const isHarmonicSignal = formGeneratorBranch === FormBranch.Harmonic
  const isSquareSignal = formGeneratorBranch === FormBranch.Square

  useEffect(() => {
    if (formGeneratorStep === 4 && isHarmonicSignal) {
      if (!moduleOneActive) {
        setIsLastStep(true)
      } else {
        setIsLastStep(false)
      }
    }
  }, [formGeneratorStep, isHarmonicSignal, moduleOneActive])

  const handleNextStep = async () => {
    if (!isLastStep) {
      setFormGeneratorStep(formGeneratorStep + 1)
      const fields = form.getFieldsValue()
      setFormData({ ...formData, ...fields })

      if (formGeneratorStep === 3) {
        setFormGeneratorBranch(fields.type)
        setIsLastStep(true)
      }

      if (isSquareSignal) {
        setIsLastStep(false)
      }

      if (formGeneratorStep === 5 && isHarmonicSignal) {
        setIsLastStep(true)
      }
    }
  }

  const resetForm = () => {
    setIsOpenPopupGenerator(false)
    setIsLastStep(false)
    setFormGeneratorStep(1)
    setFormGeneratorBranch(FormBranch.Common)
    setFormData({})
  }

  const handleSubmitForm = async () => {
    const fields = form.getFieldsValue()
    const dataGenerator = { ...formData, ...fields }

    const requestBody = {
      name: dataGenerator[GeneratorKey.Name],
      type: dataGenerator[GeneratorKey.Type].toUpperCase(),
      squareSettings: {
        normAmp: dataGenerator[GeneratorKey.NormAmp],
        squareBaseFreq: dataGenerator[GeneratorKey.SquareBaseFreq],
        squareLowLevel: dataGenerator[GeneratorKey.SquareLowLevel],
        squareHighToLow: dataGenerator[GeneratorKey.SquareHighToLow],
        squareDuty: dataGenerator[GeneratorKey.SquareDuty],
        squarePhase: dataGenerator[GeneratorKey.SquarePhase]
      },
      harmonicCommonSettings: {
        harmonics: [
          {
            normAmp: dataGenerator[GeneratorKey.NormAmp],
            dclevel: dataGenerator[GeneratorKey.Dclevel],
            baseFreq: dataGenerator[GeneratorKey.BaseFreq],
            baseAmp: dataGenerator[GeneratorKey.BaseAmp],
            nharm: dataGenerator[GeneratorKey.Nharm],
            ampSet: dataGenerator[GeneratorKey.AmpSet],
            lastAmp: dataGenerator[GeneratorKey.LastAmp],
            phaseSet: dataGenerator[GeneratorKey.PhaseSet],
            phaseShift: dataGenerator[GeneratorKey.PhaseShift],
            whiteNoise: dataGenerator[GeneratorKey.WhiteNoise],
            m1_Type: dataGenerator[GeneratorKey.M1_Type],
            m1_BaseFreq: dataGenerator[GeneratorKey.M1_BaseFreq],
            m1_DeepA: dataGenerator[GeneratorKey.M1_DeepA],
            m1_DeepT: dataGenerator[GeneratorKey.M1_DeepT],
            m1_Nharm: dataGenerator[GeneratorKey.M1_Nharm],
            m1_AmpSet: dataGenerator[GeneratorKey.M1_AmpSet],
            m1_LastAmp: dataGenerator[GeneratorKey.M1_LastAmp],
            m1_PhaseSet: dataGenerator[GeneratorKey.M1_PhaseSet],
            m1_PhaseShift: dataGenerator[GeneratorKey.M1_PhaseShift],
            m2_Type: dataGenerator[GeneratorKey.M2_Type],
            m2_BaseFreq: dataGenerator[GeneratorKey.M2_BaseFreq],
            m2_DeepA: dataGenerator[GeneratorKey.M2_DeepA],
            m2_DeepT: dataGenerator[GeneratorKey.M2_DeepT],
            m2_Nharm: dataGenerator[GeneratorKey.M2_Nharm],
            m2_AmpSet: dataGenerator[GeneratorKey.M2_AmpSet],
            m2_LastAmp: dataGenerator[GeneratorKey.M2_LastAmp],
            m2_PhaseSet: dataGenerator[GeneratorKey.M2_PhaseSet],
            m2_PhaseShift: dataGenerator[GeneratorKey.M2_PhaseShift]
          }
        ],
        normAmp: dataGenerator[GeneratorKey.NormAmp]
      }
    } as IGenerator

    if (dataGenerator.type === TypeGeneratorValue.Harmonic) {
      requestBody.squareSettings = null
    } else {
      requestBody.harmonicCommonSettings = null
    }

    try {
      const generatorData = await createGenerator(requestBody).unwrap()
      if (!generatorData?.id) throw new Error()
      searchParams.set(ParameterDevices.TypeGenerator, dataGenerator[GeneratorKey.Type])
      searchParams.set(ParameterDevices.GeneratorID, generatorData?.id)
      setSearchParams(searchParams)
      successNotificationCreate(NotificationMessageConstant.Generator)
      resetForm()
      form.resetFields()
    } catch (err) {
      errorNotificationCreate(err)
    }
  }

  const handlePreviousStep = () => {
    const step = formGeneratorStep - 1
    setFormGeneratorStep(formGeneratorStep - 1)

    const fieldsToRemove = form.getFieldsValue() as FormDataType
    const updatedFormData = { ...formData } as FormDataType
    for (const field in fieldsToRemove) {
      delete updatedFormData[field as keyof FormDataType]
      setFormData(updatedFormData)
    }

    setIsLastStep(false)
    if (step === 3) {
      setFormGeneratorBranch(FormBranch.Common)
    }
  }

  const handleClosePopup = () => {
    resetForm()
  }

  const formScheme = {
    [FormBranch.Common]: [
      {
        step: 1,
        title: FormTitleGenerator.Type,
        component: <FormItemsType />
      },
      {
        step: 2,
        title: FormTitleGenerator.Parameters,
        component: <FormItemsParameters data={data} isLoading={isLoadingGetGenerators} />
      },
      {
        step: 3,
        title: FormTitleGenerator.TypeChannel,
        component: <FormItemsTypeChannel />
      }
    ],
    [FormBranch.Harmonic]: [
      {
        step: 4,
        title: FormTitleGenerator.SignalParameters,
        component: <FormItemsHarmonicSignal form={form} />
      },
      {
        step: 5,
        title: FormTitleGenerator.ParamsModulatingOne,
        component: <FormItemsModuleOne />
      },
      {
        step: 6,
        title: FormTitleGenerator.ParamsModulatingTwo,
        component: <FormItemsModuleTwo />
      }
    ],
    [FormBranch.Square]: [
      {
        step: 4,
        title: FormTitleGenerator.SignalParameters,
        component: <FormItemsSquareSignal />
      }
    ]
  }

  const contentInfo = formScheme[formGeneratorBranch].find((item) => item.step === formGeneratorStep)

  if (!contentInfo) {
    return null
  }

  return (
    <>
      <Popup
        title={`${contentInfo.title}`}
        onCancel={handleClosePopup}
        isOpen={isOpenPopupGenerator}
        isError={isErrorCreateGenerator || isErrorValidation}
      >
        <div className={`${styles.formContainer}`}>
          <Form form={form} onFinish={isLastStep ? handleSubmitForm : handleNextStep} onReset={handleClosePopup}>
            {contentInfo.component}

            <div className={`${styles['button-container']}`}>
              <ButtonPrimary htmlType='reset' title='ОТМЕНА' />
              <ButtonPrimary
                onClick={handlePreviousStep}
                htmlType='button'
                title='НАЗАД'
                disabled={formGeneratorStep === 1}
              />
              <ButtonPrimary
                htmlType='submit'
                title={isLastStep ? 'Добавить' : 'Далее'}
                isLoadingSkeleton={isLoadingGetGenerators}
                isLoading={isLoadingCreateGenerator}
                disabled={isLoadingCreateGenerator}
              />
            </div>
          </Form>
        </div>
      </Popup>
    </>
  )
}

export default PopupGenerator
