import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import { averageTypeOptions, mapAverageType } from '@/constants/measurement/average-type.constant'
import { mapOverlayType, overlayTypeOptions } from '@/constants/measurement/program-specter/overlay-type.constant'
import { ProgramSpecterLabel } from '@/constants/measurement/program-specter/program-specter-label.constant'
import {
  mapSpecterLineCount,
  specterLineCountOptions
} from '@/constants/measurement/program-specter/specter-line-count.constant'
import {
  mapVisualFreqLimit,
  visualFreqLimitOptions
} from '@/constants/measurement/program-specter/visual-freq-limit.constant'
import { mapWindowsType, windowsTypeOptions } from '@/constants/measurement/program-specter/windows-type.constant'
import type { IProgramSpecterDescription } from '@/types/measurement/measurement.type'
import { validateMinValue } from '@/utils/validation-rules'
import { InputNumber, Select } from 'antd'
import type { FC } from 'react'

type TProps = {
  isEditMode: boolean
  isAddMode: boolean
  programSpecterDescription?: IProgramSpecterDescription
}

const SpecterForm: FC<TProps> = ({ isEditMode, isAddMode, programSpecterDescription }) => (
  <>
    {isAddMode && (
      <>
        <FormItemApp
          name={['programSpecterDescription', 'visualFreqLimit']}
          label={ProgramSpecterLabel.VisualFreqLimit}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={visualFreqLimitOptions} />
        </FormItemApp>

        <FormItemApp
          name={['programSpecterDescription', 'specterLineCount']}
          label={ProgramSpecterLabel.SpecterLineCount}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={specterLineCountOptions} />
        </FormItemApp>

        <FormItemApp
          name={['programSpecterDescription', 'windowType']}
          label={ProgramSpecterLabel.WindowsType}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={windowsTypeOptions} />
        </FormItemApp>
        <FormItemApp
          name={['programSpecterDescription', 'averageType']}
          label={ProgramSpecterLabel.AverageType}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={averageTypeOptions} />
        </FormItemApp>
        <FormItemApp
          name={['programSpecterDescription', 'overlapType']}
          label={ProgramSpecterLabel.OverlapType}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={overlayTypeOptions} />
        </FormItemApp>
        <FormItemApp
          label={ProgramSpecterLabel.AverageNumber}
          name={['programSpecterDescription', 'averageNumber']}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <InputNumber max={256} min={1} step={1} />
        </FormItemApp>
        <FormItemApp
          label={ProgramSpecterLabel.Lvl0db}
          name={['programSpecterDescription', 'lvl0db']}
          rules={[
            { required: true, message: 'Поле обязательно' },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber decimalSeparator={','} />
        </FormItemApp>
      </>
    )}
    {isEditMode && programSpecterDescription && (
      <>
        <TextStatic
          label={ProgramSpecterLabel.VisualFreqLimit}
          value={mapVisualFreqLimit[programSpecterDescription.visualFreqLimit]}
        />
        <TextStatic
          label={ProgramSpecterLabel.SpecterLineCount}
          value={mapSpecterLineCount[programSpecterDescription.specterLineCount]}
        />
        <TextStatic
          label={ProgramSpecterLabel.WindowsType}
          value={mapWindowsType[programSpecterDescription.windowType]}
        />
        <TextStatic
          label={ProgramSpecterLabel.AverageType}
          value={mapAverageType[programSpecterDescription.averageType]}
        />
        <TextStatic
          label={ProgramSpecterLabel.OverlapType}
          value={mapOverlayType[programSpecterDescription.overlapType]}
        />
        <TextStatic label={ProgramSpecterLabel.AverageNumber} value={programSpecterDescription.averageNumber} />
        <TextStatic label={ProgramSpecterLabel.Lvl0db} value={programSpecterDescription.lvl0db} />
      </>
    )}
  </>
)
export default SpecterForm
