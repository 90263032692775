import { Skeleton } from 'antd'
import type { SkeletonInputProps } from 'antd/es/skeleton/Input'
import type { FC } from 'react'
import React from 'react'

import styles from './skeleton-input.module.css'

const SkeletOnInput: FC<SkeletonInputProps> = ({ active }) => (
  <Skeleton.Input active={active} className={styles.input} />
)

export default SkeletOnInput
