import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import { averageTypeOptions, mapAverageType } from '@/constants/measurement/average-type.constant'
import { CommonLevelLabel } from '@/constants/measurement/common-level/common-level-label.constant'
import { detectorTypeOptions, mapDetectorType } from '@/constants/measurement/common-level/detector-type.constant'
import { frequencyRangeOptions, mapFrequencyRange } from '@/constants/measurement/common-level/frequency-range.constant'
import type { ICommonLevelDescription } from '@/types/measurement/measurement.type'
import { validateMinValue } from '@/utils/validation-rules'
import { InputNumber, Select } from 'antd'
import type { FC } from 'react'

type TProps = {
  isEditMode: boolean
  isAddMode: boolean
  commonLevelDescription?: ICommonLevelDescription
}

const CommonLevelForm: FC<TProps> = ({ isEditMode, isAddMode, commonLevelDescription }) => (
  <>
    {isAddMode && (
      <>
        <FormItemApp
          label={CommonLevelLabel.DetectorType}
          name={['commonLevelDescription', 'detectorType']}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={detectorTypeOptions} />
        </FormItemApp>

        <FormItemApp
          label={CommonLevelLabel.FrequencyRange}
          name={['commonLevelDescription', 'frequencyRange']}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={frequencyRangeOptions} />
        </FormItemApp>
        <FormItemApp
          label={CommonLevelLabel.AverageType}
          name={['commonLevelDescription', 'averageType']}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <Select options={averageTypeOptions} />
        </FormItemApp>
        <FormItemApp
          label={CommonLevelLabel.AverageNumber}
          name={['commonLevelDescription', 'averageNumber']}
          rules={[{ required: true, message: 'Поле обязательно' }]}
        >
          <InputNumber max={256} min={1} step={1} />
        </FormItemApp>
        <FormItemApp
          label={CommonLevelLabel.Lvl0db}
          name={['commonLevelDescription', 'lvl0db']}
          rules={[
            { required: true, message: 'Поле обязательно' },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber decimalSeparator={','} />
        </FormItemApp>
      </>
    )}
    {isEditMode && commonLevelDescription && (
      <>
        <TextStatic
          label={CommonLevelLabel.DetectorType}
          value={mapDetectorType[commonLevelDescription.detectorType]}
        />
        <TextStatic
          label={CommonLevelLabel.FrequencyRange}
          value={mapFrequencyRange[commonLevelDescription.frequencyRange]}
        />
        <TextStatic label={CommonLevelLabel.AverageType} value={mapAverageType[commonLevelDescription.averageType]} />
        <TextStatic label={CommonLevelLabel.AverageNumber} value={commonLevelDescription.averageNumber} />
        <TextStatic label={CommonLevelLabel.Lvl0db} value={commonLevelDescription.lvl0db} />
      </>
    )}
  </>
)

export default CommonLevelForm
