import Popup from '@/components/widgets/popup/popup'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useCreateMnemonicMutation } from '@/store/api/mnemo.api'
import { errorNotificationCreate } from '@/utils/notification-creators'
import { Button, Form, Input } from 'antd'
import type { FC } from 'react'
import React, { useCallback } from 'react'

import styles from '@/components/widgets/popup/popup.module.css'

type TProps = {
  setIsPopupOpen: (value: React.SetStateAction<boolean>) => void
  isPopupOpen: boolean
}

const PopupMnemonic: FC<TProps> = ({ setIsPopupOpen, isPopupOpen }) => {
  const [form] = Form.useForm()

  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const [createMnemonic] = useCreateMnemonicMutation()

  const handleReset = useCallback(() => {
    setIsPopupOpen(false)
    form.resetFields()
  }, [form, setIsPopupOpen])

  const handleFinish = useCallback(async () => {
    try {
      const fieldValues = form.getFieldsValue()

      const mnemonicData = await createMnemonic({
        name: fieldValues.nameMnemonic,
        nodeId: selectedMachineId
      }).unwrap()

      if (!mnemonicData.id) throw new Error('error')

      setIsPopupOpen(false)
    } catch (error) {
      errorNotificationCreate(error)
    }
  }, [createMnemonic, form, selectedMachineId, setIsPopupOpen])

  return (
    <Popup isOpen={isPopupOpen} title={'Добавить мнемосхему'}>
      <Form onReset={handleReset} onFinish={handleFinish} form={form} name={'mnemonic'}>
        <Form.Item
          name={'nameMnemonic'}
          rules={[
            {
              required: true,
              message: 'Нужно ввести имя маршрута'
            }
          ]}
        >
          <Input placeholder={'Введите имя мнемосхемы'} />
        </Form.Item>

        <div className={styles.buttons}>
          <Button htmlType={'submit'} className={styles.button}>
            Добавить
          </Button>
          <Button htmlType={'reset'} className={styles.button}>
            Отмена
          </Button>
        </div>
      </Form>
    </Popup>
  )
}

export default PopupMnemonic
