import KinematicLeftBar from '@/app/configuration/components/kinematics-configuration/components/kinematic-left-bar/kinematic-left-bar'
import KinematicToolBar from '@/app/configuration/components/kinematics-configuration/components/kinematic-tool-bar/kinematic-tool-bar'
import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import ToolBar from '@/components/controlers/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import Submenu from '@/components/controlers/submenu/submenu'
import KinematicSpace from '@/components/widgets/kinematic-space/kinematic-space'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { EKinematicWorkMode } from '@/enums/kinematic/work-mode.enum'
import { useGetCurrentKinematicScheme } from '@/hooks/api/use-get-current-kinematic-scheme'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC } from 'react'
import { useEffect } from 'react'

const KinematicsConfiguration: FC = () => {
  const { setWorkMode } = useActions()
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { data: currentKinematic, isFetching: isFetchingCurrentKinematic } =
    useGetCurrentKinematicScheme(selectedMachineId)

  useEffect(() => {
    setWorkMode(EKinematicWorkMode.Configuration)
  }, [setWorkMode])

  return (
    <Container>
      <ToolBar>
        <KinematicToolBar kinematicScheme={currentKinematic} />
      </ToolBar>
      <LeftBar>
        <KinematicLeftBar kinematicScheme={currentKinematic} isLoadingKinematicScheme={isFetchingCurrentKinematic} />
      </LeftBar>

      <WorkSpace>
        <Submenu links={configurationLinks} />

        {currentKinematic && selectedMachineId && <KinematicSpace kinematicScheme={currentKinematic} />}
      </WorkSpace>
    </Container>
  )
}

export default KinematicsConfiguration
