import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { IEquipmentTreeNode } from '@/types/tree/equipment-tree-node.interface'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import type { FC, Key } from 'react'

export const mapMeasurementToTree = (measurements: IMeasurement[], icon: FC): IEquipmentTreeNode[] =>
  measurements.map((measurement) => ({
    key: measurement.measurementId,
    title: measurement.name,
    group: EEquipmentTree.MEASUREMENTS,
    isLeaf: true,
    icon: icon
  }))

export const findParentKeys = (key: string, nodes: ITreeNode[], parentKey?: Key): Key[] => {
  for (const node of nodes) {
    if (node.key === key) {
      return parentKey ? [parentKey] : []
    }
    if (node.children) {
      const result = findParentKeys(key, node.children, node.key)
      if (result.length > 0) {
        return parentKey ? [parentKey, ...result] : result
      }
    }
  }
  return []
}
