import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { IMarker, IResultMarkers } from '@/types/express-analysis/marker/marker.type'
import { findClosestByField } from '@/utils/search.util'
import { isNumber } from 'lodash'

export const calculateResultsMarkers = (
  chartData: IChartSpectrum[],
  markers: IMarker[],
  title: string
): IResultMarkers => {
  // Вычисление разницы между двумя значениями
  const calculateDifference = (value1?: number, value2?: number) =>
    isNumber(value1) && isNumber(value2) ? Math.abs(value1 - value2) : null

  // Получение результатов для всех маркеров
  const charts = markers.map((marker) =>
    chartData?.[marker.chartIndex] ? findClosestByField<IChartSpectrum>(chartData, 'frequency', marker.frequency) : null
  )
  const [frequency1, frequency2, frequency3] = charts.map((chart) => chart?.frequency)
  const [amplitude1, amplitude2, amplitude3] = charts.map((chart) => chart?.amplitude)

  return {
    title: title,
    charts: charts,
    frequencyD12: calculateDifference(frequency1, frequency2),
    frequencyD13: calculateDifference(frequency1, frequency3),
    frequencyD23: calculateDifference(frequency2, frequency3),
    amplitudeD12: calculateDifference(amplitude1, amplitude2),
    amplitudeD13: calculateDifference(amplitude1, amplitude3),
    amplitudeD23: calculateDifference(amplitude2, amplitude3)
  }
}

export const calculateInitialMarkers = (chartData: IChartSpectrum[]): IMarker[] => {
  const markerChartIndex1 = Math.round(chartData.length * 0.1 - 1)
  const markerChartIndex2 = Math.round(chartData.length * 0.3 - 1)
  const markerChartIndex3 = Math.round(chartData.length * 0.5 - 1)

  return Array.of(markerChartIndex1, markerChartIndex2, markerChartIndex3).map((chartIndex, index) => ({
    index: index,
    chartIndex: chartIndex,
    frequency: chartData[chartIndex].frequency
  }))
}

export const calculateMarkersForNewSpecter = (chardData: IChartSpectrum[], markers: IMarker[]): IMarker[] =>
  markers.map((marker) => {
    const lastIndex = chardData[chardData.length - 1].index
    if (marker.chartIndex > lastIndex) {
      const newMarker = chardData[Math.round(lastIndex / 2)]
      return {
        index: marker.index,
        chartIndex: newMarker.index,
        frequency: newMarker.frequency
      }
    }

    return {
      index: marker.index,
      chartIndex: marker.chartIndex,
      frequency: chardData[marker.chartIndex].frequency
    }
  })
