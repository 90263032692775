import type { FC } from 'react'
import React from 'react'

import styles from './values-indicator.module.css'

import type { IToolProps } from '../tool.interface'

const ValueIndicator: FC<IToolProps> = ({ handleDoubleClick }) => (
  <button className={styles['value-indicator']} type={'button'} onDoubleClick={handleDoubleClick}>
    <p>Значение</p>
  </button>
)

export default ValueIndicator
