import { useTypedSelector } from '@/hooks/use-typed-selector'
import cn from 'classnames'
import React, { useEffect, useState } from 'react'

import styles from '@/components/widgets/mnemonic/indicator-settings/indicator-settings.module.css'

import BorderItem from './components/border-item'
import FillItem from './components/fill-item'
import FontItem from './components/font-item'

const IndicatorSettings = () => {
  const { mnemoSchemeItem } = useTypedSelector((state) => state.mnemonicReducer)

  const [isHidden, setIsHidden] = useState(true)

  const hideButtonHandler = () => {
    setIsHidden((prev) => !prev)
  }

  useEffect(() => {
    setIsHidden(true)
  }, [mnemoSchemeItem])

  return mnemoSchemeItem?.staticLabelElement || mnemoSchemeItem?.measurementLastValueElement ? (
    <div className={`${styles.container}`}>
      <button onClick={hideButtonHandler} className={styles['hide-button']}>
        <span className='material-symbols-sharp'>expand_more</span>
      </button>
      <div
        className={cn(styles['container-absolute'], {
          [styles['container-hide']]: isHidden
        })}
      >
        <ul className={styles.table}>
          {/* <SizeItem /> */}

          {/* <PositionItem /> */}

          {mnemoSchemeItem?.staticLabelElement ? <BorderItem /> : null}

          {mnemoSchemeItem?.staticLabelElement ? <FillItem /> : null}

          <FontItem />
        </ul>
      </div>
    </div>
  ) : null
}

export default IndicatorSettings
