import type { IModalSettings } from '@/app/settings/components/server-settings/server-settings.type'
import { EModeModal } from '@/app/settings/components/server-settings/server-settings.type'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import { ButtonTitle } from '@/constants/core/common.constant'
import type { IServerForm } from '@/types/settings/server/server-form.interface'
import type { IServer } from '@/types/settings/server/server.interface'
import { validateUniqueUrl } from '@/utils/validations/validate-unique'
import { Form, Input, Modal } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import type { FC } from 'react'
import React, { useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'

type TProps = {
  modalOpened: IModalSettings
  onClose: () => void
  servers: IServer[]
  onUpdateServers: (server: IServer) => void
  selectedServer: IServer | null
}

const ModalServer: FC<TProps> = ({ modalOpened, onClose, onUpdateServers, servers, selectedServer }) => {
  const [form] = useForm<IServerForm>()
  const isAddMode = modalOpened.mode === EModeModal.ADD
  const isEditMode = modalOpened.mode === EModeModal.EDIT
  const isDeleteMode = modalOpened.mode === EModeModal.DELETE
  // Фильтрация для отключения валидации для редактируемого элемента
  const filteredServer = isEditMode ? servers.filter((server) => server.id !== selectedServer?.id) : servers
  const existingUrls = filteredServer.map((item) => item.url)
  const existingNames = filteredServer.map((item) => item.name)

  useEffect(() => {
    if (isEditMode && selectedServer) {
      form.setFieldsValue(selectedServer)
    }

    return () => {
      form.resetFields()
    }
  }, [form, isEditMode, selectedServer])

  const handleAddServerFinish = async () => {
    if (isDeleteMode && selectedServer) {
      onUpdateServers(selectedServer)
      return
    }

    const fields = form.getFieldsValue()

    try {
      await form.validateFields()
      const server = { ...fields, id: isAddMode ? uuidv4() : selectedServer?.id }
      onUpdateServers(server)
      form.resetFields()
    } catch (e) {
      console.error(e)
    }
  }

  const titleSubmitBtn =
    (isAddMode && 'Добавить сервер') ||
    (isEditMode && 'Редактировать сервер') ||
    (isDeleteMode && 'Удалить сервер') ||
    ''

  return (
    <Modal width={800} open={modalOpened.opened} centered={true} maskClosable={false} footer={null} onCancel={onClose}>
      <Form form={form} onFinish={handleAddServerFinish} validateTrigger='onFinish'>
        <PanelTitle>
          {(isAddMode && 'Добавление сервера') ||
            (isEditMode && 'Редактирование сервера') ||
            (isDeleteMode && 'Вы действительно хотите удалить сервер?')}
        </PanelTitle>
        {!isDeleteMode && (
          <>
            <FormItemApp
              name='url'
              label='Адрес сервера'
              rules={[
                { required: true, message: 'Поле обязательно' },
                { type: 'url', message: 'Введите валидный адрес: http://192.168.50.77:4000 или https://cloud.mcm.ru' },
                { validator: (_, url) => validateUniqueUrl(url, existingUrls), message: 'Такой адрес уже используется' }
              ]}
            >
              <Input placeholder='https://192.168.X.X' />
            </FormItemApp>
            <FormItemApp
              name='name'
              label='Имя сервера'
              rules={[
                { required: true, message: 'Поле обязательно' },
                {
                  validator: (_, name) => validateUniqueUrl(name, existingNames),
                  message: 'Такое имя уже используется'
                }
              ]}
            >
              <Input placeholder='Введите имя сервера' />
            </FormItemApp>
          </>
        )}
        <ButtonsWrapper>
          <ButtonPrimary htmlType={'button'} title={ButtonTitle.Exit} onClick={onClose} />
          <ButtonPrimary htmlType={'submit'} title={titleSubmitBtn} />
        </ButtonsWrapper>
      </Form>
    </Modal>
  )
}

export default ModalServer
