import { additionalInformationKb } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import type { TableColumnsType } from 'antd'
import { Table } from 'antd'
import type { TableRef } from 'antd/es/table'
import cn from 'classnames'
import type { FC } from 'react'
import { useEffect, useRef } from 'react'

import styles from './table-kinematics.module.css'

interface ITableKinematicItem {
  key: string
  number: number
  name: string
  description: string
  kinematicElement: IKinematicElement
}

type TProps = {
  kinematicElements?: IKinematicElement[]
}

const columns: TableColumnsType<ITableKinematicItem> = [
  {
    title: 'Номер',
    dataIndex: 'number'
  },
  {
    title: 'Наименование',
    dataIndex: 'name'
  },
  {
    title: 'Описание',
    dataIndex: 'description'
  }
]

const TableKinematics: FC<TProps> = ({ kinematicElements }) => {
  const tableRef = useRef<TableRef>(null)
  const { selectedKinematic } = useTypedSelector((state) => state.kinematicUiReducer)

  useEffect(() => {
    const nativeTable = tableRef?.current?.nativeElement
    if (nativeTable) {
      const selectedRow = nativeTable.querySelector<HTMLTableRowElement>(
        `[data-row-key="${selectedKinematic?.kinematicBlockId}"]`
      )

      if (selectedRow) {
        selectedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        })
      }
    }
  }, [selectedKinematic])

  const data = kinematicElements?.map((item, index) => ({
    key: item.kinematicBlockId,
    number: index + 1,
    name: item.kinematicBlockName,
    description: additionalInformationKb[item.kinematicBlockType]?.label,
    kinematicElement: item
  }))

  const { setSelectedKinematic } = useActions()

  const handleSelectionMouseEnter = (tableItem: ITableKinematicItem) => {
    setSelectedKinematic(tableItem.kinematicElement)
  }

  const handleSelectionMouseLeave = () => {
    setSelectedKinematic(null)
  }

  const handleRow = (tableItem: ITableKinematicItem) => ({
    onMouseEnter: () => handleSelectionMouseEnter(tableItem),
    onMouseLeave: () => handleSelectionMouseLeave(),
    className: cn({
      [styles['selection']]: tableItem.kinematicElement.kinematicBlockId === selectedKinematic?.kinematicBlockId
    })
  })

  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <h2 className={styles['title']}>Информация о кинематических блоках</h2>
      </div>
      <div className={styles['table-container']}>
        <Table
          className={styles['table']}
          showHeader={false}
          dataSource={data}
          columns={columns}
          pagination={false}
          bordered={false}
          onRow={handleRow}
          ref={tableRef}
          rowKey={(record) => record.kinematicElement.kinematicBlockId}
        />
      </div>
    </div>
  )
}

export default TableKinematics
