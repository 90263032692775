import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/wrappers/controls-wrapper/controls-wrapper'
import { ButtonTitle } from '@/constants/core/common.constant'
import type { FormInstance, TableProps } from 'antd'
import { Form } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'

import styles from './table-editable.module.css'

type TProps = {
  form: FormInstance
  onFinish: () => void
  onReset: () => void
  isEdit: boolean
  className?: string
} & TableProps<NonNullable<unknown>>

const TableEditable: FC<TProps> = ({ form, onFinish, onReset, isEdit, className, children }) => (
  <Form form={form} onFinish={onFinish} className={cn(styles.container, className)}>
    {children}
    <div>
      {isEdit ? (
        <ControlsWrapper>
          <ButtonsWrapper>
            <ButtonPrimary title={ButtonTitle.Cancel} htmlType={'reset'} onClick={onReset} />
            <ButtonPrimary title={ButtonTitle.Save} htmlType={'submit'} />
          </ButtonsWrapper>
        </ControlsWrapper>
      ) : null}
    </div>
  </Form>
)

export default TableEditable
