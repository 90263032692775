import { SliceName } from '@/constants/store/slices.constant'
import type { IEquipmentsUiState } from '@/store/reducers/equipments/equipments-ui.interface'
import type { IEquipmentView } from '@/types/equipments/equipment-view.interface'
import type { IEquipmentTreeNode } from '@/types/tree/equipment-tree-node.interface'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { FC, Key } from 'react'

const initialState: IEquipmentsUiState = {
  equipmentView: {
    content: null,
    mode: null
  },
  treeMenu: [],
  selectedTreeItems: [],
  draggableMachineId: null
}

export const equipmentsUiSlice = createSlice({
  name: SliceName.EquipmentsUi,
  initialState,
  reducers: {
    setDraggableMachineId(state, action: PayloadAction<string | null>) {
      state.draggableMachineId = action.payload
    },
    setEquipmentView(state, action: PayloadAction<IEquipmentView>) {
      state.equipmentView = action.payload
    },
    setTreeMenu(state, action: PayloadAction<IEquipmentTreeNode[]>) {
      state.treeMenu = action.payload
    },
    setSelectedTreeItems(state, action: PayloadAction<Key[]>) {
      state.selectedTreeItems = action.payload
    },
    updateTreeMenuTitle(state, action: PayloadAction<{ id: string; newTitle: string }>) {
      const { id, newTitle } = action.payload

      const updateNode = (nodes: IEquipmentTreeNode[]): IEquipmentTreeNode[] =>
        nodes.map((node) => {
          if (node.key === id) {
            node.title = newTitle
            return node
          } else if (node.children) {
            return {
              ...node,
              children: updateNode(node.children)
            }
          }
          return node
        })

      state.treeMenu = updateNode(state.treeMenu)
    },
    updateTreeMenuItem: function (
      state,
      action: PayloadAction<{
        id: string
        name: string
        parentKey: string
        group: string
        icon: FC
      }>
    ) {
      const { name, id, parentKey, group, icon } = action.payload

      const updateNode = (nodes: IEquipmentTreeNode[]): IEquipmentTreeNode[] =>
        nodes.map((node) => {
          if (node.key === parentKey) {
            const updatedNode = { ...node }
            if (!updatedNode.children) {
              updatedNode.children = []
            }

            updatedNode.children.push({
              key: id,
              title: name,
              group: group,
              icon
            } as IEquipmentTreeNode)

            // Сортируем детей по приоритету групп перед машинами
            updatedNode.children = updatedNode.children.sort((a, b) => {
              const priority: { [key in string]: number } = {
                GROUPS: 1,
                MACHINES: 2,
                POINTS: 3,
                MEASUREMENTS: 4
              }

              return (priority[a.group] || 0) - (priority[b.group] || 0)
            })

            return updatedNode
          }

          if (node.children) {
            return {
              ...node,
              children: updateNode(node.children)
            }
          }

          return node
        })

      state.treeMenu = updateNode(state.treeMenu)
    }
  }
})
