export const enum EPhysicalQuantityType {
  VIBRO_ACCELERATION = 'VIBRO_ACCELERATION',
  VIBRO_VELOCITY = 'VIBRO_VELOCITY',
  VIBRO_DISPLACEMENT = 'VIBRO_DISPLACEMENT',
  VOLTAGE = 'VOLTAGE',
  AMPERAGE = 'AMPERAGE',
  PHASE = 'PHASE',
  RPM = 'RPM',
  DRPM = 'DRPM',
  TEMPERATURE = 'TEMPERATURE'
}
