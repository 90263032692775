import { mapTypeBr } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/kb-source/kb-source.constant'
import {
  KbParam,
  TypeSource
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IOptionOutSource } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import withSkeleton from '@/hocs/with-skeleton'
import { useGetKinematicElementsQuery, useLazyGetCurrentKinematicElementQuery } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import type { FormInstance } from 'antd'
import { Form, Select, type SelectProps } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TKbSource = {
  typeSource?: TypeSource
  kinematicSchemeId?: IKinematicElement['kinematicSchemeId']
  form: FormInstance
  onSelectOutFreq: (value: number, options: IOptionOutSource) => void
  currentKinematicBlockId?: string
  kbFieldName?: string
  freqOutFieldName?: string
}

const SelectWithSkeleton = withSkeleton<SelectProps>(Select)
const SelectFreqOutWithSkeleton = withSkeleton<SelectProps<number, IOptionOutSource>>(Select)

export const KbSource: FC<TKbSource> = ({
  typeSource,
  kinematicSchemeId,
  form,
  onSelectOutFreq,
  currentKinematicBlockId,
  kbFieldName = KbParam.KinematicBlockSourceId,
  freqOutFieldName = KbParam.FreqOutSource
}) => {
  const { data: kinematicElements, isLoading: isLoadingKinematicElements } = useGetKinematicElementsQuery(
    kinematicSchemeId,
    {
      skip: !kinematicSchemeId
    }
  )
  const [getCurrentKinematicElement, { isFetching: isFetchingKinematicElement }] =
    useLazyGetCurrentKinematicElementQuery()
  const [optionsOutsSource, setOptionsOutsSource] = useState<IOptionOutSource[] | null>(null)

  const watchedKinematicBlockSourceId = useWatch(kbFieldName, form)

  useEffect(() => {
    const handleUpdateFreqOutSource = async () => {
      setOptionsOutsSource(null)
      if (watchedKinematicBlockSourceId && kinematicSchemeId) {
        try {
          const kinematicElement = await getCurrentKinematicElement({
            kinematicSchemeId,
            kinematicElementId: watchedKinematicBlockSourceId
          }).unwrap()

          if (kinematicElement.outs) {
            const filteredOuts = kinematicElement.outs.filter(
              (item) => item.freqOutValue !== 0 && item.freqOutCoefKb !== 0 && item.freqOutCoefMachine !== 0
            )

            const outsSource = filteredOuts.map((out, index) => ({
              label: out.freqOutName as string,
              value: out.freqOutValue as number,
              key: index,
              freqOutCoefIndexSourse: index,
              freqOutNameSourse: out.freqOutName,
              freqOutCoefMachineSourse: out.freqOutCoefMachine
            }))

            setOptionsOutsSource(outsSource)
          }
        } catch (e) {
          console.error(e)
        }
      }
    }
    handleUpdateFreqOutSource()
  }, [getCurrentKinematicElement, kinematicSchemeId, watchedKinematicBlockSourceId])

  const optionsKb = kinematicElements?.content
    .filter((element) => element.kinematicBlockId !== currentKinematicBlockId)
    .map((element) => {
      let label: string
      if (element.kinematicBlockType) {
        label = `${element.kinematicBlockName} | ${mapTypeBr[element.kinematicBlockType]}`
      } else {
        label = element.kinematicBlockName
      }

      return {
        name: element.kinematicBlockName,
        label: label,
        value: element.kinematicBlockId
      }
    })

  if (typeSource !== TypeSource.Kb) {
    return null
  }

  const handleSelect = (value: string) => {
    if (value !== watchedKinematicBlockSourceId) {
      form.resetFields([freqOutFieldName])
    }
  }

  return (
    <>
      <Form.Item
        label='Кинематический блок'
        name={kbFieldName}
        className={styles['drawer-kinematic-input-item']}
        rules={[
          {
            required: true,
            message: 'Необходимо выбрать кинематический блок'
          }
        ]}
      >
        <SelectWithSkeleton
          placeholder='Выберите кинематический блок'
          options={optionsKb}
          isSkeletonActive={isLoadingKinematicElements}
          onSelect={handleSelect}
          optionLabelProp='name'
        />
      </Form.Item>
      <Form.Item
        label='Выходная частота'
        name={freqOutFieldName}
        className={styles['drawer-kinematic-input-item']}
        rules={[
          {
            required: true,
            message: 'Необходимо выбрать выходную частоту'
          }
        ]}
      >
        <SelectFreqOutWithSkeleton
          isSkeletonActive={isFetchingKinematicElement}
          placeholder='Выберите выходную частоту'
          options={optionsOutsSource ?? undefined}
          loading={isFetchingKinematicElement}
          onSelect={onSelectOutFreq}
        />
      </Form.Item>
    </>
  )
}
