import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { FC, LegacyRef, MutableRefObject } from 'react'
import { useState } from 'react'

import styles from '../../express-sidebar.module.css'

import ExpressSectionListItem from './components/express-section-list-item/express-section-list-item'
import PopupExpressSection from './components/popup-express-section/popup-express-section'

interface IProps {
  itemSectionsRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
  onClickExpressSectionButton: (index: number) => void
}

const ExpressSection: FC<IProps> = ({ itemSectionsRefs, onClickExpressSectionButton }) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { results, sections } = useTypedSelector((state) => state.expressSectionReducer)

  return (
    <div className={styles.container}>
      <ButtonPrimary htmlType={'button'} title={'Сечения'} onClick={() => setIsPopupOpen(true)} />

      <div className={styles['container-express']}>
        <PopupExpressSection isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} />

        <ul className={styles.list}>
          {sections.length
            ? results.map((result, i) => (
                <ExpressSectionListItem
                  key={result.title}
                  result={result}
                  onClickExpressSectionButton={() => onClickExpressSectionButton(i)}
                  refSectionListItem={itemSectionsRefs?.current && itemSectionsRefs?.current[i]}
                  currentIndex={i}
                />
              ))
            : null}
        </ul>
      </div>
    </div>
  )
}

export default ExpressSection
