import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import ToolBar from '@/components/controlers/layouts/tool-bar/tool-bar'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useExecutePlanMutation } from '@/store/api/plans.api'
import type { TPlan } from '@/types/plan/plan.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { FC } from 'react'
import { useState } from 'react'

import PopupPlans from '../popup-plans/popup-plans'

const PlansToolbar: FC = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const { selectedPlanId } = useTypedSelector((state) => state.planReducer)

  const { addPlan, deletePlan } = useActions()

  const [executePlan] = useExecutePlanMutation()

  const handleAddButton = async () => {
    setIsPopupOpen(true)
  }

  const handleClickDeleteButton = () => {
    deletePlan()
  }

  const handleStartPlan = async () => {
    if (!selectedPlanId) return

    try {
      await executePlan(selectedPlanId).unwrap()

      successNotificationCreate('Маршрут отправлен')
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  const createPlan = (plan: TPlan) => {
    addPlan(plan)
  }

  return (
    <ToolBar>
      <PopupPlans isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} onSubmitHandler={createPlan} />

      <ButtonToolbar onClick={handleAddButton} icon={'add'} title={'Добавить маршрут'} />
      {selectedPlanId ? (
        <ButtonToolbar onClick={handleStartPlan} icon={'start'} title={'Отправить маршрут в работу'} />
      ) : null}
      <ButtonToolbar onClick={handleClickDeleteButton} icon={'delete'} title={'Удалить маршрут'} />
    </ToolBar>
  )
}

export default PlansToolbar
