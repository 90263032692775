import generatePlansTreeData from '@/app/plans/components/plans-main-content/components/plans-list/plan-list.helper'
import type { TDataNode } from '@/app/plans/components/plans-main-content/plans-main-content'
import { ENodeType } from '@/app/plans/components/plans-main-content/plans-main-content'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useLazyGetMachineQuery } from '@/store/api/machines.api'
import { useLazyGetMeasurementsQuery } from '@/store/api/measurements.api'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import { useChangePlanMutation } from '@/store/api/plans.api'
import { useLazyGetMeasuringPointQuery, useLazyGetMeasuringPointsQuery } from '@/store/api/points.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Tree } from 'antd'
import type { CheckInfo } from 'rc-tree/lib/Tree'
import type { FC, Key } from 'react'
import React, { useCallback } from 'react'

import styles from './plans-list.module.css'

type TExcludedIds = {
  excludedMachineIds: string[]
  excludedMeasurementIds: string[]
  excludedMeasuringPointIds: string[]
}

const PlansList: FC = () => {
  const { excludeEquipment, addPoints, addMeasurements } = useActions()
  const { plans, selectedPlanId } = useTypedSelector((state) => state.planReducer)
  const { data: dataAllNodesState } = useGetAllNodesQuery(null)

  const plan = plans.find((item) => item.planId === selectedPlanId)

  const [changePlan] = useChangePlanMutation()

  const [getCurrentMachine] = useLazyGetMachineQuery()
  const [getCurrentPoint] = useLazyGetMeasuringPointQuery()
  const [getPoints] = useLazyGetMeasuringPointsQuery()
  const [getMeasurements] = useLazyGetMeasurementsQuery()

  const loadItemsInSelectedPlan = useCallback(
    async (node: TDataNode) => {
      if (plan !== undefined) {
        try {
          if (node.nodeType === ENodeType.MACHINE) {
            const machineId = node.machineId as string
            const { content: points } = await getPoints(machineId).unwrap()

            if (!points) throw new Error('error selectMachineInPlan')

            addPoints({
              machineId: machineId,
              points: points
            })
          } else if (node.nodeType === ENodeType.POINT) {
            const pointId = node.pointId as string
            const machineId = node.machineId
            const { content: measurements } = await getMeasurements(pointId).unwrap()
            const machine = await getCurrentMachine(machineId).unwrap()
            const point = await getCurrentPoint(pointId).unwrap()

            if (!measurements) throw new Error('error togglePointInPlan')

            addMeasurements({
              selectedPlan: plan,
              machine,
              point,
              measurements: measurements
            })
          }
        } catch (error) {
          errorNotificationCreate(error)
        }
      }
    },
    [addMeasurements, addPoints, getCurrentMachine, getCurrentPoint, getMeasurements, getPoints, plan]
  )

  const onCheck = async (_: { checked: Key[]; halfChecked: Key[] } | Key[], checkedInfo: CheckInfo<TDataNode>) => {
    try {
      // собирает все ключи исключенных id машин точек или видов измерения
      const excludedIds: TExcludedIds = checkedInfo.checkedNodes.reduce<TExcludedIds>(
        (acc, curr) => {
          if (curr.nodeType === ENodeType.MACHINE) {
            acc.excludedMachineIds.push(curr.machineId)
          } else if (curr.nodeType === ENodeType.POINT && curr.pointId) {
            acc.excludedMeasuringPointIds.push(curr.pointId)
          } else if (curr.nodeType === ENodeType.MEASUREMENT && curr.measurementId) {
            acc.excludedMeasurementIds.push(curr.measurementId)
          }

          return acc
        },
        {
          excludedMachineIds: [],
          excludedMeasurementIds: [],
          excludedMeasuringPointIds: []
        }
      )

      const updatePlan = { ...plan, ...excludedIds }

      await changePlan({
        planId: updatePlan.planId,
        name: updatePlan.name,
        planType: updatePlan.planType,
        deviceType: updatePlan.deviceType,
        machineIds: updatePlan.machineIds,
        excludedMachineIds: updatePlan.excludedMachineIds,
        excludedMeasurementIds: updatePlan.excludedMeasurementIds,
        excludedMeasuringPointIds: updatePlan.excludedMeasuringPointIds
      }).unwrap()

      successNotificationCreate('Маршрут изменен')

      excludeEquipment({
        plan: updatePlan
      })
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  return (
    <Tree
      checkable
      showLine={true}
      showIcon
      className={styles['plans-list']}
      loadData={loadItemsInSelectedPlan}
      treeData={generatePlansTreeData({ selectedPlan: plan, dataAllNodesState })}
      onCheck={onCheck}
    />
  )
}

export default PlansList
