import type { FC, PropsWithChildren } from 'react'
import React from 'react'

import styles from '@/components/widgets/mnemonic/indicator-settings/indicator-settings.module.css'

type TProps = {
  title: string
}

const TableCell: FC<PropsWithChildren<TProps>> = ({ title, children }) => (
  <li className={styles.cell}>
    <p className={styles.parameter}>{title}</p>
    <p className={styles.key}>{children}</p>
  </li>
)

export default TableCell
