import { ControlPanel } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import { DcgParamName } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcg-form/dcg-form.constant'
import { OutTable } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/components/out-table/out-table'
import { SynchMechParamName } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/sync-form.constant'
import {
  formatDataForTable,
  getCalculatedResults,
  parseDataForChange,
  parseServerData
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/sync-mech-form.util'
import type {
  IFormSynchMech,
  ISynchMechOut,
  TTableSyncMechOuts
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/synch-mech-form.type'
import type { TypeSource } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import {
  KbParam,
  optionsTypeSourceWithKb
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { IInitialData } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { validateMinValue } from '@/utils/validation-rules'
import { Form, InputNumber, Select } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type ShFormProps = {
  onClose: () => void
  kinematicElement: IKinematicElement | null
  image: string
}

const SynchMechForm: FC<ShFormProps> = ({ onClose, kinematicElement, image }) => {
  const [changeKinematicElement, { isLoading: isUpdating }] = useChangeKinematicElementMutation()

  const [synchMechForm] = useForm<IFormSynchMech>()
  const [initialData, setInitialData] = useState<IInitialData<IFormSynchMech, ISynchMechOut> | null>(null)
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const [disabledCalculateButton, setDisabledCalculateButton] = useState(false)
  const [tableDataOuts, setTableDataOuts] = useState<TTableSyncMechOuts[] | undefined>(undefined)
  const [outs, setOuts] = useState<ISynchMechOut | null>(null)
  const fieldsObservable = useWatch([], synchMechForm)
  const isInitialData = isEqual(initialData?.fields, synchMechForm.getFieldsValue()) && isEqual(initialData?.outs, outs)

  const resetForm = useCallback(() => {
    synchMechForm.resetFields()
    setOuts(null)
    setTableDataOuts(undefined)
  }, [synchMechForm])

  useEffect(() => {
    if (outs) {
      const result = formatDataForTable(outs)
      setTableDataOuts(result)
    }
  }, [outs])

  useEffect(() => {
    const { setFieldsValue } = synchMechForm
    resetForm()

    if (kinematicElement) {
      const result = parseServerData(kinematicElement)
      setFieldsValue(result.fields)
      setOuts(result.outs)
      setInitialData(result)
    }
  }, [synchMechForm, kinematicElement, resetForm])

  useEffect(() => {
    setDisabledFinishButton(true)
  }, [fieldsObservable])

  useEffect(() => {
    const { validateFields } = synchMechForm

    const checkValidateFields = async (): Promise<void> => {
      try {
        await validateFields({
          validateOnly: true,
          recursive: true
        })

        if (!isInitialData) {
          setDisabledCalculateButton(false)
        }
      } catch (e) {
        setDisabledCalculateButton(true)
      }
    }

    if (!disabledCancelButton) {
      checkValidateFields()
    }
  }, [synchMechForm, initialData, fieldsObservable, disabledCancelButton, isInitialData])

  useEffect(() => {
    setDisabledCancelButton(isInitialData)
    setDisabledCalculateButton(isInitialData)
  }, [isInitialData])

  const handleCalculation = async () => {
    const { getFieldsValue, validateFields } = synchMechForm
    const fieldsValue = getFieldsValue()

    try {
      if (kinematicElement?.kinematicBlockName) {
        await validateFields()
        const result = getCalculatedResults(
          fieldsValue,
          kinematicElement?.kinematicBlockName,
          kinematicElement.kinematicBlockType
        )
        setOuts(result)
        successNotificationCreate('Коэффициенты успешно расчитаны')
      }

      setDisabledFinishButton(false)
      setDisabledCalculateButton(true)
    } catch (e) {
      setDisabledFinishButton(true)
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
    }
  }

  const handleFinish = async () => {
    const { getFieldsValue, validateFields } = synchMechForm
    const fieldValues = getFieldsValue()

    try {
      try {
        await validateFields()
      } catch (e) {
        errorNotificationCreate(e, {
          message: 'Форма содержит ошибки'
        })
        return
      }

      if (kinematicElement && outs) {
        const requestDataParsed = parseDataForChange(kinematicElement, fieldValues, outs)

        await changeKinematicElement(requestDataParsed).unwrap()
        successNotificationCreate(`Кинематический блок ${kinematicElement.kinematicBlockName} сохранён`)

        onClose()
        resetForm()
      }
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = synchMechForm
      resetFields()
      setFieldsValue(initialData.fields)
      setOuts(initialData.outs)
    }
  }

  const handleChangeSelect = (value: TypeSource): void => {
    synchMechForm.setFieldValue(KbParam.TypeSource, value)
  }

  return (
    <>
      <Form className={styles['drawer-kinematic-form']} onFinish={handleFinish} form={synchMechForm}>
        <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
        <div className={styles['drawer-kinematic-header']}>
          <Form.Item name={KbParam.Description} className={styles['drawer-kinematic-text-area']}>
            <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} />
          </Form.Item>
          <div className={styles['drawer-kinematic-image-wrapper']}>
            <img className={styles['drawer-kinematic-image']} src={image} alt='' />
          </div>
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Вход</h2>
        <Form.Item
          label='Тип источника'
          name={DcgParamName.TypeSource}
          className={styles['drawer-kinematic-input-item']}
          tooltip='Не реализовано!'
        >
          <Select
            placeholder='Выберите тип источника'
            options={optionsTypeSourceWithKb}
            disabled={true}
            onChange={handleChangeSelect}
          />
        </Form.Item>
        <Form.Item
          tooltip='Не реализовано!'
          label='Коэффициент частоты КБ'
          name={KbParam.FreqCoefForIn}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Введите коэффициент входной частоты'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            className={styles['drawer-kinematic-input-number']}
            placeholder='Введите коэффициент входной частоты'
            min={0}
            disabled={true}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Параметры</h2>
        <Form.Item
          name={SynchMechParamName.Fu}
          label='Частота питающей сети, Гц'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать частоу питающей сети'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите частоту питающей сети'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={SynchMechParamName.N2p}
          label='Количество пар полюсов'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество пар полюсов'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите частоту питающей сети'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={SynchMechParamName.Nr}
          label='Количество пазов ротора'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество пазов ротора'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество пазов ротора'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={SynchMechParamName.Ns}
          label='Количество пазов статора'
          className={styles['drawer-kinematic-input-item']}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество пазов стартора'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Выход</h2>
        <OutTable tableData={tableDataOuts} />
      </Form>
      <ControlPanel
        onClickCancel={handleCancel}
        onClickCalculation={handleCalculation}
        onClickSave={handleFinish}
        disabledCalculation={disabledCalculateButton}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdating}
      />
    </>
  )
}

export default SynchMechForm
