import { ThresholdName, initializedRiftZoneData } from '@/constants/threshold/threshold.constant'
import { ERiftCalcType } from '@/enums/threshold/rift-calc-type.enum'
import type { IThreshold, IThresholdForm } from '@/types/threshold/threshold.type'

export const mapFromRequestThreshold = (threshold: IThreshold): IThresholdForm => ({
  [ThresholdName.ControlEnabled]: threshold.controlEnabled,
  [ThresholdName.RiftCalcEnabled]: threshold.riftCalcEnabled,
  [ThresholdName.RiftCalcType]: threshold.riftCalcType || ERiftCalcType.INCREMENT,
  [ThresholdName.BaseRiftValue]: threshold.baseRiftValue,
  [ThresholdName.BaseRiftValueAutoCorrectEnabled]: threshold.baseRiftValueAutoCorrectEnabled,
  [ThresholdName.SkipLimit]: threshold.skipLimit,
  [ThresholdName.Rifts]: threshold.rifts,
  [ThresholdName.Zones]: threshold.zones
})

export const mapFromInitializationThreshold = (): IThresholdForm => ({
  [ThresholdName.ControlEnabled]: true,
  [ThresholdName.RiftCalcEnabled]: false,
  [ThresholdName.RiftCalcType]: ERiftCalcType.INCREMENT,
  [ThresholdName.BaseRiftValue]: 0,
  [ThresholdName.BaseRiftValueAutoCorrectEnabled]: false,
  [ThresholdName.SkipLimit]: 0,
  [ThresholdName.Rifts]: initializedRiftZoneData[ThresholdName.Rifts],
  [ThresholdName.Zones]: initializedRiftZoneData[ThresholdName.Zones]
})
