import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import type { IEquipmentTreeNode } from '@/types/tree/equipment-tree-node.interface'
import type { DataNode } from 'antd/es/tree'
import type { TreeProps } from 'antd/lib'

const useTreeDraggableHook = (equipmentTreeMode: EEquipmentTreeMode) => {
  const { setDraggableMachineId, setSelectedMachineId } = useActions()
  const allowDraggableNode = (node: DataNode) => {
    const extendedNode = node as IEquipmentTreeNode
    return equipmentTreeMode === EEquipmentTreeMode.PLAN && extendedNode.group === EEquipmentTree.MACHINES
  }

  const onDragStart: TreeProps['onDragStart'] = (info) => {
    const key = info?.node?.key as string
    if (key) {
      setDraggableMachineId(key)
    }
  }

  const onDragEnd = () => {
    setDraggableMachineId(null)
  }

  return { allowDraggableNode, onDragStart, onDragEnd }
}

export default useTreeDraggableHook
