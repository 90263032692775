import { useGetMnemonicElement } from '@/hooks/api/use-get-mnemonic-element'
import { useChangeMnemonicElementMutation } from '@/store/api/mnemo.api'
import type { TMnemoSchemeItem } from '@/types/mnemonic.type'
import { errorNotificationCreate } from '@/utils/notification-creators'
import type { MenuProps } from 'antd'
import { Button, Dropdown } from 'antd'
import type { FC } from 'react'

import styles from '@/components/widgets/mnemonic/indicator-settings/indicator-settings.module.css'

import TableCell from './table-cell'

const fontSizes = [6, 8, 10, 12, 14, 16, 18, 20, 22]

const FontItem: FC = () => {
  const { data: selectedMnemoItem } = useGetMnemonicElement()
  const [changeMnemonicElement] = useChangeMnemonicElementMutation()

  const fonts: MenuProps['items'] = [
    {
      label: 'Размер шрифта',
      key: 'fontSize',
      children: fontSizes.map((size) => ({
        label: (
          <span
            className={styles.fontStyle}
            style={{
              backgroundColor:
                selectedMnemoItem?.staticLabelElement?.fontStyle?.fontSize === size ||
                selectedMnemoItem?.measurementLastValueElement?.fontStyle?.fontSize === size
                  ? '#c1c1c1'
                  : ''
            }}
          >
            {size}
          </span>
        ),
        key: size
      })) /* [
        {
          label: (
            <span
              className={styles.fontStyle}
              style={{
                backgroundColor:
                  selectedMnemoItem?.staticLabelElement?.fontStyle?.fontSize ===
                    14 ||
                  selectedMnemoItem?.measurementLastValueElement?.fontStyle
                    ?.fontSize === 14
                    ? '#c1c1c1'
                    : '',
              }}
            >
              14
            </span>
          ),
          key: '14',
        },
        {
          label: (
            <span
              className={styles.fontStyle}
              style={{
                backgroundColor:
                  selectedMnemoItem?.staticLabelElement?.fontStyle?.fontSize ===
                    16 ||
                  selectedMnemoItem?.measurementLastValueElement?.fontStyle
                    ?.fontSize === 16
                    ? '#c1c1c1'
                    : '',
              }}
            >
              16
            </span>
          ),
          key: '16',
        },
        {
          label: (
            <span
              className={styles.fontStyle}
              style={{
                backgroundColor:
                  selectedMnemoItem?.staticLabelElement?.fontStyle?.fontSize ===
                    18 ||
                  selectedMnemoItem?.measurementLastValueElement?.fontStyle
                    ?.fontSize === 18
                    ? '#c1c1c1'
                    : '',
              }}
            >
              18
            </span>
          ),
          key: '18',
        },
        {
          label: (
            <span
              className={styles.fontStyle}
              style={{
                backgroundColor:
                  selectedMnemoItem?.staticLabelElement?.fontStyle?.fontSize ===
                    20 ||
                  selectedMnemoItem?.measurementLastValueElement?.fontStyle
                    ?.fontSize === 20
                    ? '#c1c1c1'
                    : '',
              }}
            >
              20
            </span>
          ),
          key: '20',
        },
        {
          label: (
            <span
              className={styles.fontStyle}
              style={{
                backgroundColor:
                  selectedMnemoItem?.staticLabelElement?.fontStyle?.fontSize ===
                    22 ||
                  selectedMnemoItem?.measurementLastValueElement?.fontStyle
                    ?.fontSize === 22
                    ? '#c1c1c1'
                    : '',
              }}
            >
              22
            </span>
          ),
          key: '22',
        },
      ], */
    },
    {
      label: 'Цвет шрифта',
      key: 'color',
      children: [
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: '#000' }} />,
          key: '#000'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: '#fff' }} />,
          key: '#fff'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'red' }} />,
          key: 'red'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'green' }} />,
          key: 'green'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'yellow' }} />,
          key: 'yellow'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'blue' }} />,
          key: 'blue'
        }
      ]
    },
    {
      label: 'Стиль шрифта',
      key: 'style',
      children: [
        {
          label: (
            <span
              className={styles.fontStyle}
              style={{
                backgroundColor:
                  selectedMnemoItem?.staticLabelElement?.fontStyle?.bold ||
                  selectedMnemoItem?.measurementLastValueElement?.fontStyle?.bold
                    ? '#c1c1c1'
                    : ''
              }}
            >
              Bold
            </span>
          ),
          key: 'bold'
        },
        {
          label: (
            <span
              className={styles.fontStyle}
              style={{
                backgroundColor:
                  selectedMnemoItem?.staticLabelElement?.fontStyle?.italic ||
                  selectedMnemoItem?.measurementLastValueElement?.fontStyle?.italic
                    ? '#c1c1c1'
                    : ''
              }}
            >
              Italic
            </span>
          ),
          key: 'italic'
        }
      ]
    }
  ]

  const updateMnemonicElement = async (mnemonicElement: TMnemoSchemeItem) => {
    if (mnemonicElement?.mnemoElementId) {
      try {
        const data = await changeMnemonicElement(mnemonicElement).unwrap()

        if (data !== null) throw new Error('Ошибка соединения')
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const onClickFont = ({ key, keyPath }: { key: string; keyPath: string[] }) => {
    if (selectedMnemoItem) {
      const parent = keyPath[keyPath.length - 1]
      let properties = {}
      let allProperties: TMnemoSchemeItem = {
        mnemoSchemeId: '',
        name: '',
        x: 0,
        y: 0
      }

      switch (parent) {
        case 'fontSize':
          properties = { fontSize: +key }
          break

        case 'color':
          properties = { color: key }
          break

        default:
          break
      }

      if (selectedMnemoItem?.staticLabelElement) {
        if (key === 'italic') {
          properties = {
            italic: !selectedMnemoItem?.staticLabelElement?.fontStyle?.italic
          }
        } else if (key === 'bold') {
          properties = {
            bold: !selectedMnemoItem?.staticLabelElement?.fontStyle?.bold
          }
        }
        allProperties = {
          ...selectedMnemoItem,
          staticLabelElement: {
            ...selectedMnemoItem?.staticLabelElement,
            fontStyle: {
              ...selectedMnemoItem?.staticLabelElement?.fontStyle,
              ...properties
            }
          }
        }
      } else if (selectedMnemoItem?.measurementLastValueElement) {
        if (key === 'italic') {
          properties = {
            italic: !selectedMnemoItem?.measurementLastValueElement?.fontStyle?.italic
          }
        } else if (key === 'bold') {
          properties = {
            bold: !selectedMnemoItem?.measurementLastValueElement?.fontStyle?.bold
          }
        }
        allProperties = {
          ...selectedMnemoItem,
          measurementLastValueElement: {
            ...selectedMnemoItem?.measurementLastValueElement,
            fontStyle: {
              ...selectedMnemoItem?.measurementLastValueElement?.fontStyle,
              ...properties
            }
          }
        }
      }

      updateMnemonicElement(allProperties)
    }
  }

  return (
    <TableCell title={'Шрифт'}>
      <Dropdown menu={{ items: fonts, onClick: onClickFont }} placement='bottomLeft' trigger={['click']}>
        <Button style={{ width: '100%', display: 'flex' }}>
          <span
            style={{
              display: 'block',
              fontWeight: selectedMnemoItem?.staticLabelElement?.fontStyle?.bold ? 'bold' : 'normal',
              color: selectedMnemoItem?.staticLabelElement?.fontStyle?.color,
              fontStyle: selectedMnemoItem?.staticLabelElement?.fontStyle?.italic ? 'italic' : 'normal'
            }}
          >
            Aa
          </span>
        </Button>
      </Dropdown>
    </TableCell>
  )
}

export default FontItem
