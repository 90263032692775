import { ControlPanel } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import SimpleForm from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/simple-kinematic/components/simple-form/simple-form'
import type { TFormSimpleBlock } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/simple-kinematic/simple-kinematic.type'
import { KbParam } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { useForm, useWatch } from 'antd/es/form/Form'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'

type TProps = {
  onClose: () => void
  kinematicElement: IKinematicElement | null
  image: string
}

const SimpleKinematic: FC<TProps> = ({ onClose, kinematicElement, image }) => {
  const [changeKinematicElement, { isLoading: isUpdating }] = useChangeKinematicElementMutation()
  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const [initialData, setInitialData] = useState<TFormSimpleBlock | null>(null)
  const [simpleForm] = useForm<TFormSimpleBlock>()
  const isInitialData = isEqual(initialData, simpleForm.getFieldsValue())
  const descriptionObservable = useWatch(KbParam.Description, simpleForm)

  const resetForm = useCallback(() => {
    simpleForm.resetFields()
  }, [simpleForm])

  useEffect(() => {
    const { setFieldsValue } = simpleForm
    resetForm()

    if (kinematicElement) {
      const result: TFormSimpleBlock = {
        [KbParam.Description]: kinematicElement.description ?? ''
      }
      setFieldsValue(result)
      setInitialData(result)
    }
  }, [kinematicElement, resetForm, simpleForm])

  useEffect(() => {
    if (isInitialData) {
      setDisabledFinishButton(true)
      return
    }
    setDisabledFinishButton(false)
  }, [simpleForm, descriptionObservable, isInitialData])

  useEffect(() => {
    setDisabledCancelButton(isInitialData)
  }, [isInitialData])

  const handleFinish = async () => {
    const fieldValues = simpleForm.getFieldsValue()

    try {
      if (kinematicElement) {
        await changeKinematicElement({
          ...kinematicElement,
          [KbParam.Description]: fieldValues.description
        }).unwrap()
        successNotificationCreate(`Кинематический блок ${kinematicElement.kinematicBlockName} сохранён`)

        onClose()
      }
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = simpleForm
      resetFields()
      setFieldsValue(initialData)
    }
  }

  return (
    <>
      <SimpleForm form={simpleForm} image={image} />
      <ControlPanel
        onClickCancel={handleCancel}
        onClickSave={handleFinish}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdating}
      />
    </>
  )
}

export default SimpleKinematic
