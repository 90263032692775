import StatisticAlIIndicator from '@/components/widgets/statistical-indicator/statistic-alI-indicator'
import type { FC } from 'react'
import React from 'react'

import styles from './statisticalIndicatorTool.module.css'

import type { IToolProps } from '../tool.interface'

const StatisticalIndicatorTool: FC<IToolProps> = ({ handleDoubleClick }) => (
  <button className={styles.container} type={'button'} onDoubleClick={handleDoubleClick}>
    <StatisticAlIIndicator />
  </button>
)

export default StatisticalIndicatorTool
