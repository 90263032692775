import { EThresholdLevel } from '@/enums/threshold/threshold-level.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { Select } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'

import styles from './zone-item-status.module.css'

const getLevelStyle = (level?: EThresholdLevel) => {
  switch (level) {
    case EThresholdLevel.NONE:
      return styles['select-content-none']

    case EThresholdLevel.LEVEL_0:
      return styles['select-content-level0']

    case EThresholdLevel.LEVEL_1:
      return styles['select-content-level1']

    case EThresholdLevel.LEVEL_2:
      return styles['select-content-level2']

    case EThresholdLevel.LEVEL_3:
      return styles['select-content-level3']

    case EThresholdLevel.LEVEL_4:
      return styles['select-content-level4']

    default:
      return styles['select-content-none']
  }
}

interface IProps {
  index: number
}

const ZoneItemStatus: FC<IProps> = ({ index }) => {
  const { setZones } = useActions()
  const { zones } = useTypedSelector((state) => state.thresholdReducer)

  const handleChangeSelect = (value: EThresholdLevel) => {
    const updatedZones = zones.map((zone, idx) => {
      if (idx === index) {
        return { ...zone, dangerousType: value }
      }
      return zone
    })
    setZones(updatedZones)
  }

  return (
    <Select
      dropdownStyle={{ padding: 0 }}
      onChange={handleChangeSelect}
      value={zones[index].dangerousType}
      className={cn(styles.select, getLevelStyle(zones[index].dangerousType))}
    >
      <Select.Option value={EThresholdLevel.NONE}>
        <span className={`${styles['select-content']} ${styles['select-content-none']}`}>Не задан</span>
      </Select.Option>
      <Select.Option value={EThresholdLevel.LEVEL_0}>
        <span className={`${styles['select-content']} ${styles['select-content-level0']}`}>Неопределённое</span>
      </Select.Option>
      <Select.Option value={EThresholdLevel.LEVEL_1}>
        <span className={`${styles['select-content']} ${styles['select-content-level1']}`}>Хорошо</span>
      </Select.Option>
      <Select.Option value={EThresholdLevel.LEVEL_2}>
        <span className={`${styles['select-content']} ${styles['select-content-level2']}`}>Допустимо</span>
      </Select.Option>
      <Select.Option value={EThresholdLevel.LEVEL_3}>
        <span className={`${styles['select-content']} ${styles['select-content-level3']}`}>Предупреждение</span>
      </Select.Option>
      <Select.Option value={EThresholdLevel.LEVEL_4}>
        <span className={`${styles['select-content']} ${styles['select-content-level4']}`}>Опасно</span>
      </Select.Option>
    </Select>
  )
}

export default ZoneItemStatus
