import ZoneItemStatus from '@/app/configuration/components/equipments/components/measurements/components/threshold/zone-item-status/zone-item-status'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IRift, IThresholdForm } from '@/types/threshold/threshold.type'
import type { FormInstance } from 'antd'
import { Radio } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'
import React from 'react'

import styles from './zone-item.module.css'

type ZoneItemProps = {
  rift: IRift
  index: number
  thresholdForm: FormInstance<IThresholdForm>
}

const ZoneItem: FC<ZoneItemProps> = ({ rift, index }) => {
  const { riftCalcEnabled } = useTypedSelector((state) => state.thresholdReducer)
  const { selectedRift } = useTypedSelector((state) => state.thresholdReducer)
  const isSelected = index === selectedRift

  return (
    <>
      <tr>
        <td data-select='true' colSpan={4}>
          <ZoneItemStatus index={index} />
        </td>
      </tr>
      {rift && (
        <tr key={index} className={cn({ [styles['select']]: isSelected })}>
          <td>
            <Radio value={index} className={styles['radio-button']} />
          </td>
          {riftCalcEnabled && <td>{rift.coef}</td>}
          <td>{rift.riftValue}</td>
          <td>{rift.hysteresisCoef}</td>
        </tr>
      )}
    </>
  )
}

export default ZoneItem
