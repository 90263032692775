import type { EDetectorType } from '@/enums/measurment/common-level/detector-type.enum'
import type { EVisualFreqLimit } from '@/enums/measurment/visual-freq-limit.enum'

export const mapVisualFreqLimit: { [key in EVisualFreqLimit]: string } = {
  RATE_625: '625',
  RATE_1250: '1 250',
  RATE_2500: '2 500',
  RATE_5000: '5 000',
  RATE_10000: '10 000'
} as const

export const visualFreqLimitOptions = Object.entries(mapVisualFreqLimit).map(([key, label]) => ({
  value: key as EDetectorType,
  label
}))
