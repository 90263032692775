import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { IMeasurement, TCreatedMeasurement } from '@/types/measurement/measurement.type'
import type { IThreshold } from '@/types/threshold/threshold.type'

import { baseApi } from './api-base-query'

export type TMeasurementResult = {
  resultId: string
  timestamp: string
  measurementName?: string
  scalarValue?: {
    value: number | null
  }
  powerSpecterValue?: {
    amplitudeSpectrum: number[]
  }
  signalValue?: {
    values: number[]
  }
}

interface IGetMeasurements {
  content: IMeasurement[]
}

interface IGetMeasurementResults {
  content: TMeasurementResult[]
}

export const measurementsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMeasurements: builder.query<IGetMeasurements, string | null>({
      query: (mpId) => ({
        url: `${ApiResource.Measurements}?measuringPointId=${mpId}`
      }),
      providesTags: [ApiTag.Measurements]
    }),

    getMeasurementResults: builder.query<
      IGetMeasurementResults,
      { measurementId: string | null; size?: number; sortDir?: 'ASC' | 'DESC' }
    >({
      query: ({ measurementId, size = 50, sortDir = 'ASC' }) => ({
        url: `${ApiResource.Measurements}/${measurementId}/results?size=${size}&sortDir=${sortDir}`
      }),
      providesTags: [ApiTag.MeasurementsResult]
    }),

    createMeasurement: builder.mutation<{ id: string }, TCreatedMeasurement>({
      query: (data) => ({
        url: ApiResource.Measurements,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Measurements]
    }),

    getMeasurement: builder.query<IMeasurement, string | null>({
      query: (id) => ({ url: `${ApiResource.Measurements}/${id}` }),
      providesTags: [ApiTag.Measurements]
    }),

    getThreshold: builder.query<IThreshold, string | null>({
      query: (mpId) => ({
        url: `${ApiResource.Measurements}/${mpId}/threshold`
      }),
      providesTags: [ApiTag.Threshold]
    }),

    updateThreshold: builder.mutation<
      { id: string },
      {
        thresholdData: IThreshold
        measurementId: string
      }
    >({
      query: (requestData) => ({
        url: `${ApiResource.Measurements}/${requestData.measurementId}/threshold`,
        method: 'PUT',
        body: requestData.thresholdData
      }),
      invalidatesTags: [ApiTag.Threshold]
    }),

    updateMeasurementsStatus: builder.mutation<
      null,
      {
        updateData: { paused: boolean }
        measurementId: string
      }
    >({
      query: ({ updateData, measurementId }) => ({
        url: `${ApiResource.Measurements}/${measurementId}/paused`,
        method: 'PUT',
        body: updateData
      }),
      invalidatesTags: [ApiTag.Measurements]
    })
  })
})

export const {
  useGetMeasurementsQuery,
  useGetMeasurementResultsQuery,
  useLazyGetMeasurementResultsQuery,
  useCreateMeasurementMutation,
  useGetMeasurementQuery,
  useGetThresholdQuery,
  useUpdateThresholdMutation,
  useLazyGetMeasurementsQuery,
  useUpdateMeasurementsStatusMutation
} = measurementsApi
