/**
 * Находит объект с ближайшим значением указанного поля в массиве.
 *
 * @param arr - Массив объектов.
 * @param field - Имя поля, по которому выполняется поиск.
 * @param targetValue - Целевое значение для поиска.
 * @returns Объект с ближайшим значением или null, если массив пуст.
 */
export const findClosestByField = <T>(arr: T[], field: keyof T, targetValue: number): T | null => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return null // Если массив пуст или не является массивом.
  }

  return arr.reduce((closest, current) => {
    const currentDiff = Math.abs(Number(current[field]) - targetValue)
    const closestDiff = Math.abs(Number(closest[field]) - targetValue)
    return currentDiff < closestDiff ? current : closest
  })
}
