import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import Control from '@/components/icons/control/control'
import MachineIcon from '@/components/icons/equipments/machine-icon'
import MeasurementIcon from '@/components/icons/equipments/measurement-icon'
import PointIcon from '@/components/icons/equipments/point-icon'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import { ETypeMeasurement } from '@/enums/measurment/type-measurement.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetMeasurementQuery } from '@/store/api/measurements.api'
import { getTypeMeasurement } from '@/utils/measurement/get-type-measurement'
import { FolderOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Button, Dropdown, Tooltip } from 'antd'
import type { FC } from 'react'
import React from 'react'

import styles from './equipment-tool.module.css'

interface IProps {
  measurementId: string | null
  measurementOpened: boolean
  toleranceControlOpened: boolean
}

const ALLOWED_TYPES_MEASUREMENT = [ETypeMeasurement.TemperatureDescription, ETypeMeasurement.CommonLevelDescription]

const EquipmentTools: FC<IProps> = ({ measurementId, measurementOpened, toleranceControlOpened }) => {
  const { setEquipmentView, setSelectedMeasurementId } = useActions()
  const { selectedPointId, selectedMachineId, selectedGroupId } = useTypedSelector((state) => state.globalReducer)
  const { equipmentView } = useTypedSelector((state) => state.equipmentsUiReducer)
  const { data: currentMeasurement } = useGetMeasurementQuery(measurementId, {
    skip: !measurementId
  })

  const isAllowedAddGroupOrMachine = equipmentView.content === EEquipmentContent.GROUP || equipmentView.content === null

  const checkAccessToleranceButton = () => {
    if ((currentMeasurement && measurementOpened) || toleranceControlOpened) {
      const currentType = getTypeMeasurement(currentMeasurement)
      return currentType ? ALLOWED_TYPES_MEASUREMENT.includes(currentType) : false
    }
  }

  const handleAddGroup = () => {
    setEquipmentView({
      content: EEquipmentContent.GROUP,
      mode: EViewMode.ADDITION
    })
  }

  const handleAddMachine = () => {
    setEquipmentView({
      content: EEquipmentContent.MACHINE,
      mode: EViewMode.ADDITION
    })
  }

  const handleAddPoint = () => {
    setEquipmentView({
      content: EEquipmentContent.POINT,
      mode: EViewMode.ADDITION
    })
  }

  const handleAddMeasurement = () => {
    setSelectedMeasurementId(null)
    setEquipmentView({
      content: EEquipmentContent.MEASUREMENT,
      mode: EViewMode.ADDITION
    })
  }

  const handleOpenToleranceControl = () => {
    if (toleranceControlOpened) {
      setEquipmentView({
        content: EEquipmentContent.MEASUREMENT,
        mode: EViewMode.EDITING
      })
      return
    }
    setEquipmentView({
      content: EEquipmentContent.TOLERANCE,
      mode: null
    })
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Button
          className={styles['button']}
          icon={<FolderOutlined />}
          type='text'
          onClick={handleAddGroup}
          disabled={!isAllowedAddGroupOrMachine}
        >
          Добавить группу
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button
          className={styles['button']}
          icon={<MachineIcon />}
          type='text'
          onClick={handleAddMachine}
          disabled={!isAllowedAddGroupOrMachine}
        >
          Добавить машину
        </Button>
      )
    },
    {
      key: '3',
      label: (
        <Button
          className={styles['button']}
          icon={<PointIcon />}
          type='text'
          onClick={handleAddPoint}
          disabled={!selectedMachineId}
        >
          Добавить точку измерения
        </Button>
      )
    },
    {
      key: '4',
      label: (
        <Button
          className={styles['button']}
          icon={<MeasurementIcon />}
          type='text'
          disabled={!selectedPointId}
          onClick={handleAddMeasurement}
        >
          Добавить вид измерения
        </Button>
      )
    }
  ]

  return (
    <>
      <Tooltip title={'Добавить объект'} placement='right'>
        <Dropdown className={styles['dropdown']} menu={{ items }} trigger={['click']}>
          <ButtonToolbar icon={'add'} placement={'bottomRight'} />
        </Dropdown>
      </Tooltip>
      <ButtonToolbar
        title='Настроить допусковый контроль'
        disabled={!checkAccessToleranceButton()}
        activated={toleranceControlOpened}
        onClick={handleOpenToleranceControl}
      >
        <Control />
      </ButtonToolbar>
      <ButtonToolbar onClick={() => console.log('delete')} title={'Удалить объект'} icon={'delete'} disabled={true} />
    </>
  )
}

export default EquipmentTools
