import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import FormInput from '@/components/controlers/form-controls/form-input/form-input'
import FormUi from '@/components/controlers/form-controls/form-ui/form-ui'
import Popup from '@/components/widgets/popup/popup'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import useGetMachine from '@/hooks/api/use-get-machine'
import { useCreateKinematicMutation } from '@/store/api/kinematic.api'
import type { IKinematicScheme } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Form } from 'antd'
import type { FC } from 'react'
import React, { useEffect } from 'react'

import styles from './popup-kinematic.module.css'

interface AddKinematicForm {
  nameKinematic: string
}

interface IProps {
  setIsPopupOpen: (value: React.SetStateAction<boolean>) => void
  isPopupOpen: boolean
  kinematicScheme?: IKinematicScheme
}

const PopupKinematic: FC<IProps> = ({ setIsPopupOpen, isPopupOpen }) => {
  const [form] = Form.useForm<AddKinematicForm>()
  const { data: machine } = useGetMachine()

  const [createKinematic] = useCreateKinematicMutation()

  useEffect(() => {
    if (machine) {
      form.setFieldValue('nameKinematic', `${machine.name}_KS`)
    }
  }, [form, machine])

  if (!machine) {
    return null
  }

  const handleReset = () => {
    setIsPopupOpen(false)
    form.resetFields()
  }

  const handleFinish = async () => {
    try {
      const { nameKinematic } = form.getFieldsValue()

      const kinematicSchemeData = {
        name: nameKinematic,
        nodeId: machine?.machineId,
        width: 800,
        height: 600,
        description: null
      }

      await createKinematic(kinematicSchemeData).unwrap()

      setIsPopupOpen(false)
      successNotificationCreate(`Кинематическая схема ${nameKinematic} создана`)
      form.resetFields()
    } catch (error) {
      errorNotificationCreate(error)
    }
  }

  return (
    <Popup isOpen={isPopupOpen} title={'Добавить кинематическую схему'}>
      <FormUi onReset={handleReset} onFinish={handleFinish} form={form} className={styles.input}>
        <FormInput
          labelContent={'Имя кинематической схемы'}
          name='nameKinematic'
          rules={[
            {
              required: true,
              message: 'Необходимо ввести наименование кинематической схемы'
            }
          ]}
        />

        <ButtonsWrapper>
          <ButtonPrimary title={'Отмена'} htmlType={'reset'} theme='dark' />
          <ButtonPrimary title={'Добавить'} htmlType={'submit'} theme='dark' />
        </ButtonsWrapper>
      </FormUi>
    </Popup>
  )
}

export default PopupKinematic
