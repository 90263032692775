import SkeletOnInput from '@/components/controlers/skeletons/skeleton-input/skelet-on-Input'
import type { ComponentType, FC } from 'react'
import React from 'react'

type THocProps = {
  isSkeletonActive: boolean
}

const withSkeleton = <T,>(
  Component: ComponentType<Omit<T & THocProps, 'isSkeletonActive'>>
) => {
  const WithSkeleton: FC<T & THocProps> = ({ isSkeletonActive, ...props }) =>
    isSkeletonActive ? (
      <SkeletOnInput active={isSkeletonActive} />
    ) : (
      <Component {...props} />
    )

  return WithSkeleton
}

export default withSkeleton
