import ButtonWrapper from '@/components/wrappers/button-wrapper/button-wrapper'
import type { TooltipProps } from 'antd'
import type { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactNode } from 'react'

type PropsType = {
  activated?: boolean
}

type PropsWithoutChildrenType = {
  icon: string
  children?: never
}

type PropsWithoutIconType = {
  icon?: never
  children: ReactNode
}

type ButtonToolbarType = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  TooltipProps &
  PropsType &
  (PropsWithoutChildrenType | PropsWithoutIconType)

const ButtonToolbar: FC<ButtonToolbarType> = ({ icon, children, ...props }) => (
  <ButtonWrapper {...props}>{icon ? <span className={`material-symbols-sharp`}>{icon}</span> : children}</ButtonWrapper>
)

export default ButtonToolbar
