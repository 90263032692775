import SkeletonFormItem from '@/components/controlers/skeletons/skeleton-form-item/skeleton-form-item'
import FieldWrapper from '@/components/wrappers/field-wrapper/field-wrapper'
import type { FormItemProps, TooltipProps } from 'antd'
import { Form, Select } from 'antd'
import type { SelectProps } from 'antd/es/select'
import type { DefaultOptionType } from 'antd/lib/select'
import cn from 'classnames'
import React from 'react'

import styles from './form-select.module.css'

interface IFormSelect<T extends DefaultOptionType>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extends SelectProps<any, T> {
  labelContent: string
  message?: string
  isLoading?: boolean
  required?: boolean
  disabled?: boolean
  name?: string
  initialValue?: FormItemProps['initialValue']
  layout?: 'vertical' | 'horizontal'
  tooltipKey?: string
  className?: string
}

const FormSelect = <T extends DefaultOptionType & TooltipProps>({
  labelContent,
  disabled,
  required,
  isLoading,
  message,
  name,
  initialValue,
  tooltipKey,
  layout = 'horizontal',
  className,
  ...props
}: IFormSelect<T>) =>
  !isLoading ? (
    <FieldWrapper className={className} tooltipKey={tooltipKey} layout={layout}>
      <Form.Item
        name={name}
        label={<span style={{ fontWeight: 'bold' }}>{labelContent}</span>}
        className={cn(styles.item, {
          [styles['item-disabled']]: disabled,
          [styles['item-vertical']]: layout === 'vertical'
        })}
        rules={[
          {
            required: required,
            message: message || `Поле «${labelContent}» обязательно`
          }
        ]}
        required={required}
        initialValue={initialValue}
      >
        <Select {...props} disabled={disabled} />
      </Form.Item>
    </FieldWrapper>
  ) : (
    <SkeletonFormItem />
  )

export default FormSelect
