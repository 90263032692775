import KinematicPoint from '@/components/widgets/kinematic-space/components/kinematic-condition/components/kinematic-points/components/kinematic-point'
import { MachineConditionRoute, MainRoute } from '@/constants/navigation/routes.constant'
import { levelColor } from '@/constants/node-state/level-color.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import type { INodesState } from '@/types/nodes/nodes.type'
import { getQualifierPointState } from '@/utils/measurement/get-qualifier-point-state'
import { getReducePointState } from '@/utils/measurement/get-reduce-point-state'
import { RightOutlined } from '@ant-design/icons'
import { Button, Collapse, Tooltip } from 'antd'
import cn from 'classnames'
import { isEmpty } from 'lodash'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import styles from './kinematic-points.module.css'

type TProps = {
  kinematicElements?: IKinematicElement[]
  dataAllNodesState?: INodesState[]
}

interface IKinematicPoint {
  kinematicElement: IKinematicElement
  measurementPointIds?: string[]
  level: string | null
}

const KinematicPoints: FC<TProps> = ({ kinematicElements, dataAllNodesState }) => {
  const collapseRef = useRef<HTMLDivElement>(null)

  const { setSelectedKinematic, setSelectedPointId } = useActions()
  const { selectedKinematic } = useTypedSelector((state) => state.kinematicUiReducer)
  const [isActive, setIsActive] = useState(false)
  const [activeKeys, setActiveKeys] = useState<string[]>([])

  useEffect(() => {
    const nativeElement = collapseRef.current
    if (nativeElement && selectedKinematic?.kinematicBlockId) {
      const selector = `[data-id="${selectedKinematic?.kinematicBlockId}"]`
      const selectedRow = collapseRef.current?.querySelector(selector)
      if (selectedRow) {
        selectedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        })
      }
    }
  }, [selectedKinematic])

  if (!kinematicElements || isEmpty(kinematicElements)) {
    return null
  }

  const filteredKinematics = kinematicElements.filter(
    (kinematicPoint) => kinematicPoint.measurementPointIds && !isEmpty(kinematicPoint.measurementPointIds)
  )

  const kinematicPoints: IKinematicPoint[] = filteredKinematics.map((kinematicElement) => {
    const commonStatePoints = getReducePointState(kinematicElement.measurementPointIds, dataAllNodesState)
    const level = getQualifierPointState(commonStatePoints)
    return {
      kinematicElement: kinematicElement,
      measurementPointIds: kinematicElement.measurementPointIds,
      level: level ? levelColor[level] : null
    }
  })

  const handleLinkClick = (pointId: string) => {
    setSelectedPointId(pointId)
  }

  const handleSelectionMouseEnter = (kinematicElement: IKinematicElement) => {
    setSelectedKinematic(kinematicElement)
  }

  const handleSelectionMouseLeave = () => {
    setSelectedKinematic(null)
  }

  const kinematics = kinematicPoints.map((kinematicPoint) => ({
    key: kinematicPoint.kinematicElement.kinematicBlockId,
    ['data-id']: kinematicPoint.kinematicElement.kinematicBlockId,
    label: kinematicPoint.kinematicElement.kinematicBlockName,
    style: { backgroundColor: kinematicPoint.level ?? '' },
    className: cn({
      [styles['selection']]: kinematicPoint.kinematicElement.kinematicBlockId === selectedKinematic?.kinematicBlockId
    }),
    onMouseEnter: () => handleSelectionMouseEnter(kinematicPoint.kinematicElement),
    onMouseLeave: handleSelectionMouseLeave,
    children:
      kinematicPoint.measurementPointIds &&
      kinematicPoint.measurementPointIds.map((measurementPointId) => (
        <Link
          key={measurementPointId}
          to={`/${MainRoute.MachineCondition}/${MachineConditionRoute.Results}`}
          className={styles['link']}
          onClick={() => handleLinkClick(measurementPointId)}
        >
          <KinematicPoint pointId={measurementPointId} dataAllNodesState={dataAllNodesState} />
        </Link>
      ))
  }))

  const allPanelKeys: string[] = kinematicPoints.map(
    (kinematicPoint) => kinematicPoint.kinematicElement.kinematicBlockId
  )

  const handleCollapseChange = (keys: string[] | string) => {
    setActiveKeys([...keys])
    setIsActive(allPanelKeys.length === keys.length)
  }

  const handleToggleCollapseClick = () => {
    if (isActive) {
      setIsActive(false)
      setActiveKeys([])
      return
    }
    setIsActive(true)

    setActiveKeys(allPanelKeys)
  }

  return (
    <div className={styles['information-space']}>
      <div className={styles['header']}>
        <h2 className={styles['title']}>Состояние КБ</h2>
        <Tooltip mouseEnterDelay={1} title={isActive ? 'Закрыть всё' : 'Открыть всё'} placement='bottom'>
          <Button className={styles['button']} onClick={handleToggleCollapseClick}>
            <RightOutlined className={isActive ? styles.rotated : styles.normal} />
          </Button>
        </Tooltip>
      </div>
      <Collapse
        ref={collapseRef}
        activeKey={activeKeys}
        onChange={handleCollapseChange}
        items={kinematics}
        className={styles['collapse']}
      />
    </div>
  )
}

export default KinematicPoints
