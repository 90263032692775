import type { EDetectorType } from '@/enums/measurment/common-level/detector-type.enum'

export const mapDetectorType: { [key in EDetectorType]: string } = {
  RMS: 'СКЗ',
  PEAK_FACTOR: 'ПИК-фактор',
  AMPLITUDE_RANGE: 'Размах',
  KURTOSIS: 'Эксцесс',
  SKEWNESS: 'Асимметрия',
  PEAK: 'ПИК'
} as const

export const mapDetectorTypeForName: { [key in EDetectorType]: string } = {
  RMS: 'СКЗ',
  PEAK_FACTOR: 'П-Ф',
  AMPLITUDE_RANGE: 'П-П',
  KURTOSIS: 'ЭКСЦ',
  SKEWNESS: 'АСС',
  PEAK: 'ПИК'
} as const

export const detectorTypeOptions = Object.entries(mapDetectorType).map(([key, label]) => ({
  value: key as EDetectorType,
  label
}))
