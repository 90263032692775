import SkeletonFormItem from '@/components/controlers/skeletons/skeleton-form-item/skeleton-form-item'
import FieldWrapper from '@/components/wrappers/field-wrapper/field-wrapper'
import { Tooltip } from 'antd'
import cn from 'classnames'
import type { FC } from 'react'

import styles from './form-information.module.css'

type FormInformationProps = {
  label: string
  value?: string | null
  title?: string
  className?: string
  isLoading?: boolean
  tooltipKey?: string
  layout?: 'vertical' | 'horizontal'
  lastElement?: boolean
}

const FormInformation: FC<FormInformationProps> = ({
  label,
  value,
  title,
  className,
  isLoading,
  tooltipKey,
  layout = 'horizontal',
  lastElement
}) =>
  !isLoading ? (
    <FieldWrapper className={className} tooltipKey={tooltipKey} lastElement={lastElement}>
      <div
        className={cn(styles['point-type-container'], {
          [styles['item-vertical']]: layout === 'vertical'
        })}
      >
        <h4 className={styles.title}>
          {label}
          {layout === 'horizontal' ? ':' : null}
        </h4>
        <Tooltip title={title} placement={'rightTop'}>
          <div className={styles.container}>
            <p className={styles['important-text']}>{value}</p>
          </div>
        </Tooltip>
      </div>
    </FieldWrapper>
  ) : (
    <SkeletonFormItem />
  )

export default FormInformation
