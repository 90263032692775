import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import { samplingRateLtr24Options, samplingRateOptions } from '@/constants/measurement/measurements.constant'
import { ELtr24SamplingRate } from '@/enums/measurment/ltr24-sampling-rate.enum'
import { ESamplingRate } from '@/enums/measurment/sampling-rate.enum'
import type { ISignalDescription } from '@/types/measurement/measurement.type'
import { Checkbox, InputNumber, Select } from 'antd'
import type { FC } from 'react'

interface IProps {
  isLtr24: boolean
  isAddMode: boolean
  isEditMode: boolean
  signalDescription?: ISignalDescription
}

const SignalForm: FC<IProps> = ({ isLtr24, isEditMode, isAddMode, signalDescription }) => {
  const staticSamplingRate =
    samplingRateOptions.find((item) => item.value === signalDescription?.samplingRate) ||
    samplingRateLtr24Options.find((item) => item.value === signalDescription?.ltr24SamplingRate)

  return (
    <>
      {isAddMode && (
        <>
          {!isLtr24 ? (
            <FormItemApp
              label='Частота дискретизации, Гц'
              name={['signalDescription', 'samplingRate']}
              rules={[{ required: true, message: 'Поле обязательно' }]}
              initialValue={ESamplingRate.RATE_13_6400}
            >
              <Select options={samplingRateOptions} />
            </FormItemApp>
          ) : (
            <FormItemApp
              label='Частота дискретизации, Гц'
              name={['signalDescription', 'ltr24SamplingRate']}
              rules={[{ required: true, message: 'Поле обязательно' }]}
              initialValue={ELtr24SamplingRate.LTR24_FREQ_58K}
            >
              <Select options={samplingRateLtr24Options} />
            </FormItemApp>
          )}
          <FormItemApp
            label='Длительность сигнала, сек'
            name={['signalDescription', 'durationInSec']}
            rules={[{ required: true, message: 'Поле обязательно' }]}
            initialValue={10}
          >
            <InputNumber />
          </FormItemApp>
          {/* Not implemented on the backend */}
          <FormItemApp
            label='Антиалайзинговый фильтр'
            name={['signalDescription', 'iirFilterEnabled']}
            valuePropName='checked'
          >
            <Checkbox disabled={true} />
          </FormItemApp>
        </>
      )}
      {isEditMode && (
        <>
          <TextStatic value={staticSamplingRate?.label} label='Частота дискретизации, Гц' />
          <TextStatic value={signalDescription?.durationInSec} label='Длительность сигнала, Гц' />
        </>
      )}
    </>
  )
}
export default SignalForm
