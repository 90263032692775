import type { TContextOptions } from '@/components/widgets/mnemonic/mnemonic-main/mnemonic-main'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useChangeMnemonicElementMutation } from '@/store/api/mnemo.api'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { Dispatch, FC, SetStateAction } from 'react'
import React from 'react'

import styles from './contextMenu.module.css'

type TProps = {
  draggable?: boolean
  setContextMenuOptions: Dispatch<SetStateAction<TContextOptions>>
  contextMenuOptions: TContextOptions
}

const ContextMenu: FC<TProps> = ({ draggable, setContextMenuOptions, contextMenuOptions }) => {
  const [changeMnemonicElement] = useChangeMnemonicElementMutation()

  const { mnemoSchemeItem } = useTypedSelector((state) => state.mnemonicReducer)

  const handleClickAddPoint = async () => {
    if (mnemoSchemeItem?.mnemoElementId) {
      try {
        const data = await changeMnemonicElement({
          ...mnemoSchemeItem,
          pointerPosition: mnemoSchemeItem?.pointerPosition ? null : { x: mnemoSchemeItem.x, y: mnemoSchemeItem.y + 40 }
        }).unwrap()

        if (data !== null) throw new Error('Ошибка базы')

        successNotificationCreate('Указатель обновлен')
      } catch (error) {
        errorNotificationCreate(error)
      } finally {
        setContextMenuOptions({ isOpen: false, position: { x: 0, y: 0 } })
      }
    }
  }

  return draggable ? (
    <div
      className={styles.container}
      style={{
        left: contextMenuOptions.position.x,
        top: contextMenuOptions.position.y
      }}
    >
      <button
        className={styles.button}
        onClick={() => {
          handleClickAddPoint()
        }}
        type={'button'}
      >
        {mnemoSchemeItem?.pointerPosition ? 'Удалить' : 'Добавить'} указатель
      </button>
    </div>
  ) : null
}

export default ContextMenu
