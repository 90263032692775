import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import Submenu from '@/components/controlers/submenu/submenu'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import MnemonicMain from '@/components/widgets/mnemonic/mnemonic-main/mnemonic-main'
import { machineConditionLinks } from '@/constants/navigation/links.constant'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import type { FC } from 'react'

const MnemonicCondition: FC = () => (
  <Container>
    <LeftBar>
      <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONDITION} />
    </LeftBar>
    <WorkSpace>
      <Submenu links={machineConditionLinks} />
      <MnemonicMain condition={true} />
    </WorkSpace>
  </Container>
)

export default MnemonicCondition
