import NavigationSettings from '@/app/settings/components/navigation-settings/navigation-settings'
import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import ToolBar from '@/components/controlers/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import type { FC } from 'react'
import React from 'react'

export const ComponentSettings: FC = () => (
  <Container>
    <ToolBar />
    <LeftBar>
      <NavigationSettings />
    </LeftBar>
    <WorkSpace>
      <>
        <p style={{ margin: '20px 0', display: 'block', textAlign: 'center' }}>
          Раздел не реализован.
        </p>
      </>
    </WorkSpace>
  </Container>
)

export default ComponentSettings
