import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { IMachine, IMachineInfo, TCreatedMachine } from '@/types/machine.type'
import queryString from 'query-string'

import { baseApi } from './api-base-query'

interface IGetMachinesResponse {
  content: IMachineInfo[]
}

export const machinesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMachines: builder.query<IGetMachinesResponse, string | null>({
      query: (groupId) => {
        const param = queryString.stringify({ groupId })
        return { url: `${ApiResource.Machines}?${param}` }
      },
      providesTags: [ApiTag.Machines]
    }),

    getAllMachines: builder.query<IGetMachinesResponse, void>({
      query: () => ({ url: `${ApiResource.Machines}/get-all?size=500` }),
      providesTags: [ApiTag.Machines]
    }),

    createMachine: builder.mutation<{ id: string }, TCreatedMachine>({
      query: (data) => ({
        url: ApiResource.Machines,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Machines]
    }),

    getMachine: builder.query<IMachine, string | null>({
      query: (id) => ({ url: `${ApiResource.Machines}/${id}` }),
      providesTags: [ApiTag.Machines]
    }),

    updateMachines: builder.mutation<null, { machineData: TCreatedMachine; machineId: string }>({
      query: ({ machineData, machineId }) => ({
        url: `${ApiResource.Machines}/${machineId}`,
        method: 'PUT',
        body: machineData
      }),
      invalidatesTags: [ApiTag.Machines]
    }),

    updateMachinesStatus: builder.mutation<null, { updateData: { paused: boolean }; machineId: string }>({
      query: ({ updateData, machineId }) => ({
        url: `${ApiResource.Machines}/${machineId}/paused`,
        method: 'PUT',
        body: updateData
      }),
      invalidatesTags: [ApiTag.Machines]
    })
  })
})

export const {
  useGetMachinesQuery,
  useGetAllMachinesQuery,
  useLazyGetMachinesQuery,
  useCreateMachineMutation,
  useGetMachineQuery,
  useLazyGetMachineQuery,
  useUpdateMachinesMutation,
  useUpdateMachinesStatusMutation
} = machinesApi
