import {
  TgbOut,
  TgbParam
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/tgb-form/tgb-form.constant'
import type {
  IFormTgb,
  ITgbOuts
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/tgb-form/tgb-form.type'
import {
  KbParam,
  TypeSource
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type {
  ITableDataOuts,
  TTupleIns
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import {
  calculateValue,
  handleName,
  handleValue
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import { REAL_NUMBER } from '@/config/connection.config'
import type { IIn, IKinematicElement, IOut } from '@/types/kinematic/kinematic.type'
import { formatFloat, isNumber } from '@/utils/common.util'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockTGB, outs } = kinematicElement

  interface IResult {
    fields: IFormTgb
    ins: TTupleIns
    outs: ITgbOuts | null
  }

  const result: IResult = {
    fields: {
      [KbParam.Description]: description,
      [KbParam.TypeSource]: TypeSource.Missing,
      [KbParam.FreqCoefForIn]: ins && ins[0].freqCoefForIn ? ins[0].freqCoefForIn : 1,
      [KbParam.KinematicBlockSourceId]: null,
      [KbParam.FreqOutSource]: null,
      [TgbParam.FreqCoefForInTwo]: ins && ins[1].freqCoefForIn ? ins[1].freqCoefForIn : 1,
      [TgbParam.KinematicBlockSourceTwo]: null,
      [TgbParam.FreqOutSourceTwo]: null,
      [TgbParam.Z1]: null,
      [TgbParam.Z3]: null
    },
    ins: [null, null],
    outs: null
  }

  if (kinematicBlockTGB) {
    const { z1, z3 } = kinematicBlockTGB
    result.fields[TgbParam.Z1] = z1
    result.fields[TgbParam.Z3] = z3
  }

  if (ins) {
    result.fields[KbParam.TypeSource] = ins[0].kinematicBlockSourseId ? TypeSource.Kb : TypeSource.Missing
    result.fields[KbParam.KinematicBlockSourceId] = ins[0].kinematicBlockSourseId
    result.fields[KbParam.FreqOutSource] = ins[0].freqInValue
    result.fields[TgbParam.KinematicBlockSourceTwo] = ins[1].kinematicBlockSourseId
    result.fields[TgbParam.FreqOutSourceTwo] = ins[1].freqInValue
    result.ins = [ins[0], ins[1]]
  }

  if (outs) {
    result.outs = Object.values(TgbOut).reduce((acc, value, index) => {
      acc[value] = {
        freqOutName: outs[index].freqOutName,
        freqOutCoefKb: outs[index].freqOutCoefKb,
        freqOutCoefMachine: outs[index].freqOutCoefMachine,
        freqOutValue: outs[index].freqOutValue
      }

      return acc
    }, {} as Record<TgbOut, IOut>)
  }
  return result
}

export const getCalculatedResults = (fieldsValue: IFormTgb, ins: [IIn, IIn], kinematicBlockName: string) => {
  const { freqOutSource, freqOutSourceTwo, freqCoefForIn, freqCoefForInTwo, z1, z3 } = fieldsValue
  const { freqOutCoefMachineSourse, freqOutNameSourse } = ins[0]
  const { freqOutCoefMachineSourse: freqOutCoefMachineSourseTwo, freqOutNameSourse: freqOutNameSourseTwo } = ins[1]

  const freqInValue = calculateValue(freqOutSource, freqCoefForIn)
  const freqInValueTwo = calculateValue(freqOutSourceTwo, freqCoefForInTwo)
  const freqInCoefMachine = calculateValue(freqOutCoefMachineSourse, freqCoefForIn)
  const freqInCoefMachineTwo = calculateValue(freqOutCoefMachineSourseTwo, freqCoefForInTwo)

  const foCoefKb = isNumber(z1) && isNumber(z3) ? z1 / z3 : null

  const result: ITgbOuts = {
    [TgbOut.Frtgb1]: {
      freqOutName: freqOutNameSourse || null,
      freqOutCoefKb: freqCoefForIn ?? null,
      freqOutCoefMachine: freqInCoefMachine,
      freqOutValue: freqInValue
    },
    [TgbOut.Frtgb2]: {
      freqOutName: freqOutNameSourseTwo || null,
      freqOutCoefKb: freqCoefForInTwo || null,
      freqOutCoefMachine: freqInCoefMachineTwo,
      freqOutValue: freqInValueTwo
    },
    [TgbOut.Fo]: {
      freqOutName: kinematicBlockName ? `${kinematicBlockName}_Fo` : null,
      freqOutCoefKb: foCoefKb,
      freqOutCoefMachine: calculateValue(freqInCoefMachine, foCoefKb),
      freqOutValue: calculateValue(freqInValue, foCoefKb)
    },
    [TgbOut.Fz]: {
      freqOutName: kinematicBlockName ? `${kinematicBlockName}_Fz` : null,
      freqOutCoefKb: z1,
      freqOutCoefMachine: calculateValue(freqInCoefMachineTwo, z1),
      freqOutValue: calculateValue(freqInValueTwo, z1)
    }
  }

  Object.keys(result).forEach((key) => {
    const keyType = key as keyof ITgbOuts
    const { freqOutValue, freqOutCoefMachine, freqOutCoefKb } = result[keyType]

    result[keyType].freqOutValue = freqOutValue ? formatFloat(freqOutValue, REAL_NUMBER) : null
    result[keyType].freqOutCoefKb = freqOutCoefKb ? formatFloat(freqOutCoefKb, REAL_NUMBER) : null
    result[keyType].freqOutCoefMachine = freqOutCoefMachine ? formatFloat(freqOutCoefMachine, REAL_NUMBER) : null
  })
  return result
}

export const parseDataForChangeTgb = (
  kinematicElement: IKinematicElement,
  fields: IFormTgb,
  ins: [IIn, IIn],
  tgbOuts: ITgbOuts
): IKinematicElement => {
  const { description, kinematicBlockSourseId, freqCoefForIn, kinematicBlockSourceIdTwo, freqCoefForInTwo } = fields

  return {
    ...kinematicElement,
    description: description,
    kinematicBlockTGB: {
      z1: fields.z1 || 0,
      z2: null,
      z3: fields.z3 || 0
    },
    ins: [
      {
        freqInName: ins[0].freqOutNameSourse,
        freqInValue: ins[0].freqInValue,
        freqOutCoefMachineSourse: ins[0].freqOutCoefMachineSourse,
        freqInCoefMachine:
          isNumber(freqCoefForIn) && isNumber(ins[0].freqOutCoefMachineSourse)
            ? ins[0].freqOutCoefMachineSourse * freqCoefForIn
            : 1,
        kinematicBlockSourseId: kinematicBlockSourseId,
        freqOutCoefIndexSourse: ins[0].freqOutCoefIndexSourse || 0,
        freqOutNameSourse: ins[0].freqOutNameSourse || 'Fin1',
        freqCoefForIn: isNumber(freqCoefForIn) ? freqCoefForIn : 1,
        measurementSourseId: null
      },
      {
        freqInName: ins[1].freqOutNameSourse,
        freqInValue: ins[1].freqInValue,
        freqOutCoefMachineSourse: ins[1].freqOutCoefMachineSourse,
        freqInCoefMachine:
          isNumber(freqCoefForInTwo) && isNumber(ins[1].freqOutCoefMachineSourse)
            ? ins[1].freqOutCoefMachineSourse * freqCoefForInTwo
            : 1,
        kinematicBlockSourseId: kinematicBlockSourceIdTwo,
        freqOutCoefIndexSourse: ins[1].freqOutCoefIndexSourse || 0,
        freqOutNameSourse: ins[1].freqOutNameSourse || 'Fin2',
        freqCoefForIn: isNumber(freqCoefForInTwo) ? freqCoefForInTwo : 1,
        measurementSourseId: null
      }
    ],
    outs: Object.values(tgbOuts)
  }
}

export const formatDataForTable = (outs: ITgbOuts): ITableDataOuts[] => {
  const { frtgb1, frtgb2, fo, fz } = outs
  const data: Omit<ITableDataOuts, 'key'>[] = [
    {
      label: 'Частота вращения (вход 1)',
      freqOutName: handleName(frtgb1.freqOutName),
      freqOutCoefKb: handleValue(frtgb1.freqOutCoefKb),
      freqOutCoefMachine: handleValue(frtgb1.freqOutCoefMachine),
      freqOutValue: handleValue(frtgb1.freqOutValue)
    },
    {
      label: 'Частота вращения (вход 2)',
      freqOutName: handleName(frtgb2.freqOutName),
      freqOutCoefKb: handleValue(frtgb2.freqOutCoefKb),
      freqOutCoefMachine: handleValue(frtgb2.freqOutCoefMachine),
      freqOutValue: handleValue(frtgb2.freqOutValue)
    },
    {
      label: 'Частота вращения выходного вала',
      freqOutName: handleName(fo.freqOutName),
      freqOutCoefKb: handleValue(fo.freqOutCoefKb),
      freqOutCoefMachine: handleValue(fo.freqOutCoefMachine),
      freqOutValue: handleValue(fo.freqOutValue)
    },
    {
      label: 'Зубцовая частота первой и второй ступени',
      freqOutName: handleName(fz.freqOutName),
      freqOutCoefKb: handleValue(fz.freqOutCoefKb),
      freqOutCoefMachine: handleValue(fz.freqOutCoefMachine),
      freqOutValue: handleValue(fz.freqOutValue)
    }
  ]

  return data.map<ITableDataOuts>((item, key) => ({ ...item, key }))
}
