import { SliceName } from '@/constants/store/slices.constant'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type {
  IInitialExpressStore,
  IMarker,
  IMarkerForm,
  IResultMarkers
} from '@/types/express-analysis/marker/marker.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IInitialExpressStore = {
  markers: null,
  isMarkersExpressBarOpen: false,
  markersResults: [],
  formMarkers: null,
  isDragZone: false,
  activeMarker: null,
  chartSpectrum: null
}

export const expressMarkersSlice = createSlice({
  name: SliceName.ExpressMarkers,
  initialState,
  reducers: {
    setIsMarkersExpressOpen(state, action: PayloadAction<boolean>) {
      state.isMarkersExpressBarOpen = action.payload
    },

    setMarkers(state, action: PayloadAction<IMarker[]>) {
      state.markers = action.payload
    },

    setMarker(state, actions: PayloadAction<IMarker>) {
      if (state.markers) {
        state.markers[actions.payload.index] = actions.payload
      }
    },

    setMarkersResults(state, action: PayloadAction<IResultMarkers>) {
      const foundIndex = state.markersResults.findIndex((result) => result.title === action.payload.title)

      if (foundIndex === -1) {
        state.markersResults.push(action.payload)
      } else {
        state.markersResults[foundIndex] = action.payload
      }
    },

    resetMarkersResults(state) {
      state.markersResults = []
    },

    setFormMarker(state, action: PayloadAction<IMarkerForm>) {
      state.formMarkers = action.payload
    },

    setIsDragZone(state, action: PayloadAction<boolean>) {
      state.isDragZone = action.payload
    },

    setActiveMarker(state, action: PayloadAction<IMarker>) {
      state.activeMarker = action.payload
    },

    setChartSpectrum(state, action: PayloadAction<IChartSpectrum[]>) {
      state.chartSpectrum = action.payload
    },

    resetMarkers(state) {
      state.markers = null
      state.chartSpectrum = null
      state.activeMarker = null
      state.formMarkers = null
      state.markersResults = []
    }
  }
})
