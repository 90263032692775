import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { IConnection, TCreateConnection } from '@/types/connection.type'
import type { IPagedResponse } from '@/types/paged-response.interface'
import queryString from 'query-string'

import { baseApi } from './api-base-query'

export interface IQueryParameters {
  measuringPoint?: string | null
  ltrModuleId?: string
  ltrCrateId?: string
  vp3701Id?: string
  generatorId?: string
}

export const connectionsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getConnections: builder.query<IPagedResponse<IConnection>, IQueryParameters | null>({
      query: (options) => {
        const stringifyParams = queryString.stringify({ ...options })
        return {
          url: `${ApiResource.Connections}?${stringifyParams}`
        }
      },
      providesTags: [ApiTag.Connections]
    }),

    createConnections: builder.mutation<{ id?: string }, TCreateConnection>({
      query: (data) => ({
        url: ApiResource.Connections,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Connections, ApiTag.Ltr]
    }),

    deleteConnections: builder.mutation<null, string>({
      query: (connectionId) => ({
        url: `${ApiResource.Connections}/${connectionId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ApiTag.Connections]
    })
  })
})

export const {
  useGetConnectionsQuery,
  useLazyGetConnectionsQuery,
  useDeleteConnectionsMutation,
  useCreateConnectionsMutation
} = connectionsApi
