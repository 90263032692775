import PopupKinematic from '@/app/configuration/components/kinematics-configuration/components/popup-kinematic/popup-kinematic'
import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import PopupDelete from '@/components/widgets/popup-delete/popup-delete'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetKinematicsQuery, useRemoveKinematicMutation } from '@/store/api/kinematic.api'
import type { IKinematicScheme } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import type { FC } from 'react'
import { useState } from 'react'

type KinematicToolBarType = {
  kinematicScheme?: IKinematicScheme
}

const KinematicToolBar: FC<KinematicToolBarType> = ({ kinematicScheme }) => {
  const [isPopupDeleteOpen, setIsPopupDeleteOpen] = useState(false)
  const [isPopupKinematicOpen, setIsPopupKinematicOpen] = useState(false)
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)
  const { isLoading: isLoadingKinematic } = useGetKinematicsQuery(selectedMachineId, { skip: !selectedMachineId })

  const [deleteKinematic, { isLoading: isDeleting }] = useRemoveKinematicMutation()
  if (!selectedMachineId || isLoadingKinematic) return null

  const handleDelete = async () => {
    try {
      if (kinematicScheme?.kinematicSchemeId) {
        await deleteKinematic(kinematicScheme?.kinematicSchemeId).unwrap()
        successNotificationCreate(`Кинематическая схема ${kinematicScheme.name} удалена`)
      }
    } catch (e) {
      errorNotificationCreate(e)
    } finally {
      setIsPopupDeleteOpen(false)
    }
  }

  return (
    <>
      <PopupDelete
        title={`Вы действительно хотите удалить кинематическую схему?`}
        onClose={() => setIsPopupDeleteOpen(false)}
        onDelete={handleDelete}
        isOpen={isPopupDeleteOpen}
        isLoading={isDeleting}
      />
      <PopupKinematic
        setIsPopupOpen={setIsPopupKinematicOpen}
        isPopupOpen={isPopupKinematicOpen}
        kinematicScheme={kinematicScheme}
      />
      <ButtonToolbar
        icon={'add'}
        onClick={() => {
          setIsPopupKinematicOpen(true)
        }}
        title={'Добавить кинематическую схему'}
        disabled={Boolean(kinematicScheme)}
      />
      <ButtonToolbar
        icon={'delete'}
        onClick={() => {
          setIsPopupDeleteOpen(true)
        }}
        title={'Удалить кинематическую схему'}
        disabled={Boolean(!kinematicScheme)}
      />
    </>
  )
}

export default KinematicToolBar
