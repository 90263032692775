import InformationBlock from '@/components/widgets/kinematic-space/components/condition-block/components/information-block/information-block'
import StatusBlock from '@/components/widgets/kinematic-space/components/condition-block/components/status-block/status-block'
import type { IAttributesKinematicBlocks } from '@/components/widgets/kinematic-space/kinematic-space.type'
import { attributesKinematicBlocks, kbsWithoutIns } from '@/components/widgets/kinematic-space/kinematic.constant'
import { EKinematicViewMode } from '@/enums/kinematic/kinematic-view-mode.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import type { FC } from 'react'
import useImage from 'use-image'

type TKinematicBlock = {
  kinematicElement: IKinematicElement
  index: number
}

const ConditionBlock: FC<TKinematicBlock> = ({ kinematicElement, index }) => {
  const { viewMode } = useTypedSelector((state) => state.kinematicUiReducer)
  const { selectedKinematic } = useTypedSelector((state) => state.kinematicUiReducer)
  const getStatusConnectionColor = (element: IKinematicElement): string | undefined => {
    const { kinematicBlockType, ins } = element

    const selected = selectedKinematic?.kinematicBlockId === kinematicElement.kinematicBlockId

    if (selected) {
      return 'rgb(68,137,243)'
    }

    return kbsWithoutIns.every((item) => kinematicBlockType !== item) && !ins ? 'red' : undefined
  }

  const currentAttributeKinematicBlock = attributesKinematicBlocks.find(
    (element) => element.type === kinematicElement.kinematicBlockType
  ) as IAttributesKinematicBlocks

  const [image] = useImage(currentAttributeKinematicBlock.iconSrc)

  const getComponent = (mode: EKinematicViewMode): JSX.Element => {
    const components: { [key in EKinematicViewMode]: JSX.Element } = {
      [EKinematicViewMode.Information]: (
        <InformationBlock
          kinematicElement={kinematicElement}
          statusConnection={getStatusConnectionColor(kinematicElement)}
          image={image}
          index={index}
        />
      ),
      [EKinematicViewMode.Status]: (
        <StatusBlock
          kinematicElement={kinematicElement}
          statusConnection={getStatusConnectionColor(kinematicElement)}
          image={image}
        />
      )
    }

    return components[mode]
  }

  return getComponent(viewMode)
}

export default ConditionBlock
