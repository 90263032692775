import ExpressMarkersResults from '@/app/machine-condition/components/results/components/charts/components/express-sidebar/components/express-markers-results/express-markers-results'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { LegacyRef, MutableRefObject } from 'react'
import { type FC } from 'react'
import { shallowEqual } from 'react-redux'

import type { TExpressAnalysis } from '../../charts'
import ExpressBar from './components/bar/express-bar'
import ExpressSection from './components/section/express-section'
import ExpressStrobe from './components/strobe/express-strobe'

interface IProps {
  expressStrobeAnalysisItems: TExpressAnalysis
  itemStrobesRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
  itemSectionsRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
  itemMarkersRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
  itemBarsRefs: MutableRefObject<LegacyRef<HTMLLIElement>[] | null>
  onClickExpressStrobeButton: (index: number) => void
  onClickExpressSectionButton: (index: number) => void
  onClickExpressMarkerButton: (index: number) => void
  onClickExpressBarButton: (index: number) => void
  durationInSec?: number
  dataLength: number
  isTypeSpectrum: boolean
  isTypeScalar: boolean
  isTypeTiming: boolean
}

const ExpressSidebar: FC<IProps> = ({
  expressStrobeAnalysisItems,
  itemStrobesRefs,
  itemSectionsRefs,
  itemMarkersRefs,
  itemBarsRefs,
  onClickExpressStrobeButton,
  onClickExpressSectionButton,
  onClickExpressMarkerButton,
  onClickExpressBarButton,
  durationInSec,
  dataLength,
  isTypeSpectrum,
  isTypeTiming
}) => {
  const { isSectionExpressBarOpen, isStrobeExpressBarOpen, isMarkersExpressBarOpen, isBarsExpressBarOpen } =
    useTypedSelector(
      (state) => ({
        ...state.expressSectionReducer,
        ...state.expressStrobeReducer,
        ...state.expressMarkersReducer,
        ...state.expressBarsReducer
      }),
      {
        equalityFn: shallowEqual
      }
    )

  return (
    <>
      {isStrobeExpressBarOpen && isTypeTiming && (
        <ExpressStrobe
          expressAnalysisItems={expressStrobeAnalysisItems}
          itemStrobesRefs={itemStrobesRefs}
          onClickExpressStrobeButton={onClickExpressStrobeButton}
          durationInSec={durationInSec}
          dataLength={dataLength}
        />
      )}
      {isTypeSpectrum && (
        <>
          {/* Сечения */}
          {isSectionExpressBarOpen && (
            <ExpressSection
              onClickExpressSectionButton={onClickExpressSectionButton}
              itemSectionsRefs={itemSectionsRefs}
            />
          )}
          {/* Маркеры */}
          {isMarkersExpressBarOpen && (
            <ExpressMarkersResults
              onClickExpressMarkerButton={onClickExpressMarkerButton}
              itemMarkersRefs={itemMarkersRefs}
            />
          )}
          {/* Ряды */}
          {isBarsExpressBarOpen && (
            <ExpressBar onClickExpressBarButton={onClickExpressBarButton} itemBarsRefs={itemBarsRefs} />
          )}
        </>
      )}
    </>
  )
}

export default ExpressSidebar
