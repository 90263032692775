import { ApiResource, ApiTag } from '@/constants/store/api.constants'
import type { IGroup, TCreateGroup, TGroupElement } from '@/types/group/group.type'
import type { IPagedResponse } from '@/types/paged-response.interface'
import queryString from 'query-string'

import { baseApi } from './api-base-query'

export const groupsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getGroups: builder.query<IPagedResponse<TGroupElement>, string | null>({
      query: (parentId) => {
        const param = queryString.stringify({ parentId })
        return { url: `${ApiResource.Groups}?${param}` }
      },
      providesTags: [ApiTag.Groups]
    }),

    createGroup: builder.mutation<{ id: string }, TCreateGroup>({
      query: (data) => ({
        url: ApiResource.Groups,
        method: 'POST',
        body: data
      }),
      invalidatesTags: [ApiTag.Groups]
    }),

    getGroup: builder.query<IGroup, { groupId: string | null }>({
      query: ({ groupId }) => ({ url: `${ApiResource.Groups}/${groupId}` }),
      providesTags: [ApiTag.Groups]
    }),

    updateGroup: builder.mutation<void, { groupId: string; data: TCreateGroup }>({
      query: ({ groupId, data }) => ({
        url: `${ApiResource.Groups}/${groupId}`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Groups]
    }),

    updateGroupPaused: builder.mutation<void, { groupId: string; data: Pick<IGroup, 'paused'> }>({
      query: ({ groupId, data }) => ({
        url: `${ApiResource.Groups}/${groupId}/paused`,
        method: 'PUT',
        body: data
      }),
      invalidatesTags: [ApiTag.Groups]
    })
  })
})

export const {
  useGetGroupQuery,
  useGetGroupsQuery,
  useLazyGetGroupsQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useUpdateGroupPausedMutation
} = groupsApi
