import { valuesByChannelTypes } from '@/app/configuration/components/devices/vp3711-channel-panel/vp3711-channel-panel.constant'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import CommonTitle from '@/components/controlers/panel/common-title/common-title'
import ContentWrapper from '@/components/controlers/panel/content-wrapper/content-wrapper'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import SwitchIndicator from '@/components/controlers/switch-indicator/switch-indicator'
import HeaderConfiguration from '@/components/widgets/header-work-space/header-configuration'
import Spinner from '@/components/widgets/spinner/spinner'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import ControlsWrapper from '@/components/wrappers/controls-wrapper/controls-wrapper'
import { ButtonTitle } from '@/constants/core/common.constant'
import { ltr24ChannelTypeOptions } from '@/constants/device/vp3711/ltr24-channel.constant'
import { scaleTypeOptions } from '@/constants/device/vp3711/scale-type.constant'
import { pointTypes } from '@/constants/point/point.constant'
import { EMeasuringPointConnectionStatus } from '@/enums/device/measuring-point-connection-status.enum'
import { ELtr24ChannelType } from '@/enums/ltr/ltr24-channel-type.enum'
import { ELtr24RangeType } from '@/enums/ltr/ltr24-range-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import {
  useDropAllChannelConnectionsMutation,
  useGetLtrModuleQuery,
  useUpdateChannelInModuleMutation
} from '@/store/api/ltr.api'
import type { TVp3711ChannelForm, TVp3711ChannelStatic } from '@/types/device/vp3711/vp3711-channel-panel.type'
import type { ILtr24ChannelUpdate } from '@/types/ltr/channel/ltr24-channel-update.interface'
import { generateChannelName } from '@/utils/device/generate-channel-name'
import { getUnitTypeOptionsWithoutAssociation } from '@/utils/measurement/get-filter-unit.unit'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { Checkbox, Flex, Form, Input, InputNumber, Modal, Select } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import type { FC } from 'react'
import React, { useEffect, useMemo, useState } from 'react'

import styles from './vp3711-channel.module.css'

type TProps = {
  deviceId: string | undefined
}

const availablePhysicalOptions = [
  EPhysicalQuantityType.VOLTAGE,
  EPhysicalQuantityType.VIBRO_ACCELERATION,
  EPhysicalQuantityType.VIBRO_DISPLACEMENT,
  EPhysicalQuantityType.VIBRO_VELOCITY,
  EPhysicalQuantityType.AMPERAGE
]

export const availableScaleTypeByEnableIcp = [ELtr24RangeType.LTR24_ICP_RANGE_1, ELtr24RangeType.LTR24_ICP_RANGE_5]

const physicalOptions = pointTypes
  .filter((item) => availablePhysicalOptions.includes(item.value))
  .map((item) => ({ ...item, disabled: false }))

const Vp3711ChannelPanel: FC<TProps> = ({ deviceId }) => {
  const partsDeviceId = deviceId?.split('-')
  const [moduleId, channelIndex] = partsDeviceId ? partsDeviceId : []
  const { data: ltrModule, isLoading } = useGetLtrModuleQuery({ ltrModuleId: moduleId }, { skip: !moduleId })
  const [triggerUpdateChannel, { isLoading: isUpdating }] = useUpdateChannelInModuleMutation()
  const [triggerDropAllChannelConnections, { isLoading: isLoadingDropAllConnections }] =
    useDropAllChannelConnectionsMutation()

  const [form] = useForm<TVp3711ChannelForm>()
  const [staticData, setStaticData] = useState<TVp3711ChannelStatic>({})
  const [disabledIcpMode, setDisabledIcpMode] = useState(false)
  const [openedModal, setOpenedModal] = useState(false)
  const channel = ltrModule?.ltr24ChannelSettings.find((_, index) => index === Number(channelIndex))
  const name = generateChannelName(Number(channelIndex))

  const icpModeWatched = useWatch('icpMode', form)
  const typeChannelWatched = useWatch('ltr24ChannelType', form)
  const integrationWatched = useWatch('integration', form)
  const physicalQuantityTypeWatched = useWatch('physicalQuantityType', form)

  const isConnected = staticData.connectionStatus === EMeasuringPointConnectionStatus.MEASURING_POINT_CONNECTED

  const filteredScaleTypeOptions = useMemo(
    () =>
      icpModeWatched
        ? scaleTypeOptions.filter((item) => availableScaleTypeByEnableIcp.includes(item.value))
        : scaleTypeOptions.filter((item) => !availableScaleTypeByEnableIcp.includes(item.value)),
    [icpModeWatched]
  )

  useEffect(() => {
    if (channel) {
      const data: TVp3711ChannelForm = {
        ...channel,
        name: channel.name || name
      }

      const dataForStatic: TVp3711ChannelStatic = {
        connectionStatus: channel.connectionStatus
      }

      form.setFieldsValue(data)
      setStaticData(dataForStatic)
    }
  }, [channel, form, name])

  useEffect(() => {
    const defaultGainCoefficient = valuesByChannelTypes.find(
      (item) => item.conditions.ltr24ChanelType === typeChannelWatched
    )

    if (defaultGainCoefficient?.data) {
      form.setFieldsValue(defaultGainCoefficient.data)
      setDisabledIcpMode(defaultGainCoefficient.disabled.icpMode)
    }
  }, [form, integrationWatched, typeChannelWatched])

  if (isLoading) {
    return <Spinner />
  }

  if (!channel) {
    return undefined
  }

  const handleUpdateVp3711ModuleFinish = async () => {
    const fields = form.getFieldsValue()

    try {
      for (const key in fields) {
        if (key !== 'sensorROut' && fields[key as keyof ILtr24ChannelUpdate] === undefined) {
          throw new Error(`Поле ${key} не должно быть undefined`)
        }
      }
      const checkedFields = { ...fields, channelNumber: Number(channelIndex) } as ILtr24ChannelUpdate

      await triggerUpdateChannel({ ltrModuleId: moduleId, channelNumber: channelIndex, body: checkedFields }).unwrap()
      successNotificationCreate('Параметры канала обновлены')
    } catch (e) {
      console.error(e)
      errorNotificationCreate(e)
    }
  }

  const handleCancelForm = async () => {
    form.setFieldsValue(channel)
  }

  const unitOptions = getUnitTypeOptionsWithoutAssociation(physicalQuantityTypeWatched)

  const setUnitTypeByPhysicalQuantityType = (value: EPhysicalQuantityType) => {
    const baseValue = getUnitTypeOptionsWithoutAssociation(value)
    if (baseValue) {
      form.setFieldValue('unitType', baseValue[0].value)
    }
  }

  const handlePhysicalQuantityChange = (value: EPhysicalQuantityType) => {
    if (isConnected) {
      setOpenedModal(true)
      form.setFieldValue('physicalQuantityType', physicalQuantityTypeWatched)
      return
    }

    setUnitTypeByPhysicalQuantityType(value)
  }

  const handleResetScaleTypeChange = () => {
    form.setFieldValue('scaleType', undefined)
  }

  const handleDropAllConnectionsClick = async () => {
    try {
      await triggerDropAllChannelConnections({ ltrModuleId: moduleId, channelNumber: channelIndex }).unwrap()
      successNotificationCreate('Связь разорвана')
    } catch (e) {
      errorNotificationCreate(e)
      console.error(e)
    }

    setOpenedModal(false)
  }

  const handleClosedModalCancel = () => {
    setOpenedModal(false)
  }

  const connectionStatus = (
    <SwitchIndicator
      sourceBoolean={isConnected}
      trueContent='Канал подключен к ТИ'
      falseContent='Связь с ТИ отсутствует'
    />
  )

  const hasForbiddenIntegration = [ELtr24ChannelType.LIN, ELtr24ChannelType.ICP].every(
    (item) => item !== typeChannelWatched
  )

  return (
    <>
      <HeaderConfiguration
        content={
          <Flex gap={5} align='center'>
            <span className='material-symbols-outlined'>login</span>
            <span>Канал {channel.name || name}</span>
          </Flex>
        }
      />
      <ContentWrapper>
        <Form className={styles.container} form={form} onFinish={handleUpdateVp3711ModuleFinish} disabled={isUpdating}>
          <CommonTitle title='Параметры канала' />
          <ControlsWrapper>
            <FormItemApp name='enabled' label='Доступен' initialValue={true} valuePropName='checked'>
              <Checkbox />
            </FormItemApp>
            <FormItemApp
              name='name'
              label='Наименование канала'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Input />
            </FormItemApp>
            <FormItemApp
              name='ltr24ChannelType'
              label='Тип канала'
              required={true}
              initialValue={ELtr24ChannelType.ICP}
            >
              <Select options={ltr24ChannelTypeOptions} />
            </FormItemApp>
            <FormItemApp
              name='sensitivity'
              label='Коэф. преобразования'
              initialValue={0}
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <InputNumber placeholder={'Введите коэффициент усиления'} step={0.01} decimalSeparator={','} />
            </FormItemApp>
            <FormItemApp name='acMode' label='Режим АС' initialValue={true} valuePropName='checked'>
              <Checkbox />
            </FormItemApp>
            <FormItemApp name='icpMode' label='Режим ICP' valuePropName='checked' initialValue={true}>
              <Checkbox onChange={handleResetScaleTypeChange} disabled={disabledIcpMode} />
            </FormItemApp>
            <FormItemApp
              name='sensorROut'
              label='Выходное сопротивление датчика'
              initialValue={1}
              style={{ display: 'none' }}
            >
              <InputNumber disabled={true} />
            </FormItemApp>
          </ControlsWrapper>
          <ControlsWrapper>
            <FormItemApp
              name='gainCoefficient'
              label='Коэф. усиления тракта'
              initialValue={1}
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <InputNumber placeholder={'Введите коэффициент усиления'} decimalSeparator={','} />
            </FormItemApp>
            <FormItemApp name='integration' label='Интегрирование' initialValue={false} valuePropName='checked'>
              <Checkbox disabled={hasForbiddenIntegration} />
            </FormItemApp>
            <FormItemApp
              name='physicalQuantityType'
              label='Физ. величина'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Select
                options={physicalOptions}
                placeholder='Выберите физическую величину'
                onChange={handlePhysicalQuantityChange}
              />
            </FormItemApp>
            <FormItemApp name='unitType' label='Ед. изм.' rules={[{ required: true, message: 'Поле обязательно' }]}>
              <Select
                options={unitOptions}
                placeholder={
                  physicalQuantityTypeWatched ? 'Выберите единицу измерения' : 'Выберите тип физической величины'
                }
                disabled={!physicalQuantityTypeWatched}
              />
            </FormItemApp>
            <FormItemApp
              name='scaleType'
              label='Значения шкалы'
              rules={[{ required: true, message: 'Поле обязательно' }]}
            >
              <Select options={filteredScaleTypeOptions} placeholder='Выберите значение шкалы' />
            </FormItemApp>
            <TextStatic label='Статус подключения' value={connectionStatus} />
          </ControlsWrapper>
          <ControlsWrapper>
            <ButtonsWrapper>
              <ButtonPrimary
                htmlType='button'
                title={ButtonTitle.Reset}
                isLoadingSkeleton={false}
                isLoading={false}
                loading={isUpdating}
                onClick={handleCancelForm}
              />
              <ButtonPrimary htmlType='submit' title={ButtonTitle.Save} loading={isUpdating} />
            </ButtonsWrapper>
          </ControlsWrapper>
        </Form>
      </ContentWrapper>
      <Modal
        open={openedModal}
        onCancel={handleClosedModalCancel}
        title='Внимание!'
        centered={true}
        cancelButtonProps={{ disabled: isLoadingDropAllConnections }}
        footer={
          <ButtonsWrapper>
            <ButtonPrimary
              htmlType='button'
              title={'Отмена'}
              loading={isLoadingDropAllConnections}
              onClick={handleClosedModalCancel}
            />
            <ButtonPrimary
              htmlType='button'
              title='Разорвать связи'
              loading={isLoadingDropAllConnections}
              onClick={handleDropAllConnectionsClick}
            />
          </ButtonsWrapper>
        }
      >
        <p className={styles['paragraph']}>
          Изменение физической величины приведет к разрыву установленных связей между измерительным каналом и точками
          измерения.
        </p>
        <p className={styles['paragraph']}> Подтвердите действие!</p>
      </Modal>
    </>
  )
}

export default Vp3711ChannelPanel
