import TablePlans from '@/app/plans/components/plans-main-content/components/table-plans/table-plans'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import Loader from '@/components/widgets/loader/loader'
import Spinner from '@/components/widgets/spinner/spinner'
import { DATE_TEMPLATE } from '@/constants/core/common.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetAllMachinesQuery } from '@/store/api/machines.api'
import { useChangePlanMutation, useGetPlansQuery } from '@/store/api/plans.api'
import type { TPlan } from '@/types/plan/plan.type'
import { formatDate } from '@/utils/format-date'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { notification } from 'antd'
import type { DataNode } from 'antd/es/tree'
import cn from 'classnames'
import type { DragEventHandler, FC } from 'react'
import React, { useEffect } from 'react'

import styles from '@/app/plans/components/plans-main-content/plans-main-content.module.css'

import PlansList from './components/plans-list/PlansList'

const EMPTY_DATE = '---------- --:--:--'

export const enum ENodeType {
  MACHINE = 'machine',
  POINT = 'point',
  MEASUREMENT = 'measurement'
}

export type TDataNode = DataNode & {
  machineId: string
  pointId?: string
  measurementId?: string
  nodeType: ENodeType
}

const PlansMainContent: FC = () => {
  const { addMachineInPlan, addPlan, setPlans, updatePlan } = useActions()
  const { draggableMachineId } = useTypedSelector((state) => state.equipmentsUiReducer)
  const [changePlan, { isLoading: isUpdateLoading }] = useChangePlanMutation()
  const { data: machinesResponse, isSuccess: isSuccessMachines } = useGetAllMachinesQuery()

  const { selectedPlanId, plans } = useTypedSelector((state) => state.planReducer)

  const { data: plansResponse, isFetching: isFetchingPlans, isSuccess: isSuccessPlans } = useGetPlansQuery()
  const plan = plans?.find((item) => item.planId === selectedPlanId)

  useEffect(() => {
    if (plansResponse?.content && isSuccessPlans && isSuccessMachines) {
      const plansWithMachines: TPlan[] = plansResponse.content.map((planItem) => ({
        ...planItem,
        machines: machinesResponse?.content
          ? machinesResponse.content
              .filter((machine) => planItem?.machineIds?.includes(machine.machineId))
              .map((item) => ({ ...item, points: [] }))
          : []
      }))
      setPlans(plansWithMachines)
    }
  }, [isSuccessMachines, isSuccessPlans, machinesResponse?.content, plansResponse?.content, setPlans])

  const plansTable = plansResponse?.content.map((item) => {
    const changedPlan = { ...item }
    changedPlan.modified = formatDate(String(changedPlan?.modified), DATE_TEMPLATE)

    changedPlan.lastExecutionTimestamp = changedPlan?.lastExecutionTimestamp
      ? formatDate(String(changedPlan.lastExecutionTimestamp), DATE_TEMPLATE)
      : EMPTY_DATE

    changedPlan.nextExecutionTimestamp = changedPlan?.nextExecutionTimestamp
      ? formatDate(String(changedPlan?.nextExecutionTimestamp), DATE_TEMPLATE)
      : EMPTY_DATE

    return changedPlan
  })

  const handleDragEnd: DragEventHandler<HTMLDivElement> = async (evt) => {
    evt.preventDefault()
    try {
      const isNotSelectedPlan = Boolean(!selectedPlanId)
      if (isNotSelectedPlan) {
        notification.error({
          message: 'Операция невыполнима',
          description: 'Маршрут для добавления машины не выбран'
        })

        return
      }

      const foundMachine = machinesResponse?.content.find((item) => item.machineId === draggableMachineId)

      if (foundMachine && plan?.machineIds) {
        const hasAlreadyPlan = plan.machineIds.includes(foundMachine.machineId)
        const machineInPlan = plan?.machines?.[0]

        const hasDifferentGroup = machineInPlan && machineInPlan.groupId !== foundMachine.groupId

        if (hasDifferentGroup) {
          notification.error({
            message: 'Операция невыполнима',
            description: 'Машины должны быть из одной группы',
            role: 'alert'
          })

          return
        }

        if (hasAlreadyPlan) {
          notification.error({
            message: 'Операция невыполнима',
            description: 'Машина уже добавлена в маршрут',
            role: 'alert'
          })

          return
        }
        const changedPlan = {
          ...plan,
          machineIds: [...plan.machineIds, foundMachine.machineId]
        }

        await changePlan(changedPlan).unwrap()
        addMachineInPlan(foundMachine)

        successNotificationCreate('Машина добавлена в маршрут')
      }
    } catch (error) {
      console.error(error)
      errorNotificationCreate(error)
    }
  }

  const handleDragOver: DragEventHandler<HTMLDivElement> = (evt) => {
    evt.preventDefault()
  }

  return (
    <WorkSpace>
      <Loader isLoading={isUpdateLoading} />
      <div className={styles['inner-wrapper']}>
        <div className={styles['main-left-container']}>
          {isFetchingPlans ? <Spinner /> : plansTable && <TablePlans plans={plansTable} />}
        </div>
        <div
          className={cn(styles['main-right-container'], {
            [styles['main-right-container-on-drag-active']]: draggableMachineId
          })}
          onDrop={handleDragEnd}
          onDragOver={handleDragOver}
        >
          {draggableMachineId && (
            <div className={styles['drop-info']}>
              <span className={cn('material-symbols-outlined', styles['place-item'])}>place_item</span>
              <p className={styles['drop-info-text']}>Перенести машину сюда</p>
            </div>
          )}

          <PlansList />
        </div>
      </div>
    </WorkSpace>
  )
}

export default PlansMainContent
