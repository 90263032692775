import Spinner from '@/components/widgets/spinner/spinner'
import { ApiTag } from '@/constants/store/api.constants'
import useActions from '@/hooks/use-actions'
import { useAppDispatch } from '@/hooks/use-app-dispatch'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { baseApi } from '@/store/api/api-base-query'
import { useGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import type { FC } from 'react'
import { useEffect } from 'react'

import styles from './points-list.module.css'

import PointItem from './components/point-item'

const PointsList: FC = () => {
  const dispatch = useAppDispatch()
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  const { setSelectedPointId } = useActions()

  const { data: pointsResponse, isFetching } = useGetMeasuringPointsQuery(selectedMachineId, {
    skip: !selectedMachineId
  })

  const points = pointsResponse?.content

  useEffect(() => {
    dispatch(baseApi.util.invalidateTags([ApiTag.MeasurementsResult]))
  }, [dispatch, points])

  const handlePointSelect = (id: ISelectedMeasuringPoint['pointId']) => {
    if (id) {
      setSelectedPointId(id)
    }
  }

  if (isFetching) {
    return <Spinner />
  }

  return (
    selectedMachineId && (
      <div className={styles.container}>
        {points?.map((point, index) => (
          <PointItem onSelect={handlePointSelect} key={point.pointId} point={point} />
        ))}
        {points?.length === 0 && (
          <p style={{ textAlign: 'center', fontSize: '12px', margin: 0 }}>Точки измерения отсутствуют</p>
        )}
      </div>
    )
  )
}

export default PointsList
