import cn from 'classnames'
import type { DetailedHTMLProps, FC } from 'react'
import React from 'react'

import styles from './spinner.module.css'

const Spinner: FC<DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = ({
  className,
  ...props
}) => (
  <div {...props} className={cn(styles.container, className)}>
    <div className={styles.spinner} />
  </div>
)

export default Spinner
