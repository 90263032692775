import type { TTableSyncMechOuts } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/synch-mech-form.type'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import cn from 'classnames'
import type { FC } from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TOutTable = {
  tableData?: TTableSyncMechOuts[]
}

export const OutTable: FC<TOutTable> = ({ tableData }) => {
  const columns: ColumnsType<TTableSyncMechOuts> = [
    {
      dataIndex: 'label'
    },
    {
      dataIndex: 'freqOutName',
      title: 'Наименование частот'
    },
    {
      dataIndex: 'freqOutValue',
      title: 'Значение'
    }
  ]

  return (
    <Table
      className={cn(styles['drawer-kinematic-table'], styles['drawer-kinematic-table-width-height'])}
      columns={columns}
      dataSource={tableData}
      pagination={false}
    />
  )
}
