import {
  calculateAmplitudeHarmonic,
  calculateDecibel,
  calculateFrequencyHarmonic
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/chart-spectrum.util'
import { ColorLine } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/components/spectrum-overlay/spectrum-overlay.constant'
import type { IDataLines } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/data-lines.interface'
import type { ISpectrumDataForCursor } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/spectrum-data-for-cursor.interface'
import type { ISpectrumOverlayData } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/spectrum-overlay-data.interface'
import type { ITimeSliceData } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/interfaces/time-slice-data.interface'
import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { TMeasurementResult } from '@/store/api/measurements.api'
import { vibrationConverter } from '@/utils/unit/unit-converter.util'
import { notification } from 'antd'
import { maxBy } from 'lodash'

export const defineRangeBoundsForOverlayData = (
  index: number,
  data: ISpectrumOverlayData[],
  deltaFrequency: number,
  widthCursor: number
): [number, number] | null => {
  const lengthData = data.length - 1
  try {
    const sizeToBound = Math.ceil(widthCursor / deltaFrequency / 2)
    const prevIndex = index - sizeToBound
    const nextIndex = index + sizeToBound

    if (prevIndex <= 0 || nextIndex >= lengthData) {
      throw new Error(
        `The adjacent values in the array must be greater than 0 and less than the maximum ${lengthData} value of the array.`
      )
    }

    return [prevIndex, nextIndex]
  } catch (e) {
    console.error(e)
    notification.error({
      message: 'Ошибка выбранного значения',
      description: `Соседнее значение не может быть меньше или равно 0.\nСоседнее значение не может быть больше или равно максимальному значению в массиве (${lengthData}).`,
      duration: 10
    })

    return null
  }
}

export const transformDataForCursor = (spectrumOverlayData: ISpectrumOverlayData[]): ISpectrumDataForCursor[] => {
  // Формирование списка спектров
  const listOfSpectraIds = Object.keys(spectrumOverlayData[0])
    .map((key) => {
      if (key.startsWith('amplitude')) {
        return key.split('-')[1]
      }
    })
    .filter((element) => element)

  // Трансформация данных.
  // Возвращаем результат трансформации.
  return listOfSpectraIds.map((id) => {
    const amplitudeKey = `amplitude-${id}`
    const timestampKey = `timestamp-${id}`

    return {
      id: id as string,
      timestamp: spectrumOverlayData[0][timestampKey],
      data: spectrumOverlayData.map((item) => ({
        amplitude: item[amplitudeKey],
        frequency: item.frequency,
        index: item.index
      }))
    }
  }) as ISpectrumDataForCursor[]
}

export const getFilteredDataByMaxAmplitude = (
  range: [number, number] | null,
  spectrum: ISpectrumDataForCursor[]
): ISpectrumDataForCursor[] | null => {
  if (!range) {
    return null
  }
  return spectrum.map((itemSpectrum) => {
    const { data } = itemSpectrum
    const rangeBoundsData = data.slice(range[0], range[1] + 1)
    const maxByAmplitude = maxBy(rangeBoundsData, 'amplitude')

    const filteredArray = []

    if (maxByAmplitude?.index) {
      const prevDataByAmplitude = data[maxByAmplitude.index - 1]
      const nextDataByAmplitude = data[maxByAmplitude.index + 1]
      filteredArray.push(prevDataByAmplitude, maxByAmplitude, nextDataByAmplitude)
    }

    return {
      ...itemSpectrum,
      data: filteredArray
    } as ISpectrumDataForCursor
  })
}

export const getTimeSliceDataByCursor = (
  spectrumData: ISpectrumDataForCursor[] | null,
  colors: string[]
): ITimeSliceData[] | null => {
  if (!spectrumData) {
    return null
  }
  return spectrumData.map((spectrumItem, index) => {
    const payload = spectrumItem.data.reduce(
      (acc, dataItem) => ({
        frequencies: acc.frequencies ? [...acc.frequencies, dataItem.frequency] : [dataItem.frequency],
        amplitudes: acc.amplitudes ? [...acc.amplitudes, dataItem.amplitude] : [dataItem.amplitude]
      }),
      {} as { frequencies: number[]; amplitudes: number[] }
    )
    const { amplitudes, frequencies } = payload
    const harmonicAmplitude = calculateAmplitudeHarmonic(amplitudes)
    const harmonicFrequency = calculateFrequencyHarmonic(frequencies, amplitudes, harmonicAmplitude)
    return {
      resultId: `amplitude-${spectrumItem.id}`,
      frequency: harmonicFrequency,
      amplitude: harmonicAmplitude,
      timestamp: spectrumItem.timestamp,
      color: colors[index]
    }
  })
}

export const convertTimeSliceAmplitudeToDecibel = (data: ITimeSliceData[]) =>
  data.map((item) => ({ ...item, amplitude: calculateDecibel(item.amplitude) }))

export const findAmplitudeByIndex = (index: number, data: ISpectrumOverlayData[]): number =>
  data[index].frequency as number

const getMaxLengthByData = (data: TMeasurementResult[]): number =>
  Math.max(
    ...data.map((item) => {
      if (item.powerSpecterValue?.amplitudeSpectrum) {
        return item.powerSpecterValue.amplitudeSpectrum.length
      }
      return 0
    })
  )

export const transformDataForSpectrumOverlay = (
  data: TMeasurementResult[],
  dfValue: number,
  sourceUnit: EUnitType,
  targetUnit: EUnitType
): ISpectrumOverlayData[] => {
  // Уменьшаем количество точек на одну
  const countValues = getMaxLengthByData(data)

  return Array.from({ length: countValues }, (_, index) =>
    data.reduce(
      (acc, item) => {
        const frequency = dfValue * index
        // const amplitude = Math.sqrt(item.powerSpecterValue?.values[index] as number)
        const sourceSpecter = item.powerSpecterValue?.amplitudeSpectrum
        let amplitude = sourceSpecter?.[index] as number
        amplitude = vibrationConverter(amplitude, frequency, sourceUnit, targetUnit)

        acc[`amplitude-${item.resultId}`] = amplitude // Устанавливаем тег "amplitude" для поиска при преобразованиях
        acc[`timestamp-${item.resultId}`] = item.timestamp // Устанавливаем тег "timestamp" для сохранения всех данных по времени
        acc.frequency = frequency
        return acc
      },
      { index: index } as ISpectrumOverlayData
    )
  )
}

export const assignColorsToDataLines = (data: TMeasurementResult[]): IDataLines[] => {
  const colorList = Object.values(ColorLine)
  return data.map((item, index) => ({
    resultId: `amplitude-${item.resultId}`,
    color: colorList[index % colorList.length],
    timestamp: item.timestamp
  }))
}

export const convertAmplitudeByCb = (
  data: ISpectrumOverlayData[],
  cb: (value: number) => number
): ISpectrumOverlayData[] =>
  data.map((item) => {
    const convertedItem = { ...item }
    for (const key in convertedItem) {
      if (key.includes('amplitude')) {
        convertedItem[key] = cb(item[key] as number)
      }
    }
    return convertedItem
  })

export const extractTimeSliceData = (payload: ISpectrumOverlayData): ITimeSliceData[] => {
  let colorIndex = 0
  return Object.keys(payload).reduce<ITimeSliceData[]>((acc, key) => {
    if (key.startsWith('amplitude')) {
      const id = key.slice(10) // Извлечение идентификатора из ключа
      const amplitude = payload[key] as number
      const timestampKey = `timestamp-${id}`
      const timestamp = payload[timestampKey] as string

      if (timestamp) {
        const color = payload.colors[colorIndex]
        acc.push({
          amplitude: amplitude,
          timestamp: timestamp,
          color: color,
          resultId: `amplitude-${id}`,
          frequency: payload.frequency
        })
        colorIndex++
      }
    }
    return acc
  }, [])
}
