import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import ToolBar from '@/components/controlers/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import type { FC } from 'react'
import React from 'react'

const NotImplemented: FC = () => (
  <Container>
    <ToolBar />
    <LeftBar />
    <WorkSpace>
      <>
        <p
          style={{
            margin: '20px 0',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            fontSize: '18px',
            color: 'var(--border-color)'
          }}
        >
          404 | Страница не найдена
        </p>
      </>
    </WorkSpace>
  </Container>
)

export default NotImplemented
