import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import {
  InputMode,
  bearingFormFields
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bearing-form/bearing-form.constant'
import { CoefficientTable } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bearing-form/component/coefficient-table/coefficient-table'
import { SubmitButton } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/bearing-form/component/submit-button/add-bearing-button'
import type { IManufacturerOption } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/br-form/br-form.type'
import Popup from '@/components/widgets/popup/popup'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import { REAL_NUMBER } from '@/config/connection.config'
import { useAddBearingMutation } from '@/store/api/bearing.api'
import type { IBearing } from '@/types/bearing/bearing.type'
import { calculateKBPFI, calculateKBPFO, calculateKBSF, calculateKFTF, calculateKFTFo } from '@/utils/bearing'
import { convertDegToRad, formatFloat } from '@/utils/common.util'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { validateMinValue } from '@/utils/validation-rules'
import { Checkbox, Drawer, Form, Input, InputNumber, Select } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { useForm } from 'antd/lib/form/Form'
import { isNumber } from 'lodash'
import type { FC } from 'react'
import React, { useState } from 'react'

import localStyles from './bearing-form.module.css'
import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type BearingFormType = {
  manufacturesOptions?: IManufacturerOption[]
  onUpdateManufacturesOptions: (value: string) => void
  onClose: () => void
  isOpenedBearingDrawer: boolean
  onGetBearing: (id: string) => void
  onSetFieldsBearing: (manufacturer?: string, modelType?: string) => void
}

const BearingForm: FC<BearingFormType> = ({
  manufacturesOptions,
  onUpdateManufacturesOptions,
  onClose,
  isOpenedBearingDrawer,
  onGetBearing,
  onSetFieldsBearing
}) => {
  const [addBearing] = useAddBearingMutation()
  const [formAddBearing] = useForm<IBearing>()

  const [openAddPopup, setOpenAddPopup] = useState(false)
  const [modeInput, setModeInput] = useState<InputMode>(InputMode.Geometry)

  const [formAddManufacturer] = useForm()
  const isGeometryMode = modeInput === InputMode.Geometry
  const isCoefficientMode = modeInput === InputMode.Coefficient

  const handleClickAddPopup = (): void => {
    setOpenAddPopup(!openAddPopup)
  }

  const handleClosePopup = (): void => {
    setOpenAddPopup(!openAddPopup)
  }

  const handleFormAddManufacturer = () => {
    const value = formAddManufacturer.getFieldValue('add-bearing')
    onUpdateManufacturesOptions(value)
    setOpenAddPopup(!openAddPopup)
    formAddManufacturer.resetFields()
    formAddBearing.setFieldValue(bearingFormFields.manufacturer.name, value)
  }

  const handleChangeModeInput = (evt: CheckboxChangeEvent): void => {
    if (evt.target.checked) {
      setModeInput(InputMode.Coefficient)
      formAddBearing.resetFields([
        bearingFormFields.dtk.name,
        bearingFormFields.din.name,
        bearingFormFields.dout.name,
        bearingFormFields.n.name,
        bearingFormFields.alfa.name
      ])
      return
    }

    setModeInput(InputMode.Geometry)
  }

  const handleClickCalculationButton = async () => {
    const values = formAddBearing.getFieldsValue()

    try {
      await formAddBearing.validateFields([
        bearingFormFields.dtk.name,
        bearingFormFields.din.name,
        bearingFormFields.dout.name,
        bearingFormFields.n.name,
        bearingFormFields.alfa.name
      ])

      const { din, dout, dtk, alfa, n } = values
      if (!isNumber(din) || !isNumber(dout) || !isNumber(dtk) || !isNumber(alfa) || !isNumber(n)) {
        return null
      }

      const alfaRadians = convertDegToRad(alfa)
      const kFTF = calculateKFTF(dtk, din, dout, alfaRadians)
      const kFTFo = calculateKFTFo(kFTF)
      const kBSF = calculateKBSF(dtk, din, dout, alfaRadians)
      const kBPFO = calculateKBPFO(dtk, din, dout, alfaRadians, n)
      const kBPFI = calculateKBPFI(dtk, din, dout, alfaRadians, n)

      formAddBearing.setFieldsValue({
        [bearingFormFields.kftf.name]: formatFloat(kFTF, REAL_NUMBER),
        [bearingFormFields.kftfo.name]: formatFloat(kFTFo, REAL_NUMBER),
        [bearingFormFields.kbsf.name]: formatFloat(kBSF, REAL_NUMBER),
        [bearingFormFields.kbpfo.name]: formatFloat(kBPFO, REAL_NUMBER),
        [bearingFormFields.kbpfi.name]: formatFloat(kBPFI, REAL_NUMBER)
      })

      successNotificationCreate('Коэффициенты успешно расчитаны')
    } catch (e) {
      errorNotificationCreate(e, {
        message: 'Коэффициенты не расчитаны',
        description: `Форма для расчёта содержит ошибки`
      })
    }

    try {
      await formAddBearing.validateFields([
        bearingFormFields.kftf.name,
        bearingFormFields.kftfo.name,
        bearingFormFields.kbsf.name,
        bearingFormFields.kbpfo.name,
        bearingFormFields.kbpfi.name
      ])
    } catch (e) {
      console.error(e)
    }
  }

  const handleFinishAddBearing = async (): Promise<void> => {
    const bearingFields = formAddBearing.getFieldsValue()
    try {
      await formAddBearing.validateFields([
        bearingFormFields.modelType.name,
        bearingFormFields.manufacturer.name,
        bearingFormFields.kftf.name,
        bearingFormFields.kftfo.name,
        bearingFormFields.kbsf.name,
        bearingFormFields.kbpfo.name,
        bearingFormFields.kbpfi.name
      ])

      const { id } = await addBearing(bearingFields).unwrap()

      onClose()
      formAddBearing.resetFields()
      successNotificationCreate('Подшипник успешно добавлен')
      onGetBearing(id)
      onSetFieldsBearing(bearingFields.manufacturer, bearingFields.modelType)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Drawer
      title='Добавление подшипника'
      closable={true}
      width={650}
      onClose={onClose}
      open={isOpenedBearingDrawer}
      extra={<SubmitButton formAddBearing={formAddBearing} onFinishAddBearing={handleFinishAddBearing} />}
    >
      <Form form={formAddBearing} className={localStyles['container']}>
        <h2 className={styles['drawer-kinematic-subtitle']}>Описание модели</h2>
        <Form.Item
          label={bearingFormFields.modelType.label}
          name={bearingFormFields.modelType.name}
          className={styles['drawer-kinematic-input-item']}
          rules={[{ required: true, message: 'Необходимо указать тип подшипника' }]}
        >
          <Input
            className={styles['drawer-kinematic-input-number']}
            placeholder={bearingFormFields.modelType.placeholder}
          />
        </Form.Item>
        <Form.Item
          label={bearingFormFields.manufacturer.label}
          name={bearingFormFields.manufacturer.name}
          className={styles['drawer-kinematic-input-item']}
          rules={[{ required: true, message: 'Необходимо выбрать производителя' }]}
        >
          <Select
            className={styles['drawer-kinematic-input-number']}
            placeholder={bearingFormFields.manufacturer.placeholder}
            options={manufacturesOptions}
          />
        </Form.Item>
        <div className={styles['drawer-kinematic-button-container']}>
          <ButtonPrimary
            className={styles['drawer-kinematic-button-add']}
            title='Добавить производителя'
            htmlType='button'
            onClick={handleClickAddPopup}
            theme='dark'
          />
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Геометрические размеры подшипников</h2>
        <Form.Item label='Нет данных' name='input-mode' className={styles['drawer-kinematic-checkbox']}>
          <Checkbox onChange={handleChangeModeInput} checked={isCoefficientMode} />
        </Form.Item>
        <Form.Item
          label={bearingFormFields.din.label}
          name={bearingFormFields.din.name}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: isGeometryMode,
              message: bearingFormFields.din.errorMessage
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            className={styles['drawer-kinematic-input-number']}
            placeholder={isGeometryMode ? bearingFormFields.din.placeholder : undefined}
            disabled={isCoefficientMode}
            decimalSeparator={','}
            required={isGeometryMode}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={bearingFormFields.dout.label}
          name={bearingFormFields.dout.name}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: isGeometryMode,
              message: bearingFormFields.dout.errorMessage
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            className={styles['drawer-kinematic-input-number']}
            placeholder={isGeometryMode ? bearingFormFields.dout.placeholder : undefined}
            disabled={isCoefficientMode}
            decimalSeparator={','}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={bearingFormFields.dtk.label}
          name={bearingFormFields.dtk.name}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: isGeometryMode,
              message: bearingFormFields.dtk.errorMessage
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            className={styles['drawer-kinematic-input-number']}
            placeholder={isGeometryMode ? bearingFormFields.dtk.placeholder : undefined}
            disabled={isCoefficientMode}
            decimalSeparator={','}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={bearingFormFields.n.label}
          name={bearingFormFields.n.name}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: isGeometryMode,
              message: bearingFormFields.n.errorMessage
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            className={styles['drawer-kinematic-input-number']}
            placeholder={isGeometryMode ? bearingFormFields.n.placeholder : undefined}
            disabled={isCoefficientMode}
            decimalSeparator={','}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label={bearingFormFields.alfa.label}
          name={bearingFormFields.alfa.name}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: isGeometryMode,
              message: bearingFormFields.alfa.errorMessage
            }
          ]}
        >
          <InputNumber
            className={styles['drawer-kinematic-input-number']}
            placeholder={isGeometryMode ? bearingFormFields.alfa.placeholder : undefined}
            disabled={isCoefficientMode}
            decimalSeparator={','}
            min={0}
            max={360}
          />
        </Form.Item>
        <div className={styles['drawer-kinematic-button-container']}>
          <ButtonPrimary
            title='Расчитать'
            htmlType='button'
            theme='dark'
            onClick={handleClickCalculationButton}
            className={styles['drawer-kinematic-button-add']}
            disabled={isCoefficientMode}
          />
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Коэффициенты для расчёта характерных частот подшипника</h2>
        <CoefficientTable editable={isCoefficientMode} formAddBearing={formAddBearing} />
      </Form>
      <Popup isOpen={openAddPopup} title='Добавить производителя подшипника' onCancel={handleClosePopup}>
        <Form onFinish={handleFormAddManufacturer} form={formAddManufacturer}>
          <Form.Item
            label='Наименование производителя'
            name='add-bearing'
            rules={[
              {
                required: true,
                message: 'Не указано наименование производителя'
              }
            ]}
          >
            <Input className={localStyles['popup-input']} />
          </Form.Item>
          <ButtonsWrapper>
            <ButtonPrimary title={'Отмена'} htmlType={'reset'} onClick={handleClosePopup} />
            <ButtonPrimary title={'Добавить'} htmlType={'submit'} />
          </ButtonsWrapper>
        </Form>
      </Popup>
    </Drawer>
  )
}

export default BearingForm
