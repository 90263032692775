import type { EDetectorType } from '@/enums/measurment/common-level/detector-type.enum'
import type { EWindowType } from '@/enums/measurment/program-specter/window-type.enum'

export const mapWindowsType: { [key in EWindowType]: string } = {
  HAMMING: 'Хэмминга',
  HANN: 'Ханна',
  BLACKMANHARRIS: 'Блэкмана-Харриса',
  FLATTOP: 'С плоской вершиной',
  TRIANGULAR: 'Треугольное'
} as const

export const windowsTypeOptions = Object.entries(mapWindowsType).map(([key, label]) => ({
  value: key as EDetectorType,
  label
}))
