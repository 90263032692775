import cn from 'classnames'
import type { FC, ReactNode } from 'react'

import styles from './text-static.module.css'

type TValue = string | ReactNode | number

interface IProps {
  label: string
  value?: TValue
}

const TextStatic: FC<IProps> = ({ label, value }) => {
  const locale = navigator.language || 'en-US'

  const formatValue = (formatedValue: TValue) => {
    if (typeof formatedValue === 'number') {
      if (locale.startsWith('ru') || locale.startsWith('fr') || locale.startsWith('de')) {
        return formatedValue?.toString().replace('.', ',')
      }

      return formatedValue
    }
    return formatedValue ? formatedValue : 'Не определено'
  }

  return (
    <div className={cn(styles['text-static'])}>
      <span className={styles['label']} title={label}>
        {label}
      </span>
      <span className={styles['value']}>{formatValue(value)}</span>
    </div>
  )
}
export default TextStatic
