import { REAL_NUMBER } from '@/config/connection.config'
import { NO_DATA_AVAILABLE } from '@/constants/core/common.constant'
import type { IOut } from '@/types/kinematic/kinematic.type'
import { formatFloat, isNumber } from '@/utils/common.util'
import _, { isString } from 'lodash'

export const handleValue = (value: unknown) => (value ? String(value) : NO_DATA_AVAILABLE)
export const handleName = (name: unknown): string => (isString(name) ? name : NO_DATA_AVAILABLE)

export const handleValueWithZero = (value: unknown): string => (_.isNumber(value) ? String(value) : NO_DATA_AVAILABLE)

export const calculateValue = (value?: number | null, multiplier?: number | null) =>
  isNumber(value) && isNumber(multiplier) ? value * multiplier : null

export const formatOutValues = <T extends Record<keyof T, IOut>>(outs: T) =>
  Object.keys(outs).reduce((acc, key) => {
    const { freqOutValue, freqOutCoefMachine, freqOutCoefKb, freqOutName } = outs[key as keyof T]

    acc[key as keyof T] = {
      freqOutName: freqOutName,
      freqOutValue: freqOutValue ? formatFloat(freqOutValue, REAL_NUMBER) : null,
      freqOutCoefKb: freqOutCoefKb ? formatFloat(freqOutCoefKb, REAL_NUMBER) : null,
      freqOutCoefMachine: freqOutCoefMachine ? formatFloat(freqOutCoefMachine, REAL_NUMBER) : null
    }

    return acc
  }, {} as Record<keyof T, IOut>)
