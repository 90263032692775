import type {
  IOutDataAd,
  IOuts
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form.type'
import { formatDataForTable } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/ad-form/ad-form.util'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import cn from 'classnames'
import type { FC } from 'react'
import { useEffect, useState } from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TOutTable = {
  outs: IOuts | null
}

export const OutTable: FC<TOutTable> = ({ outs }) => {
  const [outData, setOutData] = useState<IOutDataAd[] | undefined>(undefined)
  useEffect(() => {
    if (!outs) {
      setOutData(undefined)
      return
    }

    const formattedDataTable = formatDataForTable(outs)

    setOutData(formattedDataTable)
  }, [outs])

  const columns: ColumnsType<IOutDataAd> = [
    {
      dataIndex: 'label'
    },
    {
      dataIndex: 'name',
      title: 'Наименование частот'
    },
    {
      dataIndex: 'value',
      title: 'Значение'
    }
  ]

  return (
    <Table
      className={cn(styles['drawer-kinematic-table'], styles['drawer-kinematic-table-width-height'])}
      columns={columns}
      dataSource={outData}
      pagination={false}
    />
  )
}
