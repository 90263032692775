import Menu from '@/components/widgets/menu/menu'
import { menuLinks } from '@/constants/navigation/links.constant'
import { configurationSlice } from '@/store/reducers/configuration.slice'
import React, { useState } from 'react'

import styles from './header.module.css'

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const { clearSelectedEquipment } = configurationSlice.actions

  const onClickMenuButton = (): void => {
    clearSelectedEquipment()
    setIsMenuOpen((prevState) => !prevState)
  }

  return (
    <div className={styles.header}>
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} links={menuLinks} />

      <button className={styles.menu} onClick={onClickMenuButton}>
        <span className={`material-symbols-sharp ${styles['menu-button']}`}>menu</span>
      </button>
    </div>
  )
}

export default Header
