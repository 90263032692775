import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IResultMarkers } from '@/types/express-analysis/marker/marker.type'
import { formatNumber } from '@/utils/common.util'
import type { TableProps } from 'antd'
import { Table } from 'antd'
import classNames from 'classnames'
import { isNumber } from 'lodash'
import type { FC, LegacyRef } from 'react'

import styles from '../../../../express-sidebar.module.css'

interface ITableData {
  key: string
  title: string
  f: string
  a: string
}

const columns: TableProps<ITableData>['columns'] = [
  {
    title: 'N',
    dataIndex: 'title',
    key: 'title'
  },
  {
    title: 'F',
    dataIndex: 'f',
    key: 'f'
  },
  {
    title: 'A',
    dataIndex: 'a',
    key: 'a'
  }
]

const getTableData = (result: IResultMarkers): ITableData[] => {
  const chartsData: ITableData[] = result.charts
    .filter((chart): chart is NonNullable<typeof chart> => chart !== null)
    .map((chart, index) => ({
      key: `${index}`,
      title: `${index + 1}`,
      f: chart.frequency.toPrecision(5),
      a: formatNumber(chart.amplitude)
    }))

  const additionalData: ITableData[] = [
    { frequency: result.frequencyD12, amplitude: result.amplitudeD12, title: 'D12' },
    { frequency: result.frequencyD13, amplitude: result.amplitudeD13, title: 'D13' },
    { frequency: result.frequencyD23, amplitude: result.amplitudeD23, title: 'D23' }
  ]
    .map(({ frequency, amplitude, title }) => {
      if (isNumber(frequency) && isNumber(amplitude)) {
        return {
          key: `${title}`,
          title,
          f: frequency.toPrecision(5),
          a: formatNumber(amplitude)
        }
      }
      return null
    })
    .filter((item): item is NonNullable<typeof item> => item !== null)

  return [...chartsData, ...additionalData]
}

interface IExpressMarkerListItem {
  onClickExpressMarkerButton: () => void
  refMarkerListItem: LegacyRef<HTMLLIElement> | null
  result: IResultMarkers
  currentIndex: number
}

const ExpressMarkerListItem: FC<IExpressMarkerListItem> = ({
  onClickExpressMarkerButton,
  refMarkerListItem,
  result,
  currentIndex
}) => {
  const tableData = getTableData(result)
  const { selectedResultIndex } = useTypedSelector((state) => state.chartsUiReducer)
  return (
    <li ref={refMarkerListItem} className={styles['list-item']}>
      <button onClick={onClickExpressMarkerButton} type={'button'} className={styles.button}>
        <h3
          className={classNames(styles['list-header'], styles['list-header-bg'], {
            [styles['list-header-active']]: selectedResultIndex === currentIndex
          })}
        >
          {result.title}
        </h3>

        <Table className={styles['custom-table']} pagination={false} dataSource={tableData} columns={columns} />
      </button>
    </li>
  )
}

export default ExpressMarkerListItem
