import type { TFormSimpleBlock } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/simple-kinematic/simple-kinematic.type'
import { KbParam } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import type { FormInstance } from 'antd'
import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import type { FC } from 'react'
import React from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TProps = {
  form: FormInstance<TFormSimpleBlock>
  image: string
}

const SimpleForm: FC<TProps> = ({ form, image }) => (
  <Form form={form}>
    <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
    <div className={styles['drawer-kinematic-header']}>
      <Form.Item name={KbParam.Description} className={styles['drawer-kinematic-text-area']}>
        <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} />
      </Form.Item>
      <div className={styles['drawer-kinematic-image-wrapper']}>
        <img className={styles['drawer-kinematic-image-vertical']} src={image} alt='' />
      </div>
    </div>
  </Form>
)

export default SimpleForm
