import { findParentKeys } from '@/components/widgets/equipment-tree/equipment-tree.service'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import useActions from '@/hooks/use-actions'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import type { Key } from 'react'
import { useCallback } from 'react'

export const useTreeSelectionHandler = (treeMenu: ITreeNode[]) => {
  const {
    setSelectedTreeItems,
    setEquipmentView,
    setSelectedGroupId,
    setSelectedMachineId,
    setSelectedPointId,
    setSelectedMeasurementId,
    resetSelectedForMachine,
    setExpendedEquipments
  } = useActions()

  const handleSelect = useCallback(
    (_: Key[] | null, info: { node: ITreeNode }) => {
      const { group, key } = info.node
      const selectedId = key as string

      setSelectedTreeItems([selectedId])
      const parentsKeys = findParentKeys(selectedId, treeMenu) as string[]

      switch (group) {
        case EEquipmentTree.ROOT:
          setEquipmentView({
            content: null,
            mode: null
          })
          setSelectedGroupId(null)
          resetSelectedForMachine()
          setExpendedEquipments([])
          break

        case EEquipmentTree.GROUPS:
          setEquipmentView({
            content: EEquipmentContent.GROUP,
            mode: EViewMode.EDITING
          })
          setSelectedGroupId(selectedId)
          setExpendedEquipments(parentsKeys)
          setSelectedMachineId(null)
          setSelectedPointId(null)
          setSelectedMeasurementId(null)
          break

        case EEquipmentTree.MACHINES:
          setEquipmentView({
            content: EEquipmentContent.MACHINE,
            mode: EViewMode.EDITING
          })
          setSelectedMachineId(selectedId)
          setExpendedEquipments(parentsKeys)
          setSelectedPointId(null)
          setSelectedMeasurementId(null)
          break

        case EEquipmentTree.POINTS:
          setEquipmentView({
            content: EEquipmentContent.POINT,
            mode: EViewMode.EDITING
          })
          setSelectedMachineId(parentsKeys[parentsKeys.length - 1] as string)
          setExpendedEquipments(parentsKeys)
          setSelectedPointId(selectedId)
          setSelectedMeasurementId(null)
          break

        case EEquipmentTree.MEASUREMENTS:
          setEquipmentView({
            content: EEquipmentContent.MEASUREMENT,
            mode: EViewMode.EDITING
          })
          setSelectedMachineId(parentsKeys[parentsKeys.length - 2] as string)
          setExpendedEquipments(parentsKeys)
          setSelectedPointId(parentsKeys[parentsKeys.length - 1] as string)
          setSelectedMeasurementId(selectedId)
          break

        default:
          console.warn(`Unknown group type: ${group}`)
          break
      }
    },
    [
      setSelectedTreeItems,
      treeMenu,
      setEquipmentView,
      resetSelectedForMachine,
      setExpendedEquipments,
      setSelectedGroupId,
      setSelectedMachineId,
      setSelectedPointId,
      setSelectedMeasurementId
    ]
  )

  return { handleSelect }
}
