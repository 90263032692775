import { KbParam } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import { Form } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import type { FC } from 'react'
import React from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TProps = {
  image: string
}

const TnForm: FC<TProps> = ({ image }) => (
  <>
    <Form className={styles['drawer-kinematic-form']}>
      <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
      <div className={styles['drawer-kinematic-header']}>
        <Form.Item name={KbParam.Description} className={styles['drawer-kinematic-text-area']}>
          <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} />
        </Form.Item>
        <div className={styles['drawer-kinematic-image-wrapper']}>
          <img className={styles['drawer-kinematic-image']} src={image} alt='' />
        </div>
      </div>
      <h3 style={{ textAlign: 'center' }}>Не реализовано!</h3>
    </Form>
  </>
)

export default TnForm
