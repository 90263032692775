import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import Control from '@/components/icons/control/control'
import { EKinematicViewMode } from '@/enums/kinematic/kinematic-view-mode.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import cn from 'classnames'
import type { FC, HTMLAttributes } from 'react'

import styles from './mode-panel.module.css'

const ModePanel: FC<HTMLAttributes<HTMLDivElement>> = ({ ...props }) => {
  const { viewMode } = useTypedSelector((state) => state.kinematicUiReducer)
  const { setViewMode } = useActions()
  const handleStatusModeClick = () => {
    setViewMode(EKinematicViewMode.Status)
  }

  const handleDefaultModeClick = () => {
    setViewMode(EKinematicViewMode.Information)
  }

  return (
    <div {...props} className={cn(styles.modelPanel, props.className)}>
      <ButtonToolbar
        title='Режим просмотра: состояние'
        placement='bottom'
        className={cn({
          [styles['button-active']]: viewMode === EKinematicViewMode.Status
        })}
        onClick={handleStatusModeClick}
      >
        <Control />
      </ButtonToolbar>
      <ButtonToolbar
        title='Режим просмотра: информация'
        placement='bottom'
        className={cn({
          [styles['button-active']]: viewMode === EKinematicViewMode.Information
        })}
        icon='info'
        onClick={handleDefaultModeClick}
      />
    </div>
  )
}

export default ModePanel
