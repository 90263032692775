import ButtonToolbar from '@/components/controlers/button-toolbar/button-toolbar'
import { EChartsViewMode } from '@/enums/charts/chart-view-mode.enum'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { Tooltip } from 'antd'
import type { FC } from 'react'

import styles from './view-mode.module.css'

const ViewMode: FC = () => {
  const { chartsViewMode } = useTypedSelector((state) => state.chartsUiReducer)
  const { setChartsViewMode } = useActions()
  const isViewModeCascade = chartsViewMode === EChartsViewMode.Cascade
  const isViewModeOverlay = chartsViewMode === EChartsViewMode.Overlay

  const setModeViewCascade = () => {
    setChartsViewMode(EChartsViewMode.Cascade)
  }

  const setModeViewOverlay = () => {
    setChartsViewMode(EChartsViewMode.Overlay)
  }

  return (
    <div className={styles['view-mode']}>
      <Tooltip title='Режима просмотра: каскад' placement='bottom'>
        <ButtonToolbar icon={'clarify'} activated={isViewModeCascade} onClick={setModeViewCascade} />
      </Tooltip>
      <Tooltip title='Режима просмотра: наложение' placement='bottom'>
        <ButtonToolbar icon={'stacked_line_chart'} activated={isViewModeOverlay} onClick={setModeViewOverlay} />
      </Tooltip>
    </div>
  )
}

export default ViewMode
