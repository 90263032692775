import SkeletOnInput from '@/components/controlers/skeletons/skeleton-input/skelet-on-Input'
import FieldWrapper from '@/components/wrappers/field-wrapper/field-wrapper'
import type { FormItemProps, InputProps, InputRef } from 'antd'
import { Form, Input } from 'antd'
import cn from 'classnames'
import type { FC, Ref } from 'react'

import styles from './form-input.module.css'

type FormInputProps = {
  labelContent: string
  isLoading?: boolean
  defaultPlaceholder?: boolean
  inputRef?: Ref<InputRef>
  layout?: 'vertical' | 'horizontal'
  tooltipKey?: string
}

type TProps = FormInputProps & InputProps & FormItemProps

const FormInput: FC<TProps> = ({
  labelContent,
  placeholder,
  defaultPlaceholder,
  isLoading,
  name,
  initialValue,
  inputRef,
  disabled,
  required = false,
  layout = 'horizontal',
  rules,
  tooltipKey,
  ...props
}) =>
  !isLoading ? (
    <FieldWrapper tooltipKey={tooltipKey} layout={layout}>
      <Form.Item
        className={cn(styles.item, {
          [styles['item-vertical']]: layout === 'vertical'
        })}
        label={<span style={{ fontWeight: 'bold' }}>{labelContent}</span>}
        rules={[
          ...(rules ?? []),
          {
            required: required,
            message: `Поле «${labelContent}» обязательно`
          }
        ]}
        name={name}
        initialValue={initialValue}
      >
        <Input
          {...props}
          size='middle'
          className={styles.input}
          name={name}
          placeholder={defaultPlaceholder ? `Заполните поле «${labelContent}»` : placeholder ?? ''}
          disabled={disabled}
          ref={inputRef}
        />
      </Form.Item>
    </FieldWrapper>
  ) : (
    <SkeletOnInput active={isLoading} />
  )
export default FormInput
