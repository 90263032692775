import ResizableSidebar from '@/app/machine-condition/components/results/components/results-sidebar/components/resizable-sidebar/resizable-sidebar'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import type { ResizeCallback } from 're-resizable'
import { Resizable } from 're-resizable'
import type { FC } from 'react'
import { useState } from 'react'

import styles from './results-sidebar.module.css'

import MeasurementsList from './components/measurements-list/measurements-list'
import PointsList from './components/points-list/points-list'

const enum Restrictions {
  MinWidth = 220,
  MaxWidth = 450
}

const ResultsSidebar: FC = () => {
  const [width, setWidth] = useState<number | string>('auto')

  const resizeStopHandler: ResizeCallback = (e, direction, ref) => {
    const currentWidth = ref.offsetWidth
    setWidth(currentWidth)
  }

  return (
    <Resizable
      enable={{ right: true }}
      size={{ width: width, height: '100%' }}
      onResizeStop={resizeStopHandler}
      handleClasses={{
        right: styles['resize-right']
      }}
      minWidth={Restrictions.MinWidth}
      maxWidth={Restrictions.MaxWidth}
      className={styles['wrapper-sidebar']}
    >
      <ResizableSidebar className={styles['points-container']}>
        <PanelTitle className={styles['points-title']} size='S'>
          Точки измерения
        </PanelTitle>
        <div className={styles['points-content']}>
          <PointsList />
        </div>
      </ResizableSidebar>

      <div className={styles['points-container']}>
        <PanelTitle className={styles['points-title']} size='S'>
          Виды измерения
        </PanelTitle>
        <div className={styles['points-content']}>
          <MeasurementsList />
        </div>
      </div>
    </Resizable>
  )
}

export default ResultsSidebar
