import FormInformation from '@/components/controlers/form-controls/form-information/form-information'
import MachinesList from '@/components/widgets/machines-list/machines-list'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IKinematicScheme } from '@/types/kinematic/kinematic.type'
import type { FC } from 'react'

import styles from './kinematic-left-bar.module.css'

type KinematicLeftBarType = {
  kinematicScheme?: IKinematicScheme
  isLoadingKinematicScheme?: boolean
}

const KinematicLeftBar: FC<KinematicLeftBarType> = ({ kinematicScheme }) => {
  const { selectedMachineId } = useTypedSelector((state) => state.globalReducer)

  return (
    <>
      <MachinesList />
      {selectedMachineId &&
        (kinematicScheme ? (
          <FormInformation
            label='Кинематическая схема'
            value={kinematicScheme.name}
            layout='vertical'
            className={styles['form-information']}
            lastElement={true}
          />
        ) : (
          <p className={styles['text-information']}>Кинематическая схема не добавлена</p>
        ))}
    </>
  )
}

export default KinematicLeftBar
