import { useGetCurrentKinematicQuery, useGetKinematicsQuery } from '@/store/api/kinematic.api'

export const useGetCurrentKinematicScheme = (machineId: string | null) => {
  const { data: kinematicsResponse } = useGetKinematicsQuery(machineId, {
    skip: !machineId
  })

  const kinematicId = kinematicsResponse?.content[0]?.kinematicSchemeId || null
  const result = useGetCurrentKinematicQuery(kinematicId, {
    skip: !kinematicId
  })

  if (!kinematicId) {
    result.data = undefined
  }
  return result
}
