import {
  SynchMechOut,
  SynchMechParamName
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/sync-form.constant'
import type {
  IFormSynchMech,
  ISynchMechOut,
  TTableSyncMechOuts
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/synch-mech-form/synch-mech-form.type'
import {
  KbParam,
  TypeSource
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import {
  calculateValue,
  formatOutValues,
  handleName,
  handleValue
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.util'
import type { IIn, IKinematicElement, IOut } from '@/types/kinematic/kinematic.type'
import { EKinematicBlockType } from '@/types/kinematic/kinematic.type'
import { isNumber } from '@/utils/common.util'

export const parseServerData = (kinematicElement: IKinematicElement) => {
  const { description, ins, kinematicBlockSD, kinematicBlockSG, outs } = kinematicElement

  interface IResult {
    fields: IFormSynchMech
    ins: IIn[] | null
    outs: ISynchMechOut | null
  }

  const result: IResult = {
    fields: {
      [KbParam.Description]: description,
      [KbParam.FreqCoefForIn]: ins?.[0]?.freqCoefForIn ?? 1,
      [KbParam.TypeSource]: TypeSource.Missing,
      [KbParam.KinematicBlockSourceId]: null,
      [KbParam.FreqOutSource]: null,
      [SynchMechParamName.Ns]: null,
      [SynchMechParamName.Nr]: null,
      [SynchMechParamName.Fu]: null,
      [SynchMechParamName.N2p]: null
    },
    ins: null,
    outs: null
  }

  if (kinematicBlockSD) {
    const { fu, n2p, nr, ns } = kinematicBlockSD
    Object.assign(result.fields, {
      [SynchMechParamName.Fu]: fu,
      [SynchMechParamName.N2p]: n2p,
      [SynchMechParamName.Nr]: nr,
      [SynchMechParamName.Ns]: ns
    })
  }

  if (kinematicBlockSG) {
    const { n2p, fu, nr, ns } = kinematicBlockSG
    Object.assign(result.fields, {
      [SynchMechParamName.Fu]: fu,
      [SynchMechParamName.N2p]: n2p,
      [SynchMechParamName.Nr]: nr,
      [SynchMechParamName.Ns]: ns
    })
  }

  if (outs) {
    result.outs = Object.values(SynchMechOut).reduce((acc, value, index) => {
      acc[value] = {
        freqOutName: outs[index].freqOutName,
        freqOutCoefKb: outs[index].freqOutCoefKb,
        freqOutCoefMachine: outs[index].freqOutCoefMachine,
        freqOutValue: outs[index].freqOutValue
      }

      return acc
    }, {} as Record<SynchMechOut, IOut>)
  }
  return result
}

export const getCalculatedResults = (
  fieldsValue: IFormSynchMech,
  kinematicBlockName: string,
  kienmaticType: EKinematicBlockType
) => {
  const { fu, n2p, ns, nr } = fieldsValue

  const resultFreqInValue = isNumber(fu) && isNumber(n2p) ? fu / n2p : null
  const isSdType = kienmaticType === EKinematicBlockType.SD
  const isSgType = kienmaticType === EKinematicBlockType.SG

  const result: ISynchMechOut = {
    [SynchMechOut.Fu]: {
      freqOutName: `${kinematicBlockName}_fU`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: fu
    },
    [SynchMechOut.Fr]: {
      freqOutName: `${kinematicBlockName}_Frsd`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: resultFreqInValue
    },
    [SynchMechOut.Fe]: {
      freqOutName: `${kinematicBlockName}_Fe`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(fu, 2)
    },
    [SynchMechOut.Fnl]: {
      freqOutName: `${kinematicBlockName}_Fnl`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(fu, 6)
    },
    [SynchMechOut.Fsz]: {
      freqOutName: `${kinematicBlockName}${(isSdType && '_Fsdz') || (isSgType && '_Fsgz')}`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(resultFreqInValue, ns)
    },
    [SynchMechOut.Fsy]: {
      freqOutName: `${kinematicBlockName}${(isSdType && '_Fsdy') || (isSgType && '_Fsgy')}`,
      freqOutCoefKb: 1,
      freqOutCoefMachine: 1,
      freqOutValue: calculateValue(resultFreqInValue, nr)
    }
  }

  return formatOutValues<ISynchMechOut>(result)
}

export const parseDataForChange = (
  kinematicElement: IKinematicElement,
  fieldValues: IFormSynchMech,
  outsData: ISynchMechOut
): IKinematicElement => {
  const typeBlock: { [key in string]: string } = {
    [EKinematicBlockType.SD]: 'kinematicBlockSD',
    [EKinematicBlockType.SG]: 'kinematicBlockSG'
  }

  return {
    ...kinematicElement,
    description: fieldValues.description,
    [typeBlock[kinematicElement.kinematicBlockType]]: {
      n2p: fieldValues.n2p,
      fu: fieldValues.fu,
      nr: fieldValues.nr,
      ns: fieldValues.ns
    },
    outs: Object.values(outsData)
  }
}

export const formatDataForTable = (outs: ISynchMechOut): TTableSyncMechOuts[] => {
  const { fu, fr, fnl, fe, fsz, fsy } = outs
  const data: Omit<TTableSyncMechOuts, 'key'>[] = [
    {
      label: 'Частота питающей сети',
      freqOutName: handleName(fu.freqOutName),
      freqOutValue: handleValue(fu.freqOutValue)
    },
    {
      label: 'Частота вращения',
      freqOutName: handleName(fr.freqOutName),
      freqOutValue: handleValue(fr.freqOutValue)
    },
    {
      label: 'Частота ЭМ взаимодействия (магнитострикционная, ЭМС)',
      freqOutName: handleName(fe.freqOutName),
      freqOutValue: handleValue(fe.freqOutValue)
    },
    {
      label: 'Частота искажения питания',
      freqOutName: handleName(fnl.freqOutName),
      freqOutValue: handleValue(fnl.freqOutValue)
    },
    {
      label: 'Зубцовая частота явнополюсного', // TODO: В будущем появится API, который позволит получить эту информацию. Будет изменена реализация.
      freqOutName: handleName(fsz.freqOutName),
      freqOutValue: handleValue(fsz.freqOutValue)
    },
    {
      label: 'Зубцовая частота неявнополюсного СД',
      freqOutName: handleName(fsy.freqOutName),
      freqOutValue: handleValue(fsy.freqOutValue)
    }
  ]

  return data.map<TTableSyncMechOuts>((item, key) => ({ ...item, key }))
}
