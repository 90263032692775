import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetInfoQuery } from '@/store/api/info.api'
import type { FC } from 'react'
import React from 'react'

const AboutMain: FC = () => {
  const { version } = useTypedSelector((state) => state.serverSettingsReducer)

  const { data: dataInfo } = useGetInfoQuery()

  return (
    <WorkSpace>
      <p>Версия фронта: {version}</p>
      <p>Версия бэкенда: {dataInfo?.version}</p>
    </WorkSpace>
  )
}

export default AboutMain
