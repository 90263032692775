import { useGetMnemonicElement } from '@/hooks/api/use-get-mnemonic-element'
import { useChangeMnemonicElementMutation } from '@/store/api/mnemo.api'
import type { TMnemoSchemeItem } from '@/types/mnemonic.type'
import { errorNotificationCreate } from '@/utils/notification-creators'
import type { MenuProps } from 'antd'
import { Button, Checkbox, Dropdown } from 'antd'
import type { FC } from 'react'

import styles from '@/components/widgets/mnemonic/indicator-settings/indicator-settings.module.css'

import TableCell from './table-cell'

const BorderItem: FC = () => {
  const { data: selectedMnemoItem } = useGetMnemonicElement()
  const [changeMnemonicElement] = useChangeMnemonicElementMutation()

  const borders: MenuProps['items'] = [
    {
      label: (
        <>
          <span
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <p
              style={{
                fontSize: 14,
                margin: 0
              }}
            >
              Есть/Нет
            </p>

            <Checkbox checked={!!selectedMnemoItem?.staticLabelElement?.borderStyle?.size} />
          </span>
        </>
      ),
      key: 'state'
    },
    {
      disabled: !selectedMnemoItem?.staticLabelElement?.borderStyle?.size,
      label: 'Размер границы',
      key: 'size',
      children: [
        {
          label: (
            <div
              style={{
                height: 15,
                width: 15,
                border: `1px solid #c1c1c1`
              }}
            />
          ),
          key: '1'
        },
        {
          label: (
            <div
              style={{
                height: 15,
                width: 15,
                border: `1px solid #c1c1c1`
              }}
            />
          ),
          key: '2'
        },
        {
          label: (
            <div
              style={{
                height: 15,
                width: 15,
                border: `3px solid #c1c1c1`
              }}
            />
          ),
          key: '3'
        },
        {
          label: (
            <div
              style={{
                height: 15,
                width: 15,
                border: `4px solid #c1c1c1`
              }}
            />
          ),
          key: '4'
        }
      ]
    },
    {
      disabled: !selectedMnemoItem?.staticLabelElement?.borderStyle?.size,

      label: 'Цвет границы',
      key: 'color',
      children: [
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'transparent' }} />,
          key: '#c1c1c1'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'red' }} />,
          key: 'red'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'green' }} />,
          key: 'green'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'yellow' }} />,
          key: 'yellow'
        },
        {
          label: <div className={`${styles.fill}`} style={{ backgroundColor: 'blue' }} />,
          key: 'blue'
        }
      ]
    }
  ]

  const updateMnemonicElement = async (mnemonicElement: TMnemoSchemeItem) => {
    if (mnemonicElement?.mnemoElementId) {
      try {
        const data = await changeMnemonicElement(mnemonicElement).unwrap()

        if (data !== null) throw new Error('Ошибка соединения')
      } catch (error) {
        errorNotificationCreate(error)
      }
    }
  }

  const onClickBorder = ({ key, keyPath }: { key: string; keyPath: string[] }) => {
    const parent = keyPath[keyPath.length - 1]
    let properties = {}

    if (parent === 'color') {
      properties = {
        color: key
      }
    }
    if (key === 'state') {
      properties = {
        size: selectedMnemoItem?.staticLabelElement?.borderStyle?.size ? 0 : 1
      }
    }
    if (parent === 'size') {
      properties = { size: +key }
    }

    if (selectedMnemoItem) {
      updateMnemonicElement({
        ...selectedMnemoItem,
        staticLabelElement: {
          ...selectedMnemoItem.staticLabelElement,
          borderStyle: {
            ...selectedMnemoItem.staticLabelElement?.borderStyle,
            ...properties
          }
        }
      })
    }
  }

  return (
    <TableCell title={'Граница'}>
      <Dropdown trigger={['click']} menu={{ items: borders, onClick: onClickBorder }} placement='bottomLeft'>
        <Button style={{ width: '100%' }}>
          <div
            style={{
              height: 15,
              borderWidth: selectedMnemoItem?.staticLabelElement?.borderStyle?.size || 1,
              borderStyle: 'solid',
              borderColor: selectedMnemoItem?.staticLabelElement?.borderStyle?.color || '#c1c1c1',
              opacity: selectedMnemoItem?.staticLabelElement?.borderStyle?.size ? 1 : 0.4
            }}
          />
        </Button>
      </Dropdown>
    </TableCell>
  )
}

export default BorderItem
