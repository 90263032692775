import GeneratorHarmonic from '@/app/configuration/components/devices/generator/generator-harmonic/generator-harmonic'
import GeneratorSquare from '@/app/configuration/components/devices/generator/generator-square/generator-square'
import Spinner from '@/components/widgets/spinner/spinner'
import { EGeneratorType } from '@/enums/device/generator/generator-type.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { useGetGeneratorQuery } from '@/store/api/devices.api'
import type { FC } from 'react'
import React from 'react'

type TProps = {
  deviceId: string | undefined
}

const Generator: FC<TProps> = ({ deviceId }) => {
  const { data: generator, isFetching } = useGetGeneratorQuery(deviceId, {
    skip: !deviceId
  })
  const { isEditGenerator } = useTypedSelector((state) => state.devicesReducer)
  const isSquareGenerator = generator?.type === EGeneratorType.SQUARE
  const isHarmonicGenerator = generator?.type === EGeneratorType.HARMONIC

  if (isFetching) {
    return <Spinner />
  }

  if (!generator || !deviceId) {
    return null
  }

  return (
    <>
      {isSquareGenerator ? (
        <GeneratorSquare generatorSquare={generator} isEditGenerator={isEditGenerator} currentGeneratorID={deviceId} />
      ) : null}
      {isHarmonicGenerator ? (
        <GeneratorHarmonic
          generatorHarmonic={generator}
          isEditGenerator={isEditGenerator}
          currentGeneratorID={deviceId}
        />
      ) : null}
    </>
  )
}
export default Generator
