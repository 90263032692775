import SubmenuLink from '@/components/controlers/submenu-link/submenu-link'
import type { ILink } from '@/types/navigation/links.type'
import type { FC } from 'react'
import React from 'react'

import styles from './submenu.module.css'

type SubmenuTypes = {
  links: Readonly<ILink[]>
}

const Submenu: FC<SubmenuTypes> = ({ links }) => (
  <ul className={styles.submenu}>
    {links.map((link, index) => (
      <SubmenuLink key={index} icon={link.icon} title={link.title} to={link.to} />
    ))}
  </ul>
)

export default Submenu
