import { ETargetType } from '@/enums/connection/target-type.enum'
import { EPhysicalQuantityType } from '@/enums/point/physical-quantity-type.enum'
import type { ITargetTypeOption } from '@/types/device/target-type-option.interface'

export const targetTypeOptions: ITargetTypeOption[] = [
  {
    label: 'VP-3701',
    value: ETargetType.VP3701,
    availableFor: [
      EPhysicalQuantityType.TEMPERATURE,
      EPhysicalQuantityType.VIBRO_ACCELERATION,
      EPhysicalQuantityType.VIBRO_DISPLACEMENT,
      EPhysicalQuantityType.VIBRO_VELOCITY
    ]
  },
  {
    label: 'LTR-24',
    value: ETargetType.LTR24,
    availableFor: [
      EPhysicalQuantityType.VOLTAGE,
      EPhysicalQuantityType.VIBRO_ACCELERATION,
      EPhysicalQuantityType.VIBRO_ACCELERATION,
      EPhysicalQuantityType.VIBRO_DISPLACEMENT,
      EPhysicalQuantityType.VIBRO_VELOCITY,
      EPhysicalQuantityType.AMPERAGE,
      EPhysicalQuantityType.RPM
    ]
  },
  { label: 'LTR-51', value: ETargetType.LTR51, disabled: true, availableFor: [] },
  {
    label: 'Генератор',
    value: ETargetType.GENERATOR,
    availableFor: [
      EPhysicalQuantityType.VOLTAGE,
      EPhysicalQuantityType.AMPERAGE,
      EPhysicalQuantityType.VIBRO_ACCELERATION,
      EPhysicalQuantityType.VIBRO_DISPLACEMENT,
      EPhysicalQuantityType.VIBRO_VELOCITY,
      EPhysicalQuantityType.RPM
    ]
  }
]

export const enum Vp3701Label {
  Vp3701Id = 'VP-3701 ID',
  SerialNumber = 'Серийный номер',
  PowerType = 'Источник питания',
  Version = 'Версия',
  IP = 'IP-адрес',
  Name = 'Наименование устройства',
  ConnectionStatus = 'Статус подключения к ТИ',
  StatusUpdatingTimestamp = 'Время обновления статуса',
  Status = 'Статус соединения',
  PortData = 'Порт данных',
  PortSetting = 'Порт потока'
}

export const enum GeneratorLabel {
  GeneratorId = 'Генератор-ID',
  Name = 'Наименование',
  Type = 'Тип',
  Fs = 'Частота дискретизации, Гц',
  TSig = 'Длительность сигнала, секунды',
  NormAmp = 'Амплитуда сигнала',
  NameSignal = 'Наименование сигнала',
  SquareBaseFreq = 'Базовая частота, Гц',
  SquareLowLevel = 'Нижний уровень',
  SquareHighToLow = 'Размах, %',
  SquareDuty = 'Длительность, секунды',
  SquarePhase = 'Начальная фаза, %',
  DskSettings = 'DskSettings',
  AmpSet = 'Амплитуда распределения',
  BaseAmp = 'Амплитуда базовой частоты',
  BaseFreq = 'Базовая частота, Гц',
  Dclevel = 'Постоянная составляющая',
  LastAmp = 'Амплитуда посл. гарм., %',
  M1_AmpSet = 'Закон изменения ряда',
  M1_BaseFreq = 'Базовая частота, Гц',
  M1_DeepA = 'Глубина модуляции ампл., %А',
  M1_DeepT = 'Глубина модуляции фазовая, %А',
  M1_LastAmp = 'Глубина модул. посл. гарм., %',
  M1_Nharm = 'Количество гармоник',
  M1_PhaseSet = 'Фазовое распределение',
  M1_Type = 'Тип модуляции',
  M1_PhaseShift = 'Сдвиг по фазе, град',
  M2_AmpSet = 'Закон изменения ряда',
  M2_BaseFreq = 'Базовая частота, Гц',
  M2_DeepA = 'Глубина модуляции ампл., %А',
  M2_DeepT = 'Глубина модуляции фазовая, %А',
  M2_LastAmp = 'Глубина модул. посл. гарм., %',
  M2_Nharm = 'Количество гармоник',
  M2_PhaseSet = 'Фазовое распределение',
  M2_PhaseShift = 'Сдвиг по фазе, град',
  M2_Type = 'Тип модуляции',
  Nharm = 'Количество гармоник',
  PhaseSet = 'Фазовое распределение',
  PhaseShift = 'Сдвиг по фазе, град',
  WhiteNoise = 'Уровень белого шума, %'
}

export const mapDeviceType: { [key in ETargetType]: string } = {
  [ETargetType.GENERATOR]: 'Генератор',
  [ETargetType.VP3701]: 'VP-3701',
  [ETargetType.LTR51]: 'LTR-51',
  [ETargetType.LTR24]: 'LTR-24'
} as const
