import { ETypeChart } from '@/constants/chart/charts.constant'
import { SliceName } from '@/constants/store/slices.constant'
import { EChartsViewMode } from '@/enums/charts/chart-view-mode.enum'
import type { IChartsUi } from '@/store/reducers/charts/charts-ui.interface'
import type { IConfigChartItem } from '@/types/chart/charts.type'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState: IChartsUi = {
  chartsViewMode: EChartsViewMode.Cascade,
  cursorSettings: {
    width: 0,
    isActive: false
  },
  refForFocusKeys: null,
  selectedResultId: undefined,
  selectedResultIndex: 0,
  configChart: { typeChart: ETypeChart.SCALAR, size: 50 }
}

export const chartsUiSlice = createSlice({
  name: SliceName.ChartsUi,
  initialState,
  reducers: {
    setChartsViewMode(state, action: PayloadAction<EChartsViewMode>) {
      state.chartsViewMode = action.payload
    },
    setActiveCursor(state, action: PayloadAction<boolean>) {
      state.cursorSettings.isActive = action.payload
    },
    setWidthCursor(state, action: PayloadAction<number>) {
      state.cursorSettings.width = action.payload
    },
    setRefForFocusKeys(state, action: PayloadAction<HTMLElement | null>) {
      ;(state.refForFocusKeys as HTMLElement | null) = action.payload
    },
    setSelectedResultId(state, action: PayloadAction<string>) {
      state.selectedResultId = action.payload
    },
    setSelectedResultIndex(state, action: PayloadAction<number>) {
      state.selectedResultIndex = action.payload
    },
    setConfigChart(state, action: PayloadAction<IConfigChartItem>) {
      state.configChart = action.payload
    }
  }
})
