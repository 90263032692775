import GroupIcon from '@/components/icons/equipments/group-icon'
import MachineIcon from '@/components/icons/equipments/machine-icon'
import measurementIcon from '@/components/icons/equipments/measurement-icon'
import PointIcon from '@/components/icons/equipments/point-icon'
import { mapMeasurementToTree } from '@/components/widgets/equipment-tree/equipment-tree.service'
import StatisticAlIIndicator from '@/components/widgets/statistical-indicator/statistic-alI-indicator'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { EEquipmentTree } from '@/enums/equipments/equipment-tree.enum'
import useActions from '@/hooks/use-actions'
import { useLazyGetGroupsQuery } from '@/store/api/grpups.api'
import { useLazyGetMachinesQuery } from '@/store/api/machines.api'
import { useLazyGetMeasurementsQuery } from '@/store/api/measurements.api'
import { useLazyGetAllNodesQuery } from '@/store/api/nodes.api'
import { useLazyGetMeasuringPointsQuery } from '@/store/api/points.api'
import type { TGroupElement } from '@/types/group/group.type'
import type { IMachineInfo } from '@/types/machine.type'
import type { IMeasurement } from '@/types/measurement/measurement.type'
import type { INodesState } from '@/types/nodes/nodes.type'
import type { ISelectedMeasuringPoint } from '@/types/point/point.type'
import type { IEquipmentTreeNode } from '@/types/tree/equipment-tree-node.interface'
import { updateTreeData } from '@/utils/tree/update-tree-data'
import type { EventDataNode } from 'antd/es/tree'
import { useCallback } from 'react'

import styles from './equipment-tree.module.css'

export const mapPointsToTree = (
  points: ISelectedMeasuringPoint[],
  equipmentTreeMode?: EEquipmentTreeMode
): IEquipmentTreeNode[] =>
  points.map((point) => ({
    key: point.pointId,
    title: point.name,
    group: EEquipmentTree.POINTS,
    isLeaf: equipmentTreeMode === EEquipmentTreeMode.CONNECTION || undefined,
    icon: (
      <div className={styles['container-icon']}>
        <PointIcon />
      </div>
    )
  }))

export const mapMachinesToTree = (
  machines: IMachineInfo[],
  equipmentTreeMode?: EEquipmentTreeMode,
  dataAllNodesState?: INodesState[]
): IEquipmentTreeNode[] =>
  machines.map((machine) => {
    const foundNodeState = dataAllNodesState?.find((node) => node.machineId === machine.machineId)
    const isCondition = equipmentTreeMode === EEquipmentTreeMode.CONDITION
    const isPlan = equipmentTreeMode === EEquipmentTreeMode.PLAN

    const extendedIcon = (
      <div className={styles['container-icon']}>
        {(isPlan || isCondition) && <StatisticAlIIndicator nodeState={foundNodeState} />}
        <MachineIcon />
      </div>
    )

    return {
      key: machine.machineId,
      title: machine.name,
      className: styles['tree-item'],
      group: EEquipmentTree.MACHINES,
      icon: extendedIcon,
      isLeaf: isCondition || isPlan || undefined
    }
  })

export const mapGroupsToTree = (
  groups: TGroupElement[],
  isCondition?: boolean,
  dataAllNodesState?: INodesState[]
): IEquipmentTreeNode[] =>
  groups.map((group) => {
    const foundNodeState = dataAllNodesState?.find((node) => node.groupId === group.groupId)
    const extendedIcon = (
      <div className={styles['container-icon']}>
        {isCondition && <StatisticAlIIndicator nodeState={foundNodeState} />}
        <GroupIcon />
      </div>
    )

    return {
      key: group.groupId,
      title: group.name,
      className: styles['tree-item'],
      group: EEquipmentTree.GROUPS,
      icon: extendedIcon
    }
  })

export const useTreeLoader = (treeMenu: IEquipmentTreeNode[], equipmentTreeMode?: EEquipmentTreeMode) => {
  const isCondition = equipmentTreeMode === EEquipmentTreeMode.CONDITION
  const isPlan = equipmentTreeMode === EEquipmentTreeMode.PLAN
  const isConfiguration = equipmentTreeMode === EEquipmentTreeMode.CONFIGURATION
  const isGetAllNodes = isPlan || isCondition
  const [getMachines] = useLazyGetMachinesQuery()
  const [getGroups] = useLazyGetGroupsQuery()
  const [getPoints] = useLazyGetMeasuringPointsQuery()
  const [getMeasurements] = useLazyGetMeasurementsQuery()
  const [triggerGetAllNodes] = useLazyGetAllNodesQuery({
    pollingInterval: isGetAllNodes ? 3000 : undefined
  })

  const { setTreeMenu } = useActions()

  const updateTreeByRootOrGroup = useCallback(
    (machines: IMachineInfo[], groups: TGroupElement[], key: string, nodesStates?: INodesState[]) => {
      const machinesTree = mapMachinesToTree(machines, equipmentTreeMode, nodesStates)
      const groupsTree = mapGroupsToTree(groups, isCondition, nodesStates)
      const newElements = [...groupsTree, ...machinesTree]
      const updatedTreeData = updateTreeData<IEquipmentTreeNode>(treeMenu, key, newElements)
      setTreeMenu(updatedTreeData)
    },
    [equipmentTreeMode, isCondition, setTreeMenu, treeMenu]
  )

  const updateTreeByMachine = useCallback(
    (points: ISelectedMeasuringPoint[], key: string) => {
      const pointsTree = mapPointsToTree(points, equipmentTreeMode)
      const updatedTreeData = updateTreeData<IEquipmentTreeNode>(treeMenu, key, pointsTree)
      setTreeMenu(updatedTreeData)
    },
    [equipmentTreeMode, setTreeMenu, treeMenu]
  )

  const updateTreeByMeasurements = useCallback(
    (measurements: IMeasurement[], key: string) => {
      const machinesTree = mapMeasurementToTree(measurements, measurementIcon)
      const updatedTreeData = updateTreeData<IEquipmentTreeNode>(treeMenu, key, machinesTree)
      setTreeMenu(updatedTreeData)
    },
    [setTreeMenu, treeMenu]
  )

  const loadRootData = useCallback(
    async (id: string) => {
      try {
        const [groupsResponse, machinesResponse, nodesResponse] = await Promise.all([
          getGroups(null).unwrap(),
          getMachines(null).unwrap(),
          isGetAllNodes ? triggerGetAllNodes(null).unwrap() : undefined
        ])
        updateTreeByRootOrGroup(machinesResponse.content, groupsResponse.content, id, nodesResponse)
      } catch (error) {
        console.error(`Failed to load root data for ID ${id}:`, error)
      }
    },
    [getGroups, getMachines, isConfiguration, triggerGetAllNodes, updateTreeByRootOrGroup]
  )

  const loadGroupData = useCallback(
    async (id: string) => {
      try {
        const [groupsResponse, machinesResponse, nodesResponse] = await Promise.all([
          getGroups(id).unwrap(),
          getMachines(id).unwrap(),
          isGetAllNodes ? triggerGetAllNodes(null).unwrap() : undefined
        ])
        updateTreeByRootOrGroup(machinesResponse.content, groupsResponse.content, id, nodesResponse)
      } catch (error) {
        console.error(`Failed to load group data for ID ${id}:`, error)
      }
    },
    [getGroups, getMachines, isConfiguration, triggerGetAllNodes, updateTreeByRootOrGroup]
  )

  const loadMachineData = useCallback(
    async (id: string) => {
      try {
        const { content: points } = await getPoints(id).unwrap()
        updateTreeByMachine(points, id)
      } catch (error) {
        console.error(`Failed to load points for machine ${id}:`, error)
      }
    },
    [getPoints, updateTreeByMachine]
  )

  const loadPointData = useCallback(
    async (id: string) => {
      try {
        const { content: measurements } = await getMeasurements(id).unwrap()
        updateTreeByMeasurements(measurements, id)
      } catch (error) {
        console.error(`Failed to load measurements for point ${id}:`, error)
      }
    },
    [getMeasurements, updateTreeByMeasurements]
  )

  const handleDataLoad = useCallback(
    async (treeNode: EventDataNode<IEquipmentTreeNode>) => {
      const { key, group } = treeNode
      const id = key as string

      switch (group) {
        case EEquipmentTree.ROOT:
          await loadRootData(id)
          break
        case EEquipmentTree.GROUPS:
          await loadGroupData(id)
          break
        case EEquipmentTree.MACHINES:
          await loadMachineData(id)
          break
        case EEquipmentTree.POINTS:
          await loadPointData(id)
          break
        default:
          console.warn(`Unknown group type: ${group}`)
      }
    },
    [loadRootData, loadGroupData, loadMachineData, loadPointData]
  )

  return { handleDataLoad }
}
