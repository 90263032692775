import type { FormProps } from 'antd'
import { Form } from 'antd'
import cn from 'classnames'
import type { FC, PropsWithChildren } from 'react'
import React from 'react'

import styles from './form-ui.module.css'

type FormUi = {
  isShake?: boolean
}

type PropsType = FormProps & FormUi

const FormUi: FC<PropsWithChildren<PropsType>> = ({ children, isShake, className, ...props }) => (
  <Form
    {...props}
    className={cn(className, {
      [styles.shake]: isShake
    })}
  >
    {children}
  </Form>
)

export default FormUi
