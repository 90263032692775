import CharFreqTreeSelect from '@/app/machine-condition/components/results/components/charts/components/express-sidebar/components/char-freq-tree-select/char-freq-tree-select'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { ICharFreqTree } from '@/types/kinematic/char-freq.type'
import { Form, InputNumber, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import cn from 'classnames'
import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react'

import styles from './express-bar-popup.module.css'

interface IExpressBarPopup {
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>
  isPopupOpen: boolean
}

interface IForm {
  mainFreq: number
  countOfMainBars: number
  modulateFreq: number
  countOfModulateBars: number
  charFreqForMain: number
  charFreqForModulate: number
}

const ExpressBarPopup: FC<IExpressBarPopup> = ({ setIsPopupOpen, isPopupOpen }) => {
  const { barOptions } = useTypedSelector((state) => state.expressBarsReducer)
  const { setExpressBarOptions } = useActions()
  const [form] = useForm<IForm>()
  // const { kinematicElementsQuery } = useGetKinematicElements(selectedMachineId)
  // const { data: elements } = kinematicElementsQuery

  const handleOnFinish = (values: {
    mainFreq: number
    countOfMainBars: number
    modulateFreq: number
    countOfModulateBars: number
  }) => {
    const options = {
      mainFreq: values.mainFreq,
      mainIndex: null,
      modulateFreq: Math.abs(values.modulateFreq),
      modulateIndex: null,
      countOfMainBars: Math.round(values.countOfMainBars),
      countOfModulateBars: Math.abs(Math.round(values.countOfModulateBars))
    }

    setExpressBarOptions(options)

    setIsPopupOpen(false)
  }

  const handleOnReset = () => {
    setIsPopupOpen(false)
  }

  useEffect(() => {
    if (isPopupOpen) {
      form.setFieldsValue(barOptions)
    }
  }, [barOptions, form, isPopupOpen])

  const handleCharFreqForMainSelect = (value: ICharFreqTree['value'], options: ICharFreqTree) => {
    form.setFieldValue('mainFreq', options?.payload?.freqOutValue)
  }

  const handleCharFreqForModulate = (value: ICharFreqTree['value'], options: ICharFreqTree) => {
    form.setFieldValue('modulateFreq', options?.payload?.freqOutValue)
  }

  return (
    <Modal
      centered={true}
      width={1200}
      footer={false}
      onCancel={() => setIsPopupOpen(false)}
      open={isPopupOpen}
      maskClosable={false}
    >
      <Form form={form} onFinish={handleOnFinish} onReset={handleOnReset}>
        <PanelTitle>Управление рядами</PanelTitle>
        <div className={styles['container']}>
          <div className={styles['section']}>
            <FormItemApp name='mainFreq' label='Частота А'>
              <InputNumber min={0} />
            </FormItemApp>
            <FormItemApp name='countOfMainBars' label='Количество гармоник А'>
              <InputNumber min={0} />
            </FormItemApp>
            <FormItemApp name='modulateFreq' label='Частота m'>
              <InputNumber />
            </FormItemApp>
            <FormItemApp name='countOfModulateBars' label='Количество гармоник m'>
              <InputNumber />
            </FormItemApp>
          </div>
          <div className={cn(styles['section-support'], styles['section'])}>
            <FormItemApp label='← Характреные частоты' name='charFreqForMain'>
              <CharFreqTreeSelect onSelect={handleCharFreqForMainSelect} />
            </FormItemApp>
            <FormItemApp label='← Характреные частоты' name='charFreqForModulate'>
              <CharFreqTreeSelect onSelect={handleCharFreqForModulate} />
            </FormItemApp>
          </div>
        </div>
        <ButtonsWrapper>
          <ButtonPrimary htmlType={'reset'} title={'Отмена'} onClick={() => setIsPopupOpen(false)} />
          <ButtonPrimary htmlType={'submit'} title={'Сохранить'} />
        </ButtonsWrapper>
      </Form>
    </Modal>
  )
}
export default ExpressBarPopup
