import PlansMainContent from '@/app/plans/components/plans-main-content/plans-main-content'
import PlansToolbar from '@/app/plans/components/plans-toolbar/plans-toolbar'
import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import type { FC } from 'react'

const Plans: FC = () => (
  <Container>
    <PlansToolbar />
    <LeftBar>
      <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.PLAN} />
    </LeftBar>
    <PlansMainContent />
  </Container>
)

export default Plans
