import StatusIndicator from '@/app/configuration/components/devices/devices-tree/status-indicator/status-indicator'
import { vp3701Modules } from '@/constants/device/vp3701/vp3701-modules.constant'
import { EDefviceStatus } from '@/enums/device/defvice-staatus.enum'
import { EDeviceContent } from '@/enums/device/device-content.enum'
import { EMeasuringPointConnectionStatus } from '@/enums/device/measuring-point-connection-status.enum'
import { ELtrCrateStatus } from '@/enums/ltr/ltr-crate-status.enum'
import { ELtrModuleStatus } from '@/enums/ltr/ltr-module-status.enum'
import type { TDeviceNode } from '@/types/device/device-node.type'
import type { IVp3701 } from '@/types/device/vp3701/vp3701.interface'
import type { IGenerator } from '@/types/devices.type'
import type { ILtr24Channel } from '@/types/ltr/channel/ltr24-channel-setting.interface'
import type { ICrate } from '@/types/ltr/crate/crate.interface'
import type { ILtrModule } from '@/types/ltr/ltr-module/ltr-module.interface'
import type { ITreeNode } from '@/types/tree/tree-node.interface'
import { replaceDecimalPoint } from '@/utils/common.util'
import { generateChannelName } from '@/utils/device/generate-channel-name'
import { VideoCameraOutlined } from '@ant-design/icons'
import { Flex } from 'antd'
import cn from 'classnames'

import styles from '@/app/configuration/components/devices/devices-tree/device-tree.module.css'

const getNameWithBatteryCharge = (device: IVp3701) => {
  const batteryCharge = replaceDecimalPoint(device.batteryCharge?.toFixed(2))
  return batteryCharge ? `${device.name} | ${batteryCharge} В` : device.name
}

export const mapModulesVp3701ForTree = (vp3701ID: string): TDeviceNode[] =>
  vp3701Modules.map((module) => ({
    key: `${vp3701ID}-${module.name}`,
    title: module.name,
    content: EDeviceContent.VP_3701_MODULE,
    className: styles['tree-item'],
    icon: (
      <span style={{ fontSize: 18 }} className='material-symbols-outlined'>
        developer_board
      </span>
    ),
    children: module.channels.map((channel) => ({
      key: `${vp3701ID}-${module.name}-${channel.vp3701ChannelType}`,
      title: channel.name,
      content: EDeviceContent.VP_3701_CHANNEL,
      className: styles['tree-item'],
      vp3701ChannelType: channel.vp3701ChannelType,
      physicalQuantityType: channel.physicalQuantityType,
      icon: (
        <span style={{ fontSize: 18 }} className='material-symbols-outlined'>
          login
        </span>
      )
    }))
  }))

export const mapVp3701ForTree = (vp3701Collection: IVp3701[]): TDeviceNode[] =>
  vp3701Collection.map((device) => ({
    key: device.vp3701Id,
    title: getNameWithBatteryCharge(device),
    className: styles['tree-item'],
    content: EDeviceContent.VP_3701_DEVICE,
    vp3701: device,
    icon: (
      <Flex gap={5} align='center'>
        <VideoCameraOutlined rotate={90} />
        <StatusIndicator isOnline={device.status === EDefviceStatus.ONLINE} />
      </Flex>
    ),
    children: mapModulesVp3701ForTree(device.vp3701Id)
  }))

export const mapChannelsForTree = (ltr24Channels: ILtr24Channel[], moduleId: string): TDeviceNode[] =>
  ltr24Channels.map((channel) => {
    const key = `${moduleId}-${channel.channelNumber}`
    const name = generateChannelName(channel.channelNumber)

    return {
      key: key,
      title: channel.name || name,
      content: EDeviceContent.VP_3711_CHANNEL,
      className: styles['tree-item'],
      ltr24Channel: channel,
      physicalQuantityType: channel.physicalQuantityType,
      icon: (
        <span
          className={cn('material-symbols-outlined', styles['icon-channel'], {
            [styles['icon-channel-connected']]:
              channel.connectionStatus === EMeasuringPointConnectionStatus.MEASURING_POINT_CONNECTED
          })}
        >
          login
        </span>
      )
    }
  })

export const mapModulesForTree = (modules: ILtrModule[]): ITreeNode<EDeviceContent>[] =>
  modules.map((module) => ({
    key: module.ltrModuleId,
    title: module.name,
    content: EDeviceContent.VP_3711_MODULE,
    className: styles['tree-item'],
    icon: (
      <Flex gap={5} align='center'>
        <span style={{ fontSize: 18 }} className='material-symbols-outlined'>
          developer_board
        </span>
        <StatusIndicator
          isOnline={module.status === ELtrModuleStatus.READY}
          isWorking={module.status === ELtrModuleStatus.RUNNING}
        />
      </Flex>
    ),
    children: mapChannelsForTree(module.ltr24ChannelSettings, module.ltrModuleId)
  }))

export const mapVp3711ForTree = (crates: ICrate[], ltrModules: ILtrModule[]): ITreeNode<EDeviceContent>[] =>
  crates.map((crate) => {
    const filteredModulesByCrate = ltrModules?.filter((ltrModule) => ltrModule.crateSerial === crate.crateSerial)

    return {
      key: crate.crateId,
      title: crate.name,
      isLeaf: filteredModulesByCrate.length === 0,
      className: styles['tree-item'],
      content: EDeviceContent.VP_3711_DEVICE,
      icon: (
        <Flex gap={5} align='center'>
          <span style={{ fontSize: 18 }} className='material-symbols-outlined'>
            dns
          </span>
          <StatusIndicator isOnline={crate.status === ELtrCrateStatus.ONLINE} />
        </Flex>
      ),
      children: mapModulesForTree(filteredModulesByCrate)
    }
  })

export const mapGeneratorForTree = (generators: IGenerator[]): ITreeNode<EDeviceContent>[] =>
  generators.map((generator) => ({
    key: generator.generatorId,
    title: generator.name,
    content: EDeviceContent.GENERATOR_DEVICE,
    className: styles['tree-item'],
    icon: (
      <span style={{ fontSize: 18 }} className='material-symbols-outlined'>
        error_med
      </span>
    )
  }))
