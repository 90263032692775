import EquipmentItem from '@/components/widgets/equipment-item/equipment-item'
import StatisticAlIIndicator from '@/components/widgets/statistical-indicator/statistic-alI-indicator'
import { useGetAllNodesQuery } from '@/store/api/nodes.api'
import type { FC } from 'react'

interface IProps {
  name?: string
  paused?: boolean
  id: string
}

const PlanItem: FC<IProps> = ({ name, paused, id }) => {
  const { data: dataAllNodesState } = useGetAllNodesQuery(null)
  const foundNodeState = dataAllNodesState?.find((node) => node.machineId === id)

  return (
    <EquipmentItem
      indicator={foundNodeState ? <StatisticAlIIndicator nodeState={foundNodeState} /> : null}
      name={name}
      paused={paused}
    />
  )
}

export default PlanItem
