import EquipmentTools from '@/app/configuration/components/equipments/components/equipment-tools/equipment-tools'
import Group from '@/app/configuration/components/equipments/components/group/group'
import Machine from '@/app/configuration/components/equipments/components/machine/machine'
import Threshold from '@/app/configuration/components/equipments/components/measurements/components/threshold/threshold'
import Measurement from '@/app/configuration/components/equipments/components/measurements/measurement'
import Point from '@/app/configuration/components/equipments/components/point/point'
import Container from '@/components/controlers/layouts/container/container'
import LeftBar from '@/components/controlers/layouts/left-bar/left-bar'
import ToolBar from '@/components/controlers/layouts/tool-bar/tool-bar'
import WorkSpace from '@/components/controlers/layouts/work-space/work-space'
import Submenu from '@/components/controlers/submenu/submenu'
import EquipmentTree from '@/components/widgets/equipment-tree/equipment-tree'
import { configurationLinks } from '@/constants/navigation/links.constant'
import { EViewMode } from '@/enums/common/view-mode.enum'
import { EEquipmentTreeMode } from '@/enums/equipments/equipment-tree-mode.enum'
import { EEquipmentContent } from '@/enums/equipments/equipment-view.enum'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import { type FC } from 'react'

const Equipments: FC = () => {
  const { selectedMachineId, selectedMeasurementId, selectedPointId } = useTypedSelector((state) => state.globalReducer)
  const { equipmentView } = useTypedSelector((state) => state.equipmentsUiReducer)
  const isGroupContent = equipmentView.content === EEquipmentContent.GROUP
  const isMachineElement = equipmentView.content === EEquipmentContent.MACHINE
  const isPointContent = equipmentView.content === EEquipmentContent.POINT
  const isMeasurementContent = equipmentView.content === EEquipmentContent.MEASUREMENT
  const isToleranceContent = equipmentView.content === EEquipmentContent.TOLERANCE
  const isAddMode = equipmentView.mode === EViewMode.ADDITION
  const isEditMode = equipmentView.mode === EViewMode.EDITING

  return (
    <Container>
      <ToolBar>
        <EquipmentTools
          measurementId={selectedMeasurementId}
          measurementOpened={isMeasurementContent}
          toleranceControlOpened={isToleranceContent}
        />
      </ToolBar>
      <LeftBar>
        <EquipmentTree equipmentTreeMode={EEquipmentTreeMode.CONFIGURATION} />
      </LeftBar>
      <WorkSpace>
        <Submenu links={configurationLinks} />

        {isToleranceContent && <Threshold measurementId={selectedMeasurementId} />}

        {isMeasurementContent && (
          <Measurement
            measurementId={selectedMeasurementId}
            pointId={selectedPointId}
            machineId={selectedMachineId}
            isAddMode={isAddMode}
            isEditMode={isEditMode}
          />
        )}

        {isPointContent && (
          <Point
            pointId={selectedPointId}
            machineId={selectedMachineId}
            isAddMode={isAddMode}
            isEditMode={isEditMode}
          />
        )}

        {isMachineElement && <Machine machineId={selectedMachineId} isAddMode={isAddMode} isEditMode={isEditMode} />}
        {isGroupContent && <Group isAddMode={isAddMode} isEditMode={isEditMode} />}
      </WorkSpace>
    </Container>
  )
}

export default Equipments
