import {
  calculateInitialMarkers,
  calculateResultsMarkers
} from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/hooks/use-express-markers/use-express-markers.service'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { IMarker } from '@/types/express-analysis/marker/marker.type'
import { maxBy } from 'lodash'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { ReferenceDot, ReferenceLine } from 'recharts'

const markersColors = ['rgb(0,0,255)', '#ff8000', '#43942e']

const useExpressMarkers = (chartSpectrum: IChartSpectrum[], title: string) => {
  const { setIsDragZone, setMarkers, setFormMarker, setActiveMarker, setMarkersResults, setChartSpectrum } =
    useActions()
  const { markers, isDragZone, activeMarker, isMarkersExpressBarOpen } = useTypedSelector(
    (state) => state.expressMarkersReducer
  )
  const [localMarkers, setLocalMarkers] = useState<IMarker[] | null>(null)
  const refThrottlingTimer = useRef<null | ReturnType<typeof setTimeout>>(null)

  useEffect(() => {
    setChartSpectrum(chartSpectrum)
  }, [chartSpectrum, setChartSpectrum])

  // Инициализация маркеров
  useEffect(() => {
    if (markers) {
      return
    }
    const initialMarkers = calculateInitialMarkers(chartSpectrum)
    setMarkers(initialMarkers)
    const [marker1, marker2, marker3] = initialMarkers
    setFormMarker({ freq1: marker1.frequency, freq2: marker2.frequency, freq3: marker3.frequency })
  }, [chartSpectrum, markers, setFormMarker, setMarkers, title])

  useEffect(() => {
    if (markers) {
      // const markersForNewSpecter = calculateMarkersForNewSpecter(chartSpectrum, markers)
      const result = calculateResultsMarkers(chartSpectrum, markers, title)
      setFormMarker({
        freq1: markers[0].frequency,
        freq2: markers[1].frequency,
        freq3: markers[2].frequency
      })
      setMarkersResults(result)
    }
  }, [chartSpectrum, markers, setFormMarker, setMarkersResults, title])

  useEffect(() => {
    if (markers) {
      setLocalMarkers(markers)
    }
  }, [chartSpectrum, markers])

  const resetDefaultSettings = () => {
    if (isDragZone && localMarkers) {
      setIsDragZone(false)
      setMarkers(localMarkers)
    }
  }

  const handleSetLocalMarkers = (marker: IMarker) => {
    setLocalMarkers((prev) => {
      if (prev) {
        const { index } = marker
        const newLocalMarkers = [...prev]
        newLocalMarkers[index] = marker
        return newLocalMarkers
      }
      return prev
    })
  }

  const handleMouseMove = (evt: any) => {
    if (isDragZone) {
      const payload = evt.activePayload?.[0]?.payload as IMarker
      if (payload && activeMarker) {
        const { index } = activeMarker
        const { frequency, index: chartIndex } = payload
        handleSetLocalMarkers({ index, frequency, chartIndex })
      }
    }
  }

  const handleMouseUp = () => {
    resetDefaultSettings()
  }

  const handleMouseLeave = () => {
    resetDefaultSettings()
  }

  const handleStep = (countStep: number, direction: 'left' | 'right') => {
    if (activeMarker && localMarkers) {
      const { chartIndex, index } = localMarkers[activeMarker.index]
      const chartIndexNext = direction === 'right' ? chartIndex + countStep : chartIndex - countStep
      if (chartSpectrum?.[chartIndexNext]) {
        const { frequency, amplitude } = chartSpectrum[chartIndexNext]
        const newMarker = { index, chartIndex: chartIndexNext, frequency, amplitude }
        handleSetLocalMarkers(newMarker)
      }
    }
  }

  const handleStepUp = (countStep: number) => {
    if (activeMarker && localMarkers) {
      const { chartIndex, index } = localMarkers[activeMarker.index]
      const maxChartPoint = maxBy(
        [chartSpectrum[chartIndex - countStep], chartSpectrum[chartIndex], chartSpectrum[chartIndex + countStep]],
        'amplitude'
      )

      if (maxChartPoint?.index) {
        const { frequency, amplitude } = chartSpectrum[maxChartPoint.index]
        const newMarker = { index, chartIndex: maxChartPoint.index, frequency, amplitude }
        handleSetLocalMarkers(newMarker)
      }
    }
  }

  const updateThrottleMarkers = useCallback(() => {
    if (localMarkers) {
      if (refThrottlingTimer.current !== null) {
        clearTimeout(refThrottlingTimer.current)
        refThrottlingTimer.current = null
      }

      refThrottlingTimer.current = setTimeout(() => {
        setMarkers(localMarkers)
      }, 500)
    }
  }, [localMarkers, setMarkers])

  const markersHotKeysHandlers = {
    StepRightDown: () => handleStep(1, 'right'),
    StepRightUp: () => updateThrottleMarkers(),
    StepLeftDown: () => handleStep(1, 'left'),
    StepLeftUp: () => updateThrottleMarkers(),
    MiddleStepRightDown: () => handleStep(10, 'right'),
    MiddleStepRightUp: () => updateThrottleMarkers(),
    MiddleStepLeftDown: () => handleStep(10, 'left'),
    MiddleStepLeftUp: () => updateThrottleMarkers(),
    LongStepRightDown: () => handleStep(50, 'right'),
    LongStepRightUp: () => updateThrottleMarkers(),
    LongStepLeftDown: () => handleStep(50, 'left'),
    LongStepLeftUp: () => updateThrottleMarkers(),
    StepUpDown: () => handleStepUp(1),
    StepUpUp: () => updateThrottleMarkers()
  }

  const renderMarkers = () =>
    isMarkersExpressBarOpen &&
    localMarkers?.map(
      (marker, index) =>
        marker.chartIndex <= chartSpectrum.length - 1 && (
          <Fragment key={marker.index}>
            <ReferenceLine
              x={marker.frequency}
              label={{
                position: 'insideTopLeft',
                value: index + 1,
                fill: '#000',
                stroke: 'blue'
              }}
              stroke={markersColors[index]}
              strokeWidth={2}
              cursor={'move'}
              onMouseDown={() => {
                setIsDragZone(true)
                setActiveMarker(marker)
              }}
            />

            <ReferenceDot
              r={4}
              fill={activeMarker?.index === marker.index ? '#0b7ae8' : '#000'}
              x={marker.frequency}
              y={chartSpectrum[marker.chartIndex].amplitude}
            />
          </Fragment>
        )
    )

  return {
    markersHotKeysHandlers,
    handleMouseMove,
    handleMouseUp,
    handleMouseLeave,
    renderMarkers
  }
}
export default useExpressMarkers
