import type { EDetectorType } from '@/enums/measurment/common-level/detector-type.enum'
import type { EOverlayType } from '@/enums/measurment/program-specter/overlap-type.enum'

export const mapOverlayType: { [key in EOverlayType]: string } = {
  COUNT_0: '0',
  COUNT_10: '10',
  COUNT_20: '20',
  COUNT_30: '30',
  COUNT_40: '40',
  COUNT_50: '50',
  COUNT_65: '65'
} as const

export const overlayTypeOptions = Object.entries(mapOverlayType).map(([key, label]) => ({
  value: key as EDetectorType,
  label
}))
