import type { EUnitType } from '@/enums/measurment/unit-type.enum'
import type { IChartScalar, IChartZone, IMeasurementScalarResult } from '@/types/chart/charts.type'
import type { IThreshold } from '@/types/threshold/threshold.type'
import { unitConverter } from '@/utils/unit/unit-converter.util'
import { max, min } from 'lodash'

export const getChartData = (sourceData: IMeasurementScalarResult[], sourceUnit: EUnitType, targetUnit: EUnitType) => {
  const result = sourceData.map<IChartScalar>((scalarItem, scalarIndex) => {
    const convertedValue = unitConverter(scalarItem.value, sourceUnit, targetUnit)

    return {
      value: convertedValue,
      index: scalarIndex + 1,
      time: scalarItem.time
    }
  })

  return result
}

export const getChartThresholds = (
  threshold: IThreshold,
  rangeValues: number[],
  sourceUnit: EUnitType,
  targetUnit: EUnitType,
  offset: number
): IChartZone[] =>
  threshold.zones.map<IChartZone>((zone, index, list) => {
    const [minValue, maxValue] = rangeValues
    const upperValue = threshold.rifts[index - 1]?.riftValue
    const lowerValue = threshold.rifts[index]?.riftValue
    const convertedUpperValue = unitConverter(upperValue, sourceUnit, targetUnit)
    const convertedLowerValue = unitConverter(lowerValue, sourceUnit, targetUnit)

    const upper = index === 0 ? maxValue + offset : convertedUpperValue || upperValue
    const lower = index === list.length - 1 ? minValue - offset : convertedLowerValue || lowerValue

    const hysteresis =
      threshold.rifts[index]?.hysteresisCoef && lower ? lower - threshold.rifts[index]?.hysteresisCoef : undefined

    return {
      lower: lower,
      upper: upper,
      thresholdLevel: zone.dangerousType,
      hysteresis: hysteresis ? unitConverter(hysteresis, sourceUnit, targetUnit) : hysteresis
    }
  })

export const getRangeValues = (chartData: IChartScalar[]): number[] => {
  const values = chartData.map((element) => element.value)

  if (values.length === 1) {
    const value = values[0]
    return [value - value * 0.5, value + value * 0.5]
  }

  const minV = min(values) as number
  const maxV = max(values) as number
  return [minV, maxV]
}
