import { ControlPanel } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/control-panel/control-panel'
import { OutTable } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/components/out-table/out-table'
import { DcmParamName } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/dcm-form.constant'
import type {
  IFormDcm,
  IInitialData,
  IOuts
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/dcm-form.type'
import {
  getCalculatedResults,
  parseDataForChangeDcm,
  parseServerData
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/components/dcm-form/dcm-form.util'
import {
  TypeSource,
  optionsTypeSourceWithManual
} from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.constant'
import { useChangeKinematicElementMutation } from '@/store/api/kinematic.api'
import type { IKinematicElement } from '@/types/kinematic/kinematic.type'
import { errorNotificationCreate, successNotificationCreate } from '@/utils/notification-creators'
import { validateExcludedValue, validateMinValue } from '@/utils/validation-rules'
import { Form, InputNumber, type InputRef, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import TextArea from 'antd/es/input/TextArea'
import { useForm } from 'antd/lib/form/Form'
import { isEqual } from 'lodash'
import type { FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'

import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type ShFormProps = {
  onClose: () => void
  kinematicElement: IKinematicElement | null
  image: string
}

const DcmForm: FC<ShFormProps> = ({ onClose, kinematicElement, image }) => {
  const [changeKinematicElement, { isLoading: isUpdatingKinematicElement }] = useChangeKinematicElementMutation()

  const [disabledCancelButton, setDisabledCancelButton] = useState(true)
  const [disabledFinishButton, setDisabledFinishButton] = useState(true)
  const [disabledCalculateButton, setDisabledCalculateButton] = useState(false)
  const [outs, setOuts] = useState<IOuts | null>(null)
  const [initialData, setInitialData] = useState<IInitialData | null>(null)

  const [dcmForm] = useForm<IFormDcm>()
  const typeSourceObservable = useWatch(DcmParamName.TypeSource, dcmForm)
  const areaRef = useRef<InputRef>(null)
  const fieldsObservable = useWatch([], dcmForm)

  // Инициализация данных
  useEffect(() => {
    const { setFieldsValue, resetFields } = dcmForm

    resetFields()
    setOuts(null)
    if (kinematicElement) {
      const { fields, outs: outsData } = parseServerData(kinematicElement)
      setFieldsValue(fields)
      setOuts(outsData)

      setInitialData({
        fields: fields,
        outs: outsData
      })
    }
  }, [dcmForm, kinematicElement])

  useEffect(() => {
    const { validateFields, getFieldsValue } = dcmForm
    const isInitialData = isEqual(initialData?.fields, getFieldsValue())

    const checkValidateFields = async (): Promise<void> => {
      try {
        await validateFields({
          validateOnly: true,
          recursive: true
        })

        if (!isInitialData) {
          setDisabledCalculateButton(false)
        }
      } catch (e) {
        setDisabledCalculateButton(true)
      }
    }

    if (!disabledCancelButton) {
      checkValidateFields()
    }
  }, [dcmForm, initialData, fieldsObservable, disabledCancelButton])

  useEffect(() => {
    setDisabledFinishButton(true)
  }, [fieldsObservable])

  // Отключение кнопки "Отмена"
  useEffect(() => {
    const { getFieldsValue } = dcmForm
    const isInitialData = isEqual(initialData?.fields, getFieldsValue())
    setDisabledCancelButton(isInitialData)
    setDisabledCalculateButton(isInitialData)
  }, [dcmForm, fieldsObservable, initialData?.fields])

  const handleChangeSelect = (value: TypeSource): void => {
    dcmForm.setFieldValue(DcmParamName.TypeSource, value)
  }

  const handleCalculation = async () => {
    const { getFieldsValue, validateFields } = dcmForm
    const fieldsValue = getFieldsValue()
    try {
      await validateFields()

      const resultOuts = getCalculatedResults(fieldsValue, kinematicElement?.kinematicBlockName)

      setOuts(resultOuts)
      successNotificationCreate('Коэффициенты успешно расчитаны')

      setDisabledFinishButton(false)
      setDisabledCalculateButton(true)
    } catch (e) {
      setDisabledFinishButton(true)
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
    }
  }

  const handleFinish = async (): Promise<void> => {
    const { getFieldsValue, validateFields } = dcmForm
    const fieldValues = getFieldsValue()

    try {
      await validateFields()
    } catch (e) {
      errorNotificationCreate(e, {
        message: 'Форма содержит ошибки'
      })
      return
    }

    try {
      if (kinematicElement && outs) {
        const requestDataParsed = parseDataForChangeDcm(kinematicElement, fieldValues, outs)

        await changeKinematicElement(requestDataParsed).unwrap()

        successNotificationCreate(`Кинематический блок ${kinematicElement.kinematicBlockName} сохранён`)
        onClose()
      }
    } catch (e) {
      errorNotificationCreate(e)
    }
  }

  const handleCancel = () => {
    if (initialData) {
      const { setFieldsValue, resetFields } = dcmForm
      resetFields()
      setFieldsValue(initialData.fields)
      setOuts(initialData.outs)
    }
  }

  return (
    <>
      <Form className={styles['drawer-kinematic-form']} onFinish={handleFinish} form={dcmForm}>
        <h2 className={styles['drawer-kinematic-subtitle']}>Описание</h2>
        <div className={styles['drawer-kinematic-header']}>
          <Form.Item name={DcmParamName.Description} className={styles['drawer-kinematic-text-area']}>
            <TextArea placeholder='Описание кинематического блока' autoSize={{ minRows: 12 }} ref={areaRef} />
          </Form.Item>
          <div className={styles['drawer-kinematic-image-wrapper']}>
            <img className={styles['drawer-kinematic-image']} src={image} alt='' />
          </div>
        </div>
        <h2 className={styles['drawer-kinematic-subtitle']}>Вход</h2>
        <Form.Item
          label='Тип источника'
          name={DcmParamName.TypeSource}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо выбрать тип источника'
            },
            {
              validator: validateExcludedValue({
                excludedValue: TypeSource.Missing,
                errorMessage: 'Необходимо выбрать тип источника'
              })
            }
          ]}
        >
          <Select
            placeholder='Выберите тип источника'
            options={optionsTypeSourceWithManual}
            onChange={handleChangeSelect}
          />
        </Form.Item>

        <Form.Item
          label='Коэффициент частоты КБ'
          name={DcmParamName.FreqCoefForIn}
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать коэффициент входной частоты'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            className={styles['drawer-kinematic-input-number']}
            placeholder='Введите коэффициент входной частоты'
            min={0}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Параметры</h2>
        {typeSourceObservable === TypeSource.Manual && (
          <Form.Item
            label='Частота вращения'
            name={DcmParamName.FreqOutSource}
            className={styles['drawer-kinematic-input-item']}
            rules={[
              {
                required: true,
                message: 'Необходимо указать частоту вращения'
              }
            ]}
          >
            <InputNumber
              decimalSeparator={','}
              className={styles['drawer-kinematic-input-number']}
              placeholder='Введите частоту вращения'
              min={0}
            />
          </Form.Item>
        )}
        <Form.Item
          name={DcmParamName.Fu}
          label='Частота питающей сети, Гц'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать частоу питающей сети'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите частоту питающей сети'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={DcmParamName.N2p}
          label='Количество пар полюсов'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество пар полюсов'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите частоту питающей сети'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={DcmParamName.Nr}
          label='Количество пазов ротора'
          className={styles['drawer-kinematic-input-item']}
          rules={[
            {
              required: true,
              message: 'Необходимо указать количество пазов ротора'
            },
            {
              validator: validateMinValue(0)
            }
          ]}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество пазов ротора'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <Form.Item
          name={DcmParamName.Nc}
          label='Количество пластин коллектора'
          className={styles['drawer-kinematic-input-item']}
        >
          <InputNumber
            decimalSeparator={','}
            placeholder='Введите количество пазов коллектора'
            className={styles['drawer-kinematic-input-number']}
          />
        </Form.Item>
        <h2 className={styles['drawer-kinematic-subtitle']}>Выход</h2>
        <OutTable outs={outs} />
      </Form>
      <ControlPanel
        onClickCancel={handleCancel}
        onClickCalculation={handleCalculation}
        onClickSave={handleFinish}
        disabledCalculation={disabledCalculateButton}
        disabledCancel={disabledCancelButton}
        disabledSave={disabledFinishButton}
        isLoadingSave={isUpdatingKinematicElement}
      />
    </>
  )
}

export default DcmForm
