import { binarySearch } from '@/app/machine-condition/components/results/components/charts/components/chart-spectrum/helpers/calculates.helper'
import CharFreqTreeSelect from '@/app/machine-condition/components/results/components/charts/components/express-sidebar/components/char-freq-tree-select/char-freq-tree-select'
import ButtonPrimary from '@/components/controlers/button-primary/button-primary'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import PanelTitle from '@/components/controlers/panel/panel-title/panel-title'
import ButtonsWrapper from '@/components/wrappers/buttons-wrapper/buttons-wrapper'
import { PRECISION_NUMBER } from '@/constants/config.constant'
import useActions from '@/hooks/use-actions'
import { useTypedSelector } from '@/hooks/use-typed-selector'
import type { IChartSpectrum } from '@/types/chart/chart-spectrun.type'
import type { IMarker, IMarkerForm } from '@/types/express-analysis/marker/marker.type'
import type { ICharFreqTree } from '@/types/kinematic/char-freq.type'
import { Flex, Form, InputNumber, Modal } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { type Dispatch, type FC, type SetStateAction, useEffect } from 'react'

interface IExpressMarkerPopup {
  setIsPopupOpen: Dispatch<SetStateAction<boolean>>
  isPopupOpen: boolean
}

const getChartMarkerByFreqs = (chartData: IChartSpectrum[], formMarksers: IMarkerForm): IMarker[] => {
  const { freq1, freq2, freq3 } = formMarksers

  return Object.values({ freq1, freq2, freq3 }).map((freq, index) => {
    const foundIndex = binarySearch(chartData, freq, 0, chartData.length - 1).index
    const point = chartData[foundIndex]
    return {
      chartIndex: point.index,
      frequency: point.frequency,
      index: index
    }
  })
}

const ExpressMarkerPopup: FC<IExpressMarkerPopup> = ({ setIsPopupOpen, isPopupOpen }) => {
  const [form] = useForm<IMarkerForm>()
  const { markers, formMarkers, chartSpectrum } = useTypedSelector((state) => state.expressMarkersReducer)
  const { setFormMarker, setMarkers } = useActions()

  const handleMarkersFinish = () => {
    if (chartSpectrum) {
      setFormMarker(form.getFieldsValue())
      const result = getChartMarkerByFreqs(chartSpectrum, form.getFieldsValue())
      setMarkers(result)
      setIsPopupOpen(false)
    }
  }

  const handleOnReset = () => {
    setIsPopupOpen(false)
  }

  useEffect(() => {
    if (isPopupOpen && formMarkers) {
      const formatedMarkers: { [key in string]?: number } = { ...formMarkers }

      for (const key in formatedMarkers) {
        const value = formatedMarkers[key]
        if (value) {
          formatedMarkers[key] = Number(value.toPrecision(PRECISION_NUMBER))
        }
      }
      form.setFieldsValue(formatedMarkers)
    }
  }, [form, markers, isPopupOpen, formMarkers])

  const handleSetFreq1Select = (options: ICharFreqTree, field: keyof IMarkerForm) => {
    const freqValue = options.payload?.freqOutValue
    if (typeof freqValue === 'number') {
      form.setFieldValue(field, freqValue.toPrecision(PRECISION_NUMBER))
    }
  }

  return (
    <Modal
      width={1200}
      onCancel={() => setIsPopupOpen(false)}
      open={isPopupOpen}
      footer={null}
      centered={true}
      maskClosable={false}
    >
      <Form form={form} onFinish={handleMarkersFinish} onReset={handleOnReset}>
        <PanelTitle>Управление маркерами</PanelTitle>
        <Flex gap={5}>
          <Flex vertical={true} flex={1}>
            <FormItemApp label='Частота 1 маркера' name='freq1' required={true}>
              <InputNumber />
            </FormItemApp>
            <FormItemApp label='Частота 2 маркера' name='freq2' required={true}>
              <InputNumber />
            </FormItemApp>
            <FormItemApp label='Частота 3 маркера' name='freq3' required={true}>
              <InputNumber />
            </FormItemApp>
          </Flex>
          <Flex vertical={true} flex={1}>
            <FormItemApp label='← Характреные частоты 1' name='charFreq1'>
              <CharFreqTreeSelect
                onSelect={(_: unknown, options: ICharFreqTree) => handleSetFreq1Select(options, 'freq1')}
              />
            </FormItemApp>
            <FormItemApp label='← Характреные частоты 2' name='charFreq2'>
              <CharFreqTreeSelect
                onSelect={(_: unknown, options: ICharFreqTree) => handleSetFreq1Select(options, 'freq2')}
              />
            </FormItemApp>
            <FormItemApp label='← Характреные частоты 3' name='charFreq3'>
              <CharFreqTreeSelect
                onSelect={(_: unknown, options: ICharFreqTree) => handleSetFreq1Select(options, 'freq3')}
              />
            </FormItemApp>
          </Flex>
        </Flex>
        <ButtonsWrapper>
          <ButtonPrimary htmlType={'reset'} title={'Отмена'} />
          <ButtonPrimary htmlType={'submit'} title={'Сохранить'} />
        </ButtonsWrapper>
      </Form>
    </Modal>
  )
}
export default ExpressMarkerPopup
