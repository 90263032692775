import type { EDetectorType } from '@/enums/measurment/common-level/detector-type.enum'
import type { EFrequencyRange } from '@/enums/measurment/common-level/frequency-range.enum'

export const mapFrequencyRange: { [key in EFrequencyRange]: string } = {
  FREQUENCY_RANGE_0_5_500: '05-500',
  FREQUENCY_RANGE_0_7_300: '07-300',
  FREQUENCY_RANGE_2_1000: '2-1000',
  FREQUENCY_RANGE_10_1000: '10-1000',
  FREQUENCY_RANGE_10_2000: '10-2000',
  FREQUENCY_RANGE_10_5000: '10-5000',
  FREQUENCY_RANGE_10_10000: '10-10000'
}

export const frequencyRangeOptions = Object.entries(mapFrequencyRange).map(([key, label]) => ({
  value: key as EDetectorType,
  label
}))
