import type { ITableDataOuts } from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.type'
import { Table } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import cn from 'classnames'
import type { FC } from 'react'

import localStyle from './out-table.module.css'
import styles from '@/components/widgets/kinematic-space/components/kinematic-configuration/components/drawer-kinematic/drawer-kinematic.module.css'

type TOutTable = {
  tableDataOuts?: ITableDataOuts[]
}
export const OutTable: FC<TOutTable> = ({ tableDataOuts }) => {
  const columns: ColumnsType<ITableDataOuts> = [
    {
      dataIndex: 'label'
    },
    {
      dataIndex: 'freqOutName',
      title: 'Наименование частот'
    },
    {
      dataIndex: 'freqOutCoefKb',
      title: 'Коэффициенты в рамках КБ'
    },
    {
      dataIndex: 'freqOutCoefMachine',
      title: 'Коэффициенты в рамках машины'
    },
    {
      dataIndex: 'freqOutValue',
      title: 'Значения частот'
    }
  ]

  return (
    <Table
      className={cn(styles['drawer-kinematic-table'], styles['drawer-kinematic-table-width-height'], localStyle.table)}
      columns={columns}
      dataSource={tableDataOuts}
      pagination={false}
      bordered={true}
    />
  )
}
