import type { TMeasurementForm } from '@/app/configuration/components/equipments/components/measurements/measurement.interface'
import TextStatic from '@/components/controlers/form-controls/text-static/text-static'
import FormItemApp from '@/components/controlers/panel/form-item-mcm/form-item-app'
import { temperatureTypeOptions } from '@/constants/measurement/measurements.constant'
import { ETemperatureType } from '@/enums/measurment/temperature-type.enum'
import type { ITemperatureDescription } from '@/types/measurement/measurement.type'
import { validateMinValue } from '@/utils/validation-rules'
import type { FormInstance } from 'antd'
import { InputNumber, Select } from 'antd'
import type { FC } from 'react'
import { useState } from 'react'

interface IProps {
  measurementForm?: FormInstance<TMeasurementForm>
  isEditMode: boolean
  isAddMode: boolean
  temperatureDescription?: ITemperatureDescription
}

const TemperatureForm: FC<IProps> = ({ measurementForm, temperatureDescription, isAddMode, isEditMode }) => {
  const [selectedTemperature, setSelectedTemperature] = useState(ETemperatureType.OBJECT)
  const isTemperatureSensor = selectedTemperature === ETemperatureType.SENSOR
  const coef = temperatureDescription?.coef
  const staticTemperatureType = temperatureTypeOptions.find(
    (item) => item.value === temperatureDescription?.temperatureType
  )
  const handleTemperatureTypeChange = (value: ETemperatureType) => {
    if (measurementForm) {
      setSelectedTemperature(value)
      if (value === ETemperatureType.SENSOR) {
        measurementForm.setFieldValue(['temperatureDescription', 'coef'], undefined)
      }
    }
  }

  return (
    <>
      {isAddMode && (
        <>
          <FormItemApp
            name={['temperatureDescription', 'temperatureType']}
            label='Источник'
            initialValue={ETemperatureType.OBJECT}
            rules={[{ required: true, message: 'Поле обязательно' }]}
          >
            <Select options={temperatureTypeOptions} onChange={handleTemperatureTypeChange} />
          </FormItemApp>
          <FormItemApp
            name={['temperatureDescription', 'coef']}
            initialValue={1}
            label='Коэффициент излучения'
            rules={[
              { required: !isTemperatureSensor, message: 'Поле обязательно' },
              {
                validator: validateMinValue(0)
              }
            ]}
          >
            <InputNumber min={0} max={1} step={0.05} decimalSeparator={','} disabled={isTemperatureSensor} />
          </FormItemApp>
          <FormItemApp
            name={['temperatureDescription', 'numberOfAverages']}
            label='Количество усреднений'
            rules={[
              { required: true, message: 'Поле обязательно' },
              {
                validator: validateMinValue(0)
              }
            ]}
            initialValue={100}
          >
            <InputNumber min={0} max={65535} step={1} />
          </FormItemApp>
        </>
      )}
      {isEditMode && (
        <>
          <TextStatic label='Источник' value={staticTemperatureType?.label} />
          {coef && <TextStatic label='Коэффициент излучения' value={coef} />}
          <TextStatic label='Количество усреднений' value={temperatureDescription?.numberOfAverages} />
        </>
      )}
    </>
  )
}

export default TemperatureForm
